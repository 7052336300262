import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMeta, IResponseData } from 'common/common.interface'
import { ITabItem } from '@components/tab/tab.interface'
import {
    IRShipment,
    IRShipmentFilter,
    IRShipmentDetails,
    IShipmentPackLine,
    IShipmentTracking,
    IRShipmentStatusTotal,
} from 'repository/interface/shipment.interface'
import { RootState } from 'store'
import { createMetaData, formatDate } from 'common/common.service'
import { ITrackingTable } from 'common/components/tracking-table-expandable-custom.component'
import { ITrackingHistoryMilestone } from 'repository/interface/tracking.interface'
import { ITrackerItemV2 } from '@components/tracker-v2/tracker-v2.component'
import { IShipmentDetailsInitialValueSlice } from './shipment-details/shipment-details.interface'
import { shipmentDetailsInitialValueSlice } from './shipment-details/shipment-details.static'
import { IShipmentsSummaryInitialValueSlice } from './shipments.interface'
import { shipmentsSummaryInitialValueSlice } from './shipments.static'

export interface IShipmentsSlice {
    shipmentsSummary: IShipmentsSummaryInitialValueSlice
    shipmentDetails: IShipmentDetailsInitialValueSlice
}

export const initialState: IShipmentsSlice = {
    shipmentsSummary: shipmentsSummaryInitialValueSlice,
    shipmentDetails: shipmentDetailsInitialValueSlice,
}

const shipmentsSlice = createSlice({
    name: 'shipmentsIFB',
    initialState,
    reducers: {
        setShipmentData(
            state,
            action: PayloadAction<IResponseData<IRShipment[]>>,
        ) {
            const { data, meta, additionals } = action.payload

            const formatData: IRShipment[] = data?.map((item) => ({
                ...item,
                datesETDShipment: item?.datesETDShipment
                    ? formatDate(item?.datesETDShipment)
                    : '',
                datesETAShipment: item?.datesETAShipment
                    ? formatDate(item?.datesETAShipment)
                    : '',
            }))

            const statusData: IRShipmentStatusTotal = {
                all: 0,
                departed: additionals?.departed || 0,
                boarded: additionals?.boarded || 0,
                cleared: additionals?.cleared || 0,
                arrived: additionals?.arrived || 0,
                others: additionals?.others || 0,
            }

            state.shipmentsSummary = {
                ...state.shipmentsSummary,
                data: formatData,
                meta: meta,
                statusTotal: {
                    ...statusData,
                    all: Object.entries(statusData)
                        .filter(([key]) => key)
                        .reduce((sum, [, value]) => sum + value, 0),
                },
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            state.shipmentsSummary.statusSelected = action.payload
        },
        setFilter(state, action: PayloadAction<IRShipmentFilter>) {
            state.shipmentsSummary.filter = action.payload
        },
        setPageNumber(state, action: PayloadAction<number>) {
            state.shipmentsSummary.filter.pageNumber = action.payload
        },

        // segment shipment details global
        setShipmentDetailsData(
            state,
            action: PayloadAction<IResponseData<IRShipmentDetails>>,
        ) {
            const payloadData = action.payload.data

            // set data details
            const updateDetailData = {
                houseBill: payloadData.houseBill,
                shippersRef: payloadData.shippersRef,
                orderRef: payloadData.orderRef,
                containerMode: payloadData.containerMode,
                transportMode: payloadData.transportMode,
                serviceLevelDescription: payloadData.serviceLevelDescription,
                outerPacksUnitCode: payloadData.outerPacksUnitCode,
                outerPacksUnitDescription:
                    payloadData.outerPacksUnitDescription,
                totalWeight: payloadData.totalWeight,
                totalWeightUnit: payloadData.totalWeightUnit,
                totalVolume: payloadData.totalVolume,
                totalVolumeUnit: payloadData.totalVolumeUnit,
                origin: payloadData.origin,
                destination: payloadData.destination,
                charges: payloadData.charges,
                notes: payloadData.notes,
                packs: payloadData.packs,
            }
            state.shipmentDetails.details = updateDetailData

            // set data related parties
            const consignorPickupAddress = payloadData.consignorPickupAddress
            const consigneePickupAddress = payloadData.consigneePickupAddress
            const consignorDocumentaryAddress =
                payloadData.consignorDocumentaryAddress
            const consigneeDocumentaryAddress =
                payloadData.consigneeDocumentaryAddress
            state.shipmentDetails.relatedParties = {
                consignorPickupAddress,
                consigneePickupAddress,
                consignorDocumentaryAddress,
                consigneeDocumentaryAddress,
            }
        },

        // segment shipment details tracking
        setShipmentDetailsTracking(
            state,
            action: PayloadAction<IShipmentTracking[]>,
        ) {
            const payloadData = action.payload
            const { meta, pageNumber } = state.shipmentDetails.tracking

            const loadMeta = createMetaData({
                meta: meta,
                lengthData: payloadData.length,
                itemPerPage: 50,
                pageNumber,
            })

            const loadTrackingItems = (
                rawData?: ITrackingHistoryMilestone[],
            ) => {
                if (!rawData || !rawData.length) return []
                const sortedMilestones = rawData.sort((a, b) => {
                    const dateA = a.actualDate
                        ? new Date(a.actualDate).getTime()
                        : 0
                    const dateB = b.actualDate
                        ? new Date(b.actualDate).getTime()
                        : 0
                    return dateB - dateA
                })

                const updatedTrackingItems: ITrackerItemV2[] =
                    sortedMilestones?.map((item) => ({
                        statusDesc: item.description,
                        date: item.actualDate
                            ? formatDate(item.actualDate, 'DD MMM YYYY')
                            : '-',
                    }))
                return updatedTrackingItems
            }

            const updateTrackingTable: ITrackingTable[] = payloadData
                .map((item) => ({
                    transportModeCode: item.transportMode,
                    containerTypeCode: item.containerType,
                    containerMode: {
                        code: '',
                        desc: item.containerMode,
                    },
                    deliverymode: item.deliveryMode,
                    consolNumber: item.consolNo,
                    seal: item.sealNo,
                    vesselName: item.vesselName,
                    voyageFlightNo: item.voyageNo,
                    relatedShipmentNumber: item?.relatedShipmentNumbers,
                    trackerItems: loadTrackingItems(
                        item.trackingHistoryMilestone,
                    ),
                    containerNumber: item.containerNo,
                    portOfLoading: {
                        code: item.portOfLoadingCode,
                        desc: item.portOfLoadingDesc,
                    },
                    portOfDischarge: {
                        code: item.portOfDischargeCode,
                        desc: item.portOfDischargeDesc,
                    },
                    etd: item.containerETD,
                    eta: item.containerETA,
                }))
                .slice(loadMeta.index_start - 1, loadMeta.index_end)

            state.shipmentDetails.tracking.data = updateTrackingTable
            state.shipmentDetails.tracking.meta = {
                ...meta,
                ...loadMeta,
            }
        },
        setPageNumberTrackingSlice: (state, action: PayloadAction<number>) => {
            state.shipmentDetails.tracking.pageNumber = action.payload
        },

        // segment shipment details pack line
        setShipmentDetailsPackLine(
            state,
            action: PayloadAction<IShipmentPackLine[]>,
        ) {
            const payloadData = action.payload
            const { meta, pageNumber } = state.shipmentDetails.packLine

            const loadMeta = createMetaData({
                meta: meta,
                lengthData: payloadData.length,
                itemPerPage: 50,
                pageNumber,
            })

            state.shipmentDetails.packLine.data = payloadData.slice(
                loadMeta.index_start - 1,
                loadMeta.index_end,
            )
            state.shipmentDetails.packLine.meta = {
                ...meta,
                ...loadMeta,
            }
        },
        setPageNumberPackLineSlice: (state, action: PayloadAction<number>) => {
            state.shipmentDetails.packLine.pageNumber = action.payload
        },
    },
})

// these all the variables used for selector
export const shipmentsSummaryDataSelector = (state: RootState) =>
    state.shipmentIFB.shipmentsSummary || {}

// segment details
export const shipmentDetailsSelector = (state: RootState) =>
    state.shipmentIFB.shipmentDetails || {}

// all actions
export const {
    setShipmentData,
    setSelectedStatus,
    setPageNumber,
    setFilter,
    setShipmentDetailsData,
    setPageNumberTrackingSlice,
    setShipmentDetailsTracking,
    setShipmentDetailsPackLine,
    setPageNumberPackLineSlice,
} = shipmentsSlice.actions

// Reducer
export default shipmentsSlice.reducer
