var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import './tab.style.css';
import { useTranslation } from 'react-i18next';
var Tab = function (_a) {
    var items = _a.items, selectedItem = _a.selectedItem, onChange = _a.onChange, tabFilter = _a.tabFilter, _b = _a.containerClassName, containerClassName = _b === void 0 ? '' : _b, _c = _a.containerItemClass, containerItemClass = _c === void 0 ? '' : _c, _d = _a.showTotalData, showTotalData = _d === void 0 ? true : _d, props = __rest(_a, ["items", "selectedItem", "onChange", "tabFilter", "containerClassName", "containerItemClass", "showTotalData"]);
    var t = useTranslation().t;
    var getSelectedIndex = items.findIndex(function (x) { return x.value === (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.value); });
    var _e = useState(getSelectedIndex < 0 ? 0 : getSelectedIndex), activeIndex = _e[0], setActiveIndex = _e[1];
    var dataTestIdComponent = props.dataTestId
        ? "".concat(props.dataTestId, "_TAB")
        : '';
    return (_jsx("div", __assign({ className: "".concat(containerClassName, " header-status") }, { children: items.map(function (dt, idx) {
            var _a;
            var isActive = tabFilter
                ? dt.value === tabFilter.value
                : activeIndex === idx;
            var classActive = isActive ? 'active' : 'inactive';
            var className = 'themes-status-' + ((_a = dt.className) !== null && _a !== void 0 ? _a : 'gray');
            var label = dt.labelTranslationCode
                ? t(dt.labelTranslationCode)
                : dt.label;
            var dataTestId = '';
            if (props.dataTestId) {
                var formatLabelDataTestId = dt.label
                    .toUpperCase()
                    .replace(/\s+/g, '_');
                dataTestId = "".concat(dataTestIdComponent, "_").concat(formatLabelDataTestId);
            }
            return (_jsxs("div", __assign({ className: "item h-full min-h-[50px] flex flex-col justify-between ".concat(className, " ").concat(classActive, " ").concat(containerItemClass), onClick: function () {
                    setActiveIndex(idx);
                    onChange(dt);
                }, "data-testid": dataTestId }, { children: [_jsxs("div", __assign({ className: "flex-grow flex items-center" }, { children: [label, dt.totalData !== undefined &&
                                showTotalData === true ? (_jsx("span", { children: dt.totalData })) : (_jsx(_Fragment, {}))] })), showTotalData && (_jsx("span", { className: "bottom-line transition duration-300" })), showTotalData === false && items.length > 1 && (_jsx("span", { className: "bottom-line transition duration-300" }))] }), idx));
        }) })));
};
export default Tab;
