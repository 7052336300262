import { IMenu, IRoute } from 'common/common.interface'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import MenuHover from './menu-hover.component'
import Submenu from './submenu.component'
import { useTranslation } from 'react-i18next'
import { filterContentByEnv } from 'common/common.service'

export default function Menu({
    action,
    path,
    showName,
    icon,
    text,
    expandable,
    sub,
    openSideBar,
    textTranslationCode,
}: IMenu & IRoute) {
    const { t } = useTranslation()
    const [isHover, setIsHover] = useState(false)
    const [expand, setExpand] = useState(false)
    const [isSubModuleActive, setSubModuleActive] = useState(false)
    const location = useLocation()
    const currentURL = location.pathname
    const menuLabel = textTranslationCode ? t(textTranslationCode) : text

    // is module active?
    const active = isSubModuleActive
        ? isSubModuleActive
        : currentURL?.includes(path)
    const menuClass = active ? 'themes-menu-active' : 'themes-menu'
    const [showMenuNameClass, setShowMenuClass] = useState(
        showName ? '' : 'hide',
    )

    const [iconDisplay, setIconDisplay] = useState('')

    const formatLabel = text?.toUpperCase().replace(/\s+/g, '_')
    const dataTestId = `${formatLabel}_MENU`

    useEffect(() => {
        if (showName) {
            setShowMenuClass('')
            setIconDisplay(`flex justify-start pr-3 pl-3  `)
            return
        }
        setShowMenuClass('hide')

        const cls =
            'flex w-full transform transition-all  translate-x-[25%]  duration-[2s] pl-[2px] '
        setIconDisplay(cls)
        setTimeout(() => {
            setIconDisplay(cls + `   `)
        }, 500)
    }, [showName])

    useEffect(() => {
        const isSMActive = !sub?.map
            ? false
            : sub.find((x) => currentURL?.includes(x.path))
              ? true
              : false

        setSubModuleActive(isSMActive)
    }, [currentURL])

    const onClickHandler = () => {
        if (expandable) {
            setExpand(!expand)
            openSideBar && !showName && openSideBar()
        } else {
            action(path)
        }
    }

    useEffect(() => {
        if (!showName) {
            setExpand(false)
        } else {
            if (isSubModuleActive) setExpand(true)
            if (!active) setExpand(false)
        }
    }, [showName])

    useEffect(() => {
        // open sub module when some of content is selected
        if (isSubModuleActive) setExpand(true)
        if (!active) setExpand(false)
    }, [isSubModuleActive, active, currentURL])

    return (
        <div
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className={`cursor-pointer w-full flex select-none flex-col justify-center items-center last:mb-0`}
            data-testid={dataTestId}
        >
            <MenuHover show={isHover && !showName} text={text} />
            <div
                onClick={onClickHandler}
                className={`${menuClass} w-full transition-all duration-200 flex items-center rounded-[0.5rem]`}
            >
                <div
                    className={`pt-[2px] items-center min-h-[40px] text-[18px] ${iconDisplay}`}
                >
                    <i className={`cursor-pointer ${icon}`} />
                </div>

                <div
                    className={`${showMenuNameClass} pt-[5px] transition-all duration-200 text-[15px] font-[400] tracking-tight text-animation whitespace-nowrap`}
                >
                    {menuLabel}
                </div>
            </div>

            <div
                className={`${
                    expand ? 'max-h-[150px] ' : 'max-h-[0px] invisible'
                }  transform duration-[0.5s] w-full z-50 overflow-hidden`}
            >
                {sub
                    ?.filter((route) => route.show === true)
                    ?.filter((route) => filterContentByEnv(route.devOnly))
                    .map((child, idx) => (
                        <Submenu
                            key={`${text}-${idx}`}
                            index={idx}
                            active={currentURL?.includes(child.path)}
                            showName={true}
                            action={action}
                            {...child}
                            isSubMenu={true}
                        />
                    ))}
            </div>
        </div>
    )
}
