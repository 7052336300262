import { IResponseData } from 'common/common.interface'
import { fetch, rangeDate } from 'common/common.service'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import { IVolumeFilterValidation } from '../form-validation/volume.validation'
import {
    IBarDataRespon,
    IDonatChartShipmentRespon,
} from '../pages/volumes/volumes.interface'

const setUpdatedFilter = (filter: IVolumeFilterValidation, type?: string) => {
    const { StartDate, EndDate, ...rest } = filter

    const loadRangeDate = rangeDate(StartDate, EndDate)

    const updatedFilter = {
        ...rest,
        StartDate: loadRangeDate.formattedStartMonth,
        EndDate: loadRangeDate.formattedEndMonth,
        ...(type && {
            transportMode: type,
        }),
    }

    return updatedFilter
}

export const fetchBarData = async (
    filter: IVolumeFilterValidation,
    type: string,
) => {
    try {
        const updatedFilter = setUpdatedFilter(filter, type)
        const response = await fetch<
            IResponseData<IBarDataRespon>,
            IVolumeFilterValidation
        >({
            endpoint: ifbEndpoints.barChartVolume,
            params: updatedFilter,
        })
        return response
    } catch (error: unknown) {
        const err = error as IResponseData<IBarDataRespon>
        err.isSuccess = false
        return err
    }
}

export const fetchDonatData = async (filter: IVolumeFilterValidation) => {
    try {
        const updatedFilter = setUpdatedFilter(filter)
        const response = await fetch<
            IResponseData<IDonatChartShipmentRespon | undefined>,
            IVolumeFilterValidation
        >({
            endpoint: ifbEndpoints.donatChartVolume,
            params: updatedFilter,
        })
        return response
    } catch (error: unknown) {
        const err = error as IResponseData<
            IDonatChartShipmentRespon | undefined
        >
        err.isSuccess = false
        return err
    }
}
