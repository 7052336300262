import Tab from '@components/tab/tab.component'
import { quotationDetailsHeader } from './quotation-details.static'
import useQuotationDetails from './quotation-details.service'
import Dropdown from '@components/dropdown/dropdown.component'
import Input from '@components/input/input.component'
import Button from '@components/button/button.component'
import { ratesDummy } from '../quotations.dummy'
import { useTranslation } from 'react-i18next'
import SelectedRateCard from '../components/selected-rate-card.component'

const QuotationDetails = () => {
    const { tabFilter, navigate, setTabFilter } = useQuotationDetails()
    const borderComponent = <div className="border-b"></div>
    const { t } = useTranslation()
    return (
        <div className="container-global content-full-height overflow-hidden">
            <Tab
                containerClassName="px-3 overflow-auto"
                items={quotationDetailsHeader ?? []}
                tabFilter={tabFilter}
                onChange={(item) => setTabFilter(item)}
            />
            {borderComponent}
            <div className="p-3 gap-x-3 h-[calc(100vh-13.70rem)] overflow-auto grid lg:grid-cols-3 md:grid-cols-1 gap-3">
                <div className="">
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-3">
                            <div className="flex">
                                <span className="text-size-M font-bold">
                                    {t('quotes.origin')}
                                    <i className="ri-arrow-right-line text-size-M font-bold" />
                                    {t('quotes.destination')}
                                </span>
                            </div>
                            <Dropdown
                                label={t('quotes.origin_port')}
                                placeholder={t('action.select', {
                                    value: t('quotes.origin_port'),
                                })}
                                options={[
                                    {
                                        label: 'AUMEL - Melbourne, VIC, Australia',
                                        value: 'AUMEL - Melbourne, VIC, Australia',
                                    },
                                ]}
                            />
                            <Dropdown
                                label={t('quotes.destination_port')}
                                placeholder={t('action.select', {
                                    value: t('quotes.destination_port'),
                                })}
                                options={[
                                    {
                                        label: 'AUMEL - Melbourne, VIC, Australia',
                                        value: 'AUMEL - Melbourne, VIC, Australia',
                                    },
                                ]}
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            <div>{t('quotes.quote_details')}</div>
                            <div className="grid grid-cols-2 gap-3">
                                <Dropdown
                                    label={t('quotes.transport_mode')}
                                    placeholder={t('action.select', {
                                        value: t('quotes.transport_mode'),
                                    })}
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label={t('quotes.container_mode')}
                                    placeholder={t('action.select', {
                                        value: t('quotes.container_mode'),
                                    })}
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label={t('quotes.service_level')}
                                    placeholder={t('action.select', {
                                        value: t('quotes.service_level'),
                                    })}
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label={t('quotes.inco_term')}
                                    placeholder={t('action.select', {
                                        value: t('quotes.inco_term'),
                                    })}
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                            </div>
                            <Input
                                label={t('quotes.weight')}
                                value="0.000"
                                disabled
                            />
                            <Input
                                label={t('quotes.volume')}
                                value="0.000"
                                disabled
                            />
                            <Input
                                label={t('quotes.value_of_goods')}
                                value="0.000"
                                disabled
                            />
                            <Input
                                label={t('quotes.value_of_goods_currency')}
                                value="0.000"
                                disabled
                            />
                            <Dropdown
                                label={t('quotes.insurance_value')}
                                placeholder={t('action.select', {
                                    value: t('quotes.insurance_value'),
                                })}
                                options={[
                                    {
                                        label: 'AUMEL - Melbourne, VIC, Australia',
                                        value: 'AUMEL - Melbourne, VIC, Australia',
                                    },
                                ]}
                            />
                            <Dropdown
                                label={t('quotes.insurance_value_currency')}
                                placeholder={t('action.select', {
                                    value: t('quotes.insurance_value_currency'),
                                })}
                                options={[
                                    {
                                        label: 'AUMEL - Melbourne, VIC, Australia',
                                        value: 'AUMEL - Melbourne, VIC, Australia',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                    <div>{t('quotes.selected_rate')}</div>
                    <div>
                        <SelectedRateCard data={ratesDummy[0]} />
                    </div>
                </div>
            </div>
            {borderComponent}
            <div className="flex justify-between p-3">
                <Button
                    label={t('quotes.back')}
                    onClick={() => navigate(-1)}
                    className="w-btnRegularWidth"
                    variant="brand-v2-inverse"
                    useUpperCase
                />
                <Button
                    label={t('quotes.print_quote')}
                    onClick={() => {}}
                    className="w-btnRegularWidth"
                    variant="brand-v2-inverse"
                    useUpperCase
                />
            </div>
        </div>
    )
}

export default QuotationDetails
