import Tab from '@components/tab/tab.component'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import Spinner from '@components/spinner/spinner.component'
import { IUseCreateBooking } from '../create-booking.interface'
import Details from './details.component'
import RelatedParties from './related-parties.component'
import PackLines from './pack-lines.component'
import ContainerBookings from './container-bookings.component'

const FormCreateBooking = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const spinnerShow = CBService.loading ? '' : 'hidden'

    const loadContentForm = () => {
        const contents: Record<string, JSX.Element> = {
            details: <Details CBService={CBService} />,
            relatedParties: <RelatedParties CBService={CBService} />,
            packLines: <PackLines CBService={CBService} />,
            container: <ContainerBookings CBService={CBService} />,
        }
        return contents[CBService.tabFormBooking.value]
    }

    return (
        <div>
            <Tab
                containerClassName="px-3"
                items={CBService.tabFormBookingItems.filter(
                    (item) =>
                        CBService.modeTipe === 'fcl' ||
                        item.value !== 'container',
                )}
                tabFilter={CBService.tabFormBooking}
                onChange={(item) => {
                    CBService.setTabFormBooking(item)
                }}
            />
            <div className="border-b"></div>

            <FormikProvider value={CBService.formikCreateBooking}>
                <div className="h-[calc(100vh-13.8rem)]">
                    {CBService.loading ? (
                        <div
                            className={`h-full flex justify-center items-center`}
                        >
                            <Spinner label="Please Wait..." />
                        </div>
                    ) : (
                        loadContentForm()
                    )}
                </div>
            </FormikProvider>
            <div className="border-b"></div>
            <div className="p-3 flex justify-between">
                <Button
                    onClick={() => {
                        CBService.setConfirmationModalProperty({
                            title: 'Discard Changes',
                            message:
                                'Everything that you’ve added in the page will not be recorded',
                            btnProceed: {
                                onClick: () => CBService.navigate('/bookings'),
                                variant: 'brand-v2-inverse',
                            },
                        })
                        CBService.confirmationModal.openModalHandling()
                    }}
                    label="discard changes"
                    useUpperCase
                    variant="brand-v2-inverse"
                    className="w-btnRegularWidth"
                />
                <Button
                    onClick={() => {
                        CBService.saveBooking()
                    }}
                    label="PLACE BOOKING"
                    className="w-btnRegularWidth"
                    variant="brand-v2-inverse"
                    isDisabled={CBService.loading}
                />
            </div>
        </div>
    )
}

export default FormCreateBooking
