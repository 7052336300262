import EmptyResult from '@components/empty-result/empty-result.component'

const ErrorHandlingComponent = ({
    isError,
    fallbackComponent,
}: {
    isError: boolean
    fallbackComponent: React.ReactNode
}) => {
    if (isError) {
        return <EmptyResult disableIllustration message="No data found" />
    } else {
        return <>{fallbackComponent}</>
    }
}
export default ErrorHandlingComponent
