import Tab from '@components/tab/tab.component'
import { TabTrackings } from '../trackings.static'

import Button from '@components/button/button.component'
import { useNavigate } from 'react-router-dom'
import { useTrackingDetails } from './trackings-details.service'

const TrackingDetails = () => {
    const navigate = useNavigate()

    const { tabFilter, setTabFilter, getContentTrackingDetails } =
        useTrackingDetails()

    return (
        <div className="content-full-height w-full rounded-lg themes-border-color bg-white  border-2 py-2 flex flex-col justify-between">
            <div className="flex flex-col">
                <div className=" px-3 ">
                    <Tab
                        containerClassName="px-3 overflow-auto"
                        items={TabTrackings ?? []}
                        tabFilter={tabFilter}
                        onChange={(item) => setTabFilter(item)}
                    />
                </div>
                {getContentTrackingDetails(tabFilter)}
            </div>

            <div className="px-2 pt-2">
                <Button
                    onClick={() => {
                        navigate('/trackings')
                    }}
                    label="Back"
                    className="w-[10rem] ml-0"
                    variant="brand-inverse"
                />
            </div>
        </div>
    )
}

export default TrackingDetails
