import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import {
    ITrackingResponse,
    ITrackingFilter,
} from './interface/tracking.interface'

export const getTrackingsData = async (filter: ITrackingFilter) => {
    try {
        const response = await fetch<
            IResponseData<ITrackingResponse[]>,
            ITrackingFilter
        >({
            endpoint: ifbEndpoints.tracking_getAll,
            params: filter,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<ITrackingResponse[]>
        err.isSuccess = false
        return err
    }
}
