import { IResponseData } from 'common/common.interface'
import { fetch, post, put } from 'common/common.service'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import {
    IFilterModuleTemplates,
    IManageUser,
    IManageUserFilter,
    IModuleTemplate,
    IUserDefaultData,
    IUserDefaultFilter,
} from '../pages/manage-users/manage-users.interface'
import { IManageUserLog } from '../pages/manage-users/manage-user-details/manage-users-details.interface'
import { Toast } from '@components/toast/toast.component'
import { IUpdateUserValidation } from '../form-validation/update-user.validation'
import { IPayloadCreateUser } from '../pages/manage-users/manage-user-form/manage-user-form.interface'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ICreateUserErrorResponse } from './interface/manage-user.repository'
import { initialResponseData } from 'common/common.static'
import { IForgotPasswordValidation } from 'form-validation/forgot-password.validation'
import { IResetPasswordValidation } from 'form-validation/reset-password.validation'

export const getManageUserData = async (filter: IManageUserFilter) => {
    try {
        const response = await fetch<
            IResponseData<IManageUser[]>,
            IManageUserFilter
        >({
            endpoint: ifbEndpoints.manage_user_getAll,
            params: filter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Manage User data',
                message:
                    response?.message || `Failed to fetch Manage User data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Manage User data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Manage User data',
                message: `Unknown error occurred while fetching Manage User data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IManageUser[]>
        err.isSuccess = false
        return err
    }
}

export const getManageUserID = async (filter: IManageUserFilter) => {
    try {
        const endpoint = `${ifbEndpoints.manage_user_getAll}/${filter.id}`
        const response = await fetch<
            IResponseData<IManageUser>,
            IManageUserFilter
        >({
            endpoint: endpoint,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Manage User ID data',
                message:
                    response?.message || `Failed to fetch Manage User data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Manage User ID data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Manage User ID data',
                message: `Unknown error occurred while fetching Manage User ID data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IManageUser>
        err.isSuccess = false
        return err
    }
}

export const getManageUserIDLog = async (filter: IManageUserFilter) => {
    try {
        const endpoint = `${ifbEndpoints.manage_user_getAll}/${filter.id}/activities`
        const response = await fetch<
            IResponseData<IManageUserLog[]>,
            IManageUserFilter
        >({
            endpoint: endpoint,
            params: filter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Manage User ID Log data',
                message:
                    response?.message || `Failed to fetch Manage User data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Manage User ID Log data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Manage User ID Log data',
                message: `Unknown error occurred while fetching Manage User ID Log data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IManageUserLog[]>
        err.isSuccess = false
        return err
    }
}

export const getCurrentUser = async () => {
    try {
        const response = await fetch<IResponseData<IManageUser>, string>({
            endpoint: `${ifbEndpoints.manage_user_getCurrentUser}`,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IManageUser>
        err.isSuccess = false
        return err
    }
}

export const getUserDefaultData = createAsyncThunk<
    IUserDefaultData,
    IUserDefaultFilter
>('manage-users/user/defaultData', async (filter, thunkApi) => {
    try {
        const response = await fetch<IResponseData<IUserDefaultData>, string>({
            endpoint: `${ifbEndpoints.manage_user_getDefaultData}`,
            params: filter,
        })

        if (!response.isSuccess) {
            Toast({
                header: 'Manage User Default data',
                message:
                    response?.message || `Failed to fetch Manage User data.`,
                type: 'error',
            })
        }

        return response.data
    } catch (error) {
        if (error instanceof Error) {
            Toast({
                header: 'Manage User ID data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Manage User ID data',
                message: `Unknown error occurred while fetching Manage User ID data."`,
                type: 'error',
            })
        }
        return thunkApi.rejectWithValue(error)
    }
})

export const getAccessModule = async (filter: IFilterModuleTemplates) => {
    try {
        const response = await fetch<IResponseData<IModuleTemplate[]>, string>({
            endpoint: `${ifbEndpoints.manage_user_getModuleTemplates}`,
            params: filter,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IModuleTemplate[]>
        err.isSuccess = false

        return err
    }
}

export const createUser = async (payload: IPayloadCreateUser) => {
    try {
        const response = await post<IResponseData<any>, IPayloadCreateUser>({
            endpoint: `${ifbEndpoints.manage_user_getAll}`,
            payload,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as ICreateUserErrorResponse
        err.isSuccess = false

        return err
    }
}

export const updateUser = async (payload: IUpdateUserValidation) => {
    try {
        const response = await put<IResponseData<any>, IUpdateUserValidation>({
            endpoint: `${ifbEndpoints.manage_user_getAll}`,
            payload,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<any>
        err.isSuccess = false

        return err
    }
}

export const getBranchUserById = async (staffId: string) => {
    try {
        const endpoint = `${ifbEndpoints.manage_user_getDefaultData}`
        const response = await fetch<
            IResponseData<IUserDefaultData>,
            IManageUserFilter
        >({
            endpoint: endpoint,
            params: { staffId },
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<IUserDefaultData> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const forgotPassword = async (payload: IForgotPasswordValidation) => {
    try {
        const response = await post<
            IResponseData<any>,
            IForgotPasswordValidation
        >({
            endpoint: ifbEndpoints.manage_user_forgotPassword,
            params: payload,
        })
        return response
    } catch (error: any) {
        throw error.response.data
    }
}

export const postResetPassword = async (payload: IResetPasswordValidation) => {
    try {
        const response = await post<
            IResponseData<any>,
            IResetPasswordValidation
        >({
            endpoint: ifbEndpoints.manage_user_resetPassword,
            payload,
        })
        return response
    } catch (error: any) {
        throw error.response.data
    }
}

export const changeUserStatus = async (id: string, isActive: boolean) => {
    try {
        const response = await put<
            IResponseData<any>,
            { id: string; isActive: boolean }
        >({
            endpoint: ifbEndpoints.manage_user_staffUpdateStatus,
            payload: { id, isActive },
        })
        return response
    } catch (error: any) {
        throw error.response.data
    }
}
