import FormDropdown from '@components/form-dropdown/form-dropdown.component'

const BookingFilter = () => {
    return (
        <div className={`flex flex-col gap-3 p-4 overflow-auto`}>
            <FormDropdown
                isSearchable
                label={'consignor'}
                name={`consignor`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
            <FormDropdown
                isSearchable
                label={'consignee'}
                name={`consignee`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
            <FormDropdown
                isSearchable
                label={'destination'}
                name={`destination`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
            <FormDropdown
                isSearchable
                label={'origin'}
                name={`origin`}
                placeholder="Select Here"
                parentDivClassName="!mb-0"
                useUppercaseLabel
                isMultiSelect
                isClearable
                options={[]}
            />
        </div>
    )
}

export default BookingFilter
