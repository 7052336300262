import SpinnerTable from '@components/spinner-table/spinner-table.component'
import {
    IMilestoneGroupDayRangeTable,
    IPerformanceContent,
} from '../performances.interface'
import EmptyResult from '@components/empty-result/empty-result.component'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import ProgressMultipleBars from '@components/progress-multiple-bars/progress-multiple-bars.component'

const TableMilestoneShipment = <T, U>({
    data,
    isLoading = false,
    pageContent,
    errorMsg,
    disableErrorIllustration,
}: {
    data: IMilestoneGroupDayRangeTable<T, U>
    errorMsg?: string
    isLoading?: boolean
    pageContent: IPerformanceContent
    disableErrorIllustration?: boolean
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [cssPropertyMultipleProgress, setCssPropertyMultipleProgress] =
        useState<CSSProperties>({
            maxWidth: 400,
        })
    const valueTableClass =
        'themes-text-gray-v9 font-bold text-[10px] 2xl:text-size-XS'

    const calculateComponentWidth = (ws: number) => {
        // set the right ratio between component width and screen width
        const widthScreenMin = 1280 // Minimum screen width
        const widthScreenMax = 1366 // Maximum screen width
        const widthComponentMin = 70 // Component width at min screen width
        const widthComponentMax = 120 // Component width at max screen width

        // calculate the width using linear interpolation
        const wc =
            widthComponentMin +
            ((ws - widthScreenMin) / (widthScreenMax - widthScreenMin)) *
                (widthComponentMax - widthComponentMin)

        return wc
    }

    useEffect(() => {
        const handleCalculateWidth = () => {
            const widthComponent = calculateComponentWidth(window.outerWidth)
            if (window.outerWidth >= 1280) {
                setCssPropertyMultipleProgress({
                    ...cssPropertyMultipleProgress,
                    width: widthComponent,
                })
            } else {
                setCssPropertyMultipleProgress({
                    ...cssPropertyMultipleProgress,
                    width: 300,
                })
            }
        }

        handleCalculateWidth()

        // Add event listener for window resize
        window.addEventListener('resize', handleCalculateWidth)

        return () => {
            window.removeEventListener('resize', handleCalculateWidth)
        }
    }, [])

    return (
        <>
            {isLoading === true ? (
                <div className="h-full">
                    <SpinnerTable className="w-full" message="Loading Data" />
                </div>
            ) : data.data.length <= 0 ? (
                <div className="h-full">
                    <EmptyResult
                        className="w-full"
                        message={errorMsg || 'Data Empty'}
                        disableIllustration={disableErrorIllustration}
                        imgClassName="2xl:w-[8rem] 2xl:h-[11rem] xl:w-[5rem]  xl:h-[6rem]"
                    />
                </div>
            ) : (
                <div className="w-full h-full overflow-auto" ref={ref}>
                    {data.data.map((item) => {
                        let transportModeIcon: string
                        switch (item.transportMode.toLocaleLowerCase()) {
                            case 'sea':
                                transportModeIcon = 'ri-ship-2-line'
                                break
                            case 'air':
                                transportModeIcon = 'ri-plane-line'
                                break
                            case 'road':
                                transportModeIcon = 'ri-truck-line'
                                break

                            default:
                                transportModeIcon = 'ri-ship-2-line'
                        }
                        const setLoadCountryCode = item.portLoad
                            .slice(0, 2)
                            .toLowerCase()
                        const setDischargeCountryCode = item.portDischarge
                            .slice(0, 2)
                            .toLowerCase()
                        const loadCountryImg = `https://flagcdn.com/${setLoadCountryCode}.svg`
                        const dischargeCountryImg = `https://flagcdn.com/${setDischargeCountryCode}.svg`

                        return (
                            <>
                                <div className="flex p-3 gap-3 w-fit text-size-XS items-center 2xl:w-full">
                                    <div className="min-w-max">
                                        <div className="themes-bg-gray-v2 h-[30px] w-[30px] rounded flex items-center justify-center">
                                            <i
                                                className={`${transportModeIcon} text-size-S font-bold`}
                                            ></i>
                                        </div>
                                    </div>
                                    {pageContent === 'performance' && (
                                        <>
                                            <div className="border-r pr-3 min-w-max">
                                                <div>Container Mode</div>
                                                <div
                                                    className={valueTableClass}
                                                >
                                                    {item.containerMode || '-'}
                                                </div>
                                            </div>
                                            <div className="border-r pr-3 min-w-max">
                                                <div>Total Shipments</div>
                                                <div
                                                    className={valueTableClass}
                                                >
                                                    {item.shipmentCount || 0}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="border-r pr-3 flex items-center gap-1 min-w-max">
                                        <img
                                            src={loadCountryImg}
                                            alt=""
                                            className="w-[34px] border rounded"
                                        />
                                        <div>
                                            <div>Load</div>
                                            <div className={valueTableClass}>
                                                {item.portLoad}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-r pr-3 flex items-center gap-1 min-w-max">
                                        <div>
                                            <img
                                                src={dischargeCountryImg}
                                                alt=""
                                                className="w-[34px] border rounded"
                                            />
                                        </div>
                                        <div>
                                            <div>Discharge</div>
                                            <div className={valueTableClass}>
                                                {item.portDischarge}
                                            </div>
                                        </div>
                                    </div>
                                    {pageContent === 'transitTime' && (
                                        <div className="border-r pr-3 min-w-max">
                                            <div>Shipments Record</div>
                                            <div className={valueTableClass}>
                                                {item.shipmentCount || 0}
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex items-center border-r pr-3 min-w-max">
                                        <ProgressMultipleBars<T, U>
                                            containerClassNames={`w-max-[300px] flex justify-end`}
                                            style={cssPropertyMultipleProgress}
                                            data={item.progressMultipleBarData}
                                            keyItems={data.keyItems}
                                        />
                                    </div>
                                    <div className="min-w-max">
                                        <div>
                                            {pageContent === 'performance'
                                                ? 'Total Days'
                                                : 'Transit Days'}{' '}
                                        </div>
                                        <div className={valueTableClass}>
                                            {item.totalDays.min || 0} -{' '}
                                            {item.totalDays.max || 0} Days
                                        </div>
                                    </div>
                                </div>
                                <div className="border-b"></div>
                            </>
                        )
                    })}
                </div>
            )}
        </>
    )
}

export default TableMilestoneShipment
