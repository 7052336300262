import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {
    IBookingStatusRespon,
    IContainerModerRespon,
    IContainerRespon,
    INewsData,
    IOpenInvoicesAdditionalValue,
    IScfiData,
    IShipmentStatusRespon,
    IShipmentStatusRespon2,
} from './dashboard.interface'
import { ILineChartItem } from '@components/rechart-component/rechart-interface'
import { IDashboardFilterValidation } from 'form-validation/dashboard.validation'
import {
    IRShipment,
    IRShipmentFilter,
} from 'repository/interface/shipment.interface'
import { IMeta, IResponseData } from 'common/common.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    metaDataDropdown,
    IFieldDropdownMetaData,
} from '../bookings/bookings.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { IPieChartData } from '@components/rechart-component/pie/doughnut-chart.component'
import { InfoCardGroupItems } from '@components/InfoCardGroup/Info-card-group.interface'
import { IROpenInvoiceData } from 'repository/interface/dashboard.interface'
import { formatDecimalNumber } from '@services/common.service'

interface IDashboardState {
    news: INewsData[]
    scfi_index: IScfiData[]
    barInvoice: ILineChartItem[]
    barContainer: ILineChartItem[]
    transtMode: IDropdownItem[]
    statusContainerMode: {
        fclShipment: {
            sea: number
            road: number
            rail: number
        }
        lclShipment: {
            sea: number
            road: number
            rail: number
        }
    }
    filters: IDashboardFilterValidation
    itemInvoice: Array<{
        value: string
        label: string
    }>
    itemInvoiceUnPaid: Array<{
        value: string
        label: string
    }>
    openInvoices: IPieChartData<IOpenInvoicesAdditionalValue>[]
    dataItem: {
        quote: {
            total: number
            approved: number
            pending: number
            delayed: number
            newQuotes: number
        }
        bookings: IBookingStatusRespon
        shipment: IShipmentStatusRespon
        shipment2: IShipmentStatusRespon2
        warehouse: {
            total: number
            inStock: number
            lowStock: number
            outOfStock: number
            newOrders: number
        }
        co2Emissions: {
            total: number
            sea: number
            air: number
            road: number
            rail: number
        }
    }
    TrackingShipmentLocation: {
        data: IRShipment[]
        meta: IMeta
    }
    filterShipmentLocation: IRShipmentFilter
    statusSelected: ITabItem
}

export const initialState: IDashboardState = {
    news: [],
    scfi_index: [],
    filters: {
        TransportModeCode: '',
        ShipmentDateBy: '',
        StartMonth: '',
        EndMonth: '',
    },
    itemInvoice: [
        { value: '0', label: 'Total Invoice Paid' },
        { value: '', label: 'Average per month' },
        { value: '', label: 'Total paid amount' },
    ],
    itemInvoiceUnPaid: [
        { value: '0', label: 'Total open invoices' },
        { value: '', label: 'Total open invoices amount' },
    ],
    barContainer: [
        {
            name: 'FCL',
            number: 0,
            fill: '',
        },
        {
            name: 'LCL',
            number: 0,
            fill: '',
        },
        {
            name: 'LSE',
            number: 0,
            fill: '',
        },
        {
            name: 'FTL',
            number: 0,
            fill: '',
        },
        {
            name: 'COU',
            number: 0,
            fill: '',
        },
    ],
    statusContainerMode: {
        fclShipment: {
            sea: 0,
            road: 0,
            rail: 0,
        },
        lclShipment: {
            sea: 0,
            road: 0,
            rail: 0,
        },
    },
    barInvoice: [],
    openInvoices: [
        {
            name: 'Within payment term',
            value: 0,
            fill: '#C0C7CD',
            textColor: '#FFFFFF',
            key: 'withinPaymentTerm',
        },
        {
            name: '1 week overdue',
            value: 0,
            fill: '#5B6C7C',
            textColor: '#FFFFFF',
            key: 'oneWeek',
        },
        {
            name: '2 weeks overdue',
            value: 0,
            fill: '#FFE3A4',
            textColor: '#FFFFFF',
            key: 'twoWeek',
        },
        {
            name: '1 month overdue',
            value: 0,
            fill: '#E3CD9A',
            textColor: '#FFFFFF',
            key: 'oneMonth',
        },
        {
            name: '2 months overdue',
            value: 0,
            fill: '#F691A9',
            textColor: '#FFFFFF',
            key: 'twoMonth',
        },
        {
            name: '3 month overdue',
            value: 0,
            fill: '#C9677F',
            textColor: '#FFFFFF',
            key: 'threeMonth',
        },
    ],
    dataItem: {
        quote: {
            total: 0,
            approved: 0,
            pending: 0,
            delayed: 0,
            newQuotes: 0,
        },
        bookings: {
            Draft: 0,
            'Document Sent': 0,
            Booked: 0,
            'Booking Confirmed': 0,
        },
        shipment: {
            AheadOfSchedule: 0,
            Delayed: 0,
            NewShipment: 0,
            OnSchedule: 0,
            TotalShipment: 0,
        },
        warehouse: {
            total: 0,
            inStock: 0,
            lowStock: 0,
            outOfStock: 0,
            newOrders: 0,
        },
        co2Emissions: {
            total: 0,
            sea: 0,
            air: 0,
            road: 0,
            rail: 0,
        },
        shipment2: {
            'Delivery Cartage Advised': 0,
            'Pickup Cartage Complete': 0,
            'Origin Receival from Wharf': 0,
            'All Export Documents Received': 0,
        },
    },
    TrackingShipmentLocation: {
        data: [],
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total_page: 0,
            total_Items: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },
    filterShipmentLocation: {
        status: '',
        inProgressStatus: '',
        pageNumber: 1,
        pageSize: 15,
    },
    statusSelected: {
        label: 'All Invoces',
        key: 'status',
        value: 'allInvoices',
        className: 'brand',
    },
    transtMode: [{ value: 'all', label: 'All Modes' }],
}

const dashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {
        setNews: (state, action: PayloadAction<INewsData[]>) => {
            state.news = action.payload
        },
        setShipmentStatus: (
            state,
            action: PayloadAction<IShipmentStatusRespon>,
        ) => {
            state.dataItem.shipment = action.payload
        },
        setShipmentStatus2: (
            state,
            action: PayloadAction<IShipmentStatusRespon2>,
        ) => {
            state.dataItem.shipment2 = action.payload
        },
        setBookingStatus: (
            state,
            action: PayloadAction<IBookingStatusRespon>,
        ) => {
            state.dataItem.bookings = action.payload
        },
        setContainermodeStatus: (
            state,
            action: PayloadAction<IContainerModerRespon>,
        ) => {
            const { fclShipment, lclShipment, container } = action.payload

            const statusContainerMode = {
                fclShipment,
                lclShipment,
            }

            const nameMap: { [key: string]: string } = {
                fcl: 'FCL',
                lcl: 'LCL',
                loose: 'LSE',
                ftl: 'FTL',
                courier: 'COU',
            }
            const updatedChartData = state.barContainer.map((chartItem) => {
                const key = Object.keys(nameMap).find(
                    (k) => nameMap[k] === chartItem.name,
                ) as keyof IContainerRespon // Tambahkan `keyof IContainerRespon`

                return key
                    ? {
                          ...chartItem,
                          fill: '#D9DDE1',
                          number: container[key],
                      }
                    : { ...chartItem, fill: '#D9DDE1' }
            })
            state.barContainer = updatedChartData
            state.statusContainerMode = statusContainerMode
        },
        setTrackingShipmentLocation(
            state,
            action: PayloadAction<IResponseData<IRShipment[]>>,
        ) {
            const { data, meta } = action.payload
            const dataTrackingShipment = {
                meta: meta,
                data: data,
            }
            const d = {
                TrackingShipmentLocation: dataTrackingShipment,
            }
            return {
                ...state,
                ...d,
            }
        },
        setDashboardDropdown(state, action: PayloadAction<metaDataDropdown>) {
            const convertToDropdownItems = (
                dropdown: IFieldDropdownMetaData[],
            ): IDropdownItem[] => {
                const items = dropdown.map((data, index) => ({
                    label:
                        index === 0
                            ? 'All Modes'
                            : data.item + ' - ' + data.value,
                    value: index === 0 ? 'all' : data.item,
                }))
                return items
            }

            const item = action.payload.transportMode
            return {
                ...state,
                transtMode: convertToDropdownItems(item),
            }
        },
        setScfiIndex: (state, action: PayloadAction<IScfiData[]>) => {
            state.scfi_index = action.payload
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filterShipmentLocation, pageNumber }
            return {
                ...state,
                filterShipmentLocation: filter,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload

            return {
                ...state,
                statusSelected,
            }
        },
        setBarInvoice(
            state,
            action: PayloadAction<{
                chartData: ILineChartItem[]
                cardInfoData: InfoCardGroupItems[]
            }>,
        ) {
            state.barInvoice = action.payload.chartData
            state.itemInvoice = action.payload.cardInfoData
        },
        setOpenInvoices(
            state,
            action: PayloadAction<{
                data: IROpenInvoiceData
                currency: string
            }>,
        ) {
            const payloadData = action.payload

            // summary data
            const summaryData: InfoCardGroupItems[] = [
                {
                    value:
                        payloadData.data?.totalOpenInvoice?.toString() || '0',
                    label: 'Total open invoices',
                },
                {
                    value: `${payloadData?.currency} ${formatDecimalNumber(Number(parseFloat(payloadData.data?.totalOpenInvoiceAmount || '0').toFixed(2)))}`,
                    label: 'Total open invoices amount',
                },
            ]

            // pie chart data
            const updatedData: IPieChartData<IOpenInvoicesAdditionalValue>[] =
                state?.openInvoices?.map((item) => {
                    if (item?.key) {
                        const matchData2 = payloadData?.data[
                            item.key as keyof IROpenInvoiceData
                        ] as {
                            amount: string
                            percent: string
                        }

                        if (!matchData2)
                            return {
                                ...item,
                                additionalValue: {
                                    currency: payloadData?.currency || '',
                                    value: '0.00',
                                },
                            }

                        return {
                            ...item,
                            value: Number(matchData2?.amount || 0),
                            additionalValue: {
                                currency: payloadData?.currency || '',
                                value: formatDecimalNumber(
                                    Number(
                                        parseFloat(
                                            matchData2.amount || '0.00',
                                        ).toFixed(2),
                                    ),
                                ),
                            },
                        }
                    }

                    return {
                        ...item,
                    }
                })

            state.openInvoices = updatedData
            state.itemInvoiceUnPaid = summaryData
        },
    },
})

export const {
    setNews,
    setScfiIndex,
    setShipmentStatus,
    setBookingStatus,
    setContainermodeStatus,
    setTrackingShipmentLocation,
    setDashboardDropdown,
    setPageNumber,
    setSelectedStatus,
    setShipmentStatus2,
    setBarInvoice,
    setOpenInvoices,
} = dashboardSlice.actions

export const newsSelector = (state: RootState) => state.dashboardIFB.news
export const itemSelector = (state: RootState) => state.dashboardIFB.dataItem
export const filtersSelector = (state: RootState) => state.dashboardIFB.filters
export const dropdownTransModeSelector = (state: RootState) =>
    state.dashboardIFB.transtMode
export const barInvoiceSelector = (state: RootState) =>
    state.dashboardIFB.barInvoice
export const trackingShipmentLocationeSelector = (state: RootState) =>
    state.dashboardIFB.TrackingShipmentLocation
export const itemInvoiceSelector = (state: RootState) =>
    state.dashboardIFB.itemInvoice
export const itemInvoiceUnPaidSelector = (state: RootState) =>
    state.dashboardIFB.itemInvoiceUnPaid
export const barContainerSelector = (state: RootState) =>
    state.dashboardIFB.barContainer
export const statusContainerModeSelector = (state: RootState) =>
    state.dashboardIFB.statusContainerMode
export const openInvoicesSelector = (state: RootState) =>
    state.dashboardIFB.openInvoices
export const scfiIndexSelector = (state: RootState) =>
    state.dashboardIFB.scfi_index
export const filterShipmentDataSelector = (state: RootState) =>
    state.dashboardIFB.filterShipmentLocation
export const tabStatusFilterSelector = (state: RootState) =>
    state.dashboardIFB.statusSelected || {}

export default dashboardSlice.reducer
