/* eslint-disable no-unused-vars */
import {
    ICreateQuoteValidation,
    createQuoteInitialValue,
    createQuoteValidation,
} from 'form-validation/create-quotation.validation'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ratesDummy } from '../quotations.dummy'
import { Toast } from '@components/toast/toast.component'

const useCreateQuotation = () => {
    const navigate = useNavigate()
    const [rateData, setRateData] = useState(ratesDummy)

    // formik
    const formik = useFormik<ICreateQuoteValidation>({
        validationSchema: createQuoteValidation,
        initialValues: createQuoteInitialValue,
        onSubmit: (values) => {
            const updateData = ratesDummy.filter(
                (item) =>
                    item.originPortCode === values.originPort &&
                    item.destinationPortCode === values.destinationPort,
            )
            setRateData(updateData)
        },
    })

    const selectRateOnclick = () => {
        Toast({
            header: 'Success',
            message: 'Successfully created a new quote',
            type: 'success',
        })
        navigate('/quotes')
    }

    return { navigate, selectRateOnclick, rateData, formik }
}

export default useCreateQuotation
