import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IItemListInfo } from '@components/item-list-info/item-list-info.interface'
import { ITabItem } from '@components/tab/tab.interface'
import {
    IMilestonePerformanceItemAdditionalValue,
    IMilestonePerformance,
    IMilestoneTransitTime,
    IMilestoneTransitTimeItemAdditionalValue,
} from './performances.interface'
import { IProgressMultipleBarKeys } from '@components/progress-multiple-bars/progress-multiple-bars.interface'

export const transportModeMap = {
    ROA: 'ROAD',
    SEA: 'SEA',
    AIR: 'AIR',
    RAI: 'RAIL',
    COURIER: 'COURIER',
}

export const filterShipmentDateBy: IDropdownItem[] = [
    {
        value: 'etd',
        label: 'ETD',
    },
    {
        value: 'eta',
        label: 'ETA',
    },
]

export const filterCurrency: IDropdownItem[] = [
    {
        value: 'PLN',
        label: 'PLN, Polish zloty',
    },
]

export const itemListDelayedShipments: IItemListInfo[] = [
    {
        label: 'Selected Period',
        color: 'extra-red-v2',
    },
    {
        label: 'Previous Period',
        color: 'gray-v4',
    },
]

export const itemListShipmentsPerformance: IItemListInfo[] = [
    {
        label: 'All Delayed Shipments',
        color: '#C9677F',
    },
    {
        label: 'In Time Shipments',
        color: '#69BAA4',
    },
]

export const itemListTableCardPerfom: IItemListInfo[] = [
    {
        label: 'Loading',
        color: '#E3CD9A',
    },
    {
        label: 'Transit Time',
        color: '#5280C6',
    },
    {
        label: 'Discharge',
        color: '#C0C7CD',
    },
]

export const itemListTableCardTransit: IItemListInfo[] = [
    {
        label: 'Actual Transit Time',
        color: 'gray-v5',
    },
]

export const itemListMonthlyCost: IItemListInfo[] = [
    {
        label: 'Sea Freight',
        color: '#5280C6',
    },
    {
        label: 'Air Freight',
        color: '#76C6CC',
    },
    {
        label: 'Road Freight',
        color: '#9D75AD',
    },
    {
        label: 'Rail Freight',
        color: '#E3CD9A',
    },
    {
        label: 'Courier Freight',
        color: '#C98167',
    },
]

export const itemListShipmentsCosts: IItemListInfo[] = [
    {
        label: 'Loading Costs',
        color: 'gray-v2',
    },
    {
        label: 'Main Freight Costs',
        color: 'gray-v5',
    },
    {
        label: 'Discharge Costs',
        color: 'gray-v7',
    },
]

export const itemListShipmentsTransit: IItemListInfo[] = [
    {
        label: 'Planned Transit Time',
        color: 'gray-v2',
    },
    {
        label: 'Actual Transit Time',
        color: 'gray-v5',
    },
]

// segment performance
export const tabPerformanceItems: ITabItem[] = [
    {
        label: 'SEA',
        key: 'status',
        value: 'SEA',
        className: 'brand',
    },
    {
        label: 'AIR',
        key: 'status',
        value: 'AIR',
        className: 'brand',
    },
    {
        label: 'ROAD',
        key: 'status',
        value: 'ROAD',
        className: 'brand',
    },
    {
        label: 'RAIL',
        key: 'status',
        value: 'RAIL',
        className: 'brand',
    },
    {
        label: 'COURIER',
        key: 'status',
        value: 'COURIER',
        className: 'brand',
    },
]

export const progressMultipleBarKeys: IProgressMultipleBarKeys<
    IMilestonePerformance,
    IMilestonePerformanceItemAdditionalValue
>[] = [
    {
        renderTooltip: ({ additionalValue }) => {
            if (!additionalValue) return ''
            const { loading } = additionalValue
            return `Loading: ${loading.min} - ${loading.max} Days`
        },
        key: 'loading',
        bgColor: '#E3CD9A',
    },
    {
        renderTooltip: ({ additionalValue }) => {
            if (!additionalValue) return ''
            const { transitTime } = additionalValue
            return `Transit Time: ${transitTime.min} - ${transitTime.max} Days`
        },
        rendervalue: ({ additionalValue }) => {
            if (
                !additionalValue ||
                additionalValue?.transitTime.min === 0 ||
                additionalValue?.transitTime.min === 0
            )
                return
            const { transitTime } = additionalValue
            return `${transitTime.min} - ${transitTime.max} Days`
        },
        key: 'transitTime',
        bgColor: '#5280C6',
        textColor: '#FFFFFF',
    },
    {
        renderTooltip: ({ additionalValue }) => {
            if (!additionalValue) return ''
            const { discharge } = additionalValue
            return `Discharge: ${discharge.min} - ${discharge.max} Days`
        },
        key: 'discharge',
        bgColor: '#C0C7CD',
    },
]

// segment transit time
export const tabServiceTypeItems: ITabItem[] = [
    {
        label: 'Door to Door',
        key: 'status',
        value: 'Door to Door',
        className: 'brand',
    },
    {
        label: 'Deferred',
        key: 'status',
        value: 'Deferred',
        className: 'brand',
    },
    {
        label: 'Direct',
        key: 'status',
        value: 'Direct',
        className: 'brand',
    },
    {
        label: 'Standard',
        key: 'status',
        value: 'Standard',
        className: 'brand',
    },
    {
        label: 'Transhipment',
        key: 'status',
        value: 'Transhipment',
        className: 'brand',
    },
]
export const progressMultipleBarKeyTransitTime: IProgressMultipleBarKeys<
    IMilestoneTransitTime,
    IMilestoneTransitTimeItemAdditionalValue
>[] = [
    {
        renderTooltip: ({ additionalValue }) => {
            if (!additionalValue) return ''
            const { actualTransitTime } = additionalValue
            return `Loading: ${actualTransitTime.min} - ${actualTransitTime.max} Days`
        },
        key: 'actualTransitTime',
        bgColor: '#8D99A5',
    },
]
