import * as Yup from 'yup'
import moment from 'moment'

// interface for validation
export interface IDashboardFilterValidation {
    TransportModeCode: string
    ShipmentDateBy: string
    StartMonth: string
    EndMonth: string
}

export const useDashboardFilterValidation = () => {
    const dashboardFilterValidation = Yup.object().shape({
        StartMonth: Yup.string()
            .required('Start Month is required')
            .matches(
                /^(0[1-9]|1[0-2])\/\d{4}$/,
                'Invalid Start Month format (MM/YYYY)',
            )
            .test(
                'is-greater',
                'End Month cannot be earlier then the start month',
                function (value) {
                    const { EndMonth } = this.parent
                    if (!value || !EndMonth) return false

                    const start = moment(EndMonth, 'MM/YYYY')
                    const end = moment(value, 'MM/YYYY')

                    return start.isSameOrAfter(end)
                },
            )
            .test(
                'max-range',
                'Date filter range cannot be more than 12 months',
                function (value) {
                    const { EndMonth } = this.parent
                    if (!value || !EndMonth) return false

                    const end = moment(EndMonth, 'MM/YYYY')
                    const start = moment(value, 'MM/YYYY')

                    return end.diff(start, 'months') < 12
                },
            ),

        EndMonth: Yup.string()
            .required('End Month is required')
            .matches(
                /^(0[1-9]|1[0-2])\/\d{4}$/,
                'Invalid End Month format (MM/YYYY)',
            )
            .test(
                'is-greater',
                'End Month cannot be earlier then the start month',
                function (value) {
                    const { StartMonth } = this.parent
                    if (!value || !StartMonth) return false

                    const start = moment(StartMonth, 'MM/YYYY')
                    const end = moment(value, 'MM/YYYY')

                    return end.isSameOrAfter(start)
                },
            )
            .test(
                'max-range',
                'Date filter range cannot be more than 12 months',
                function (value) {
                    const { StartMonth } = this.parent
                    if (!value || !StartMonth) return false

                    const start = moment(StartMonth, 'MM/YYYY')
                    const end = moment(value, 'MM/YYYY')

                    return end.diff(start, 'months') < 12
                },
            ),
    })

    const monthNow = moment().subtract(0, 'months').format('MM/YYYY')
    const sixMonthsAgo = moment().subtract(5, 'months').format('MM/YYYY')
    const dashboardFilterInitialValue: IDashboardFilterValidation = {
        TransportModeCode: 'all',
        ShipmentDateBy: 'eta',
        StartMonth: sixMonthsAgo,
        EndMonth: monthNow,
        // StartMonth: '01/2023',
        // EndMonth: '12/2023',
    }

    return {
        dashboardFilterValidation,
        dashboardFilterInitialValue,
    }
}
