import { fetch, post } from 'common/common.service'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import { Toast } from '@components/toast/toast.component'
import {
    metaDataDropdown,
    IBookings,
    IBookingsDetailsResponse,
    IBookingsFilter,
    IFieldDropdownContactName,
    IFieldDropdownCompany,
} from '../pages/bookings/bookings.interface'
import { IResponseData } from 'common/common.interface'
import {
    ICreateBookingPayload,
    ICreateBookingResponse,
} from './interface/booking.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { initialResponseData } from 'common/common.static'

export const getBookingsData = async (filter: IBookingsFilter) => {
    try {
        const response = await fetch<
            IResponseData<IBookings[]>,
            IBookingsFilter
        >({
            endpoint: ifbEndpoints.bookings,
            params: filter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Bookings data',
                message: `Failed to fetch Bookings data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        if (error instanceof Error) {
            Toast({
                header: 'Bookings data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Bookings data',
                message: `Unknown error occurred while fetching Bookings data."`,
                type: 'error',
            })
        }
        const errResponse: IResponseData<IBookings[]> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const getBookingsID = async (idBookings: string) => {
    try {
        const endpoint = `${ifbEndpoints.bookings}/${idBookings}`
        const response = await fetch<
            IResponseData<IBookingsDetailsResponse>,
            string
        >({
            endpoint: endpoint,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Manage User ID data',
                message: `Failed to fetch Manage User data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Manage User ID data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Manage User ID data',
                message: `Unknown error occurred while fetching Manage User ID data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IBookingsDetailsResponse>
        err.isSuccess = false
        return err
    }
}

export const getGetMetadataBookings = async ({
    idForm,
    country = '',
    origin = '',
    destination = '',
    currency = '',
    company = '',
    container = '',
}: {
    idForm: string
    country?: string
    origin?: string
    destination?: string
    currency?: string
    company?: string | IDropdownItem<IFieldDropdownCompany>[]
    container?: string
}) => {
    try {
        const endpoint = `${ifbEndpoints.bookingsGetMetadata}?mode=${idForm}&country=${country}&origin=${origin}&destination=${destination}&currency=${currency}&container=${container}&company=${company}`

        const response = await fetch<metaDataDropdown, string>({
            endpoint: endpoint.toString(),
        })

        return response
    } catch (error: unknown) {
        const errorMessage =
            error instanceof Error
                ? error.message
                : 'Unknown error occurred while fetching Bookings Metadata.'

        Toast({
            header: 'Bookings Metadata',
            message: errorMessage,
            type: 'error',
        })

        return error
    }
}

export const getGetContactData = async ({
    idCompany,
}: {
    idCompany: string
}) => {
    try {
        const endpoint = `${ifbEndpoints.bookingsGetContactMetadata}?idCompany=${idCompany}`
        const response = await fetch<IFieldDropdownContactName, string>({
            endpoint: endpoint.toString(),
        })

        return response
    } catch (error: unknown) {
        const errorMessage =
            error instanceof Error
                ? error.message
                : 'Unknown error occurred while fetching Bookings Metadata.'

        Toast({
            header: 'Bookings Metadata',
            message: errorMessage,
            type: 'error',
        })

        return error
    }
}

export const BookingsCreate = async (payload: ICreateBookingPayload) => {
    try {
        const endpoint = `${ifbEndpoints.bookingsCreate}`
        const response = await post<
            IResponseData<ICreateBookingResponse>,
            string[]
        >({
            endpoint,
            payload,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<ICreateBookingResponse>
        console.error(err)
        err.isSuccess = false
        return err
    }
}
