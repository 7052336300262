import { IResponseData } from 'common/common.interface'
import { fetch, rangeDate } from 'common/common.service'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import { IPerformanceFilterValidation } from '../form-validation/performance.validation'
import {
    IFetchAverageDaysDelayed,
    IFetchAverageTransitTime,
    IFetchAverageTransitTimeByMode,
    IFetchMilestoneGroupDayRange,
    IFetchMonthlyTotalCost,
    IFetchPortCharges,
    IFetchShipmentsTransitTimeByPorts,
    IFetchTotalCostByModes,
    IFetchTotalCostByPorts,
    IFetchTransitTimeRouteGroup,
    IFilterPerformance,
    IShipmentStatusData,
    shipmentPerformanceByPorts,
} from './interface/performance.interface'
import { initialResponseData } from 'common/common.static'

const setUpdatedFilter = (
    filter: IPerformanceFilterValidation,
    performanceMode: string,
) => {
    const { startMonth, endMonth, currency } = filter

    const loadRangeDate = rangeDate(startMonth, endMonth)

    const updatedFilter = {
        StartDate: loadRangeDate.formattedStartMonth,
        EndDate: loadRangeDate.formattedEndMonth,
        PerformanceMode: performanceMode,
        ...(currency && {
            CurrencyCode: currency,
        }),
        ShipmentDateBy: filter.shipmentDateBy,
    }

    return updatedFilter
}

export const fetchShipmentPerformanceStatus = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'performance')
    try {
        const response = await fetch<
            IResponseData<IShipmentStatusData>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_getStatus,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IShipmentStatusData>
        console.error(err)
        err.isSuccess = false
        return err
    }
}

export const fetchShipmentPerformanceByPorts = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'performance')

    try {
        const response = await fetch<
            IResponseData<shipmentPerformanceByPorts>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_getByPorts,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<shipmentPerformanceByPorts>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchMilestoneGroupDayRange = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'performance')
    try {
        const response = await fetch<
            IResponseData<IFetchMilestoneGroupDayRange[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_milestoneGroupDayRange,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchMilestoneGroupDayRange[]>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchAverageDaysDelayed = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'performance')
    try {
        const response = await fetch<
            IResponseData<IFetchAverageDaysDelayed>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_averageDaysDelayed,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<IFetchAverageDaysDelayed> =
            initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const fetchAverageTransitTime = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'transitTime')

    try {
        const response = await fetch<
            IResponseData<IFetchAverageTransitTime[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_averageTransitTime,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchAverageTransitTime[]>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchShipmentsTransitTimeByPorts = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'transitTime')
    try {
        const response = await fetch<
            IResponseData<IFetchShipmentsTransitTimeByPorts[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_shipmentsTransitTimeByPorts,
            params: updatedFilter,
        })

        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchShipmentsTransitTimeByPorts[]>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchAverageTransitTimeByMode = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'transitTime')
    try {
        const response = await fetch<
            IResponseData<IFetchAverageTransitTimeByMode[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_averageTransitTimeByMode,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchAverageTransitTimeByMode[]>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchTransitTimeRouteGroup = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'transitTime')
    try {
        const response = await fetch<
            IResponseData<IFetchTransitTimeRouteGroup[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_transitTimeRouteGroup,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchTransitTimeRouteGroup[]>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchTotalCostByModes = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'cost')
    try {
        const response = await fetch<
            IResponseData<IFetchTotalCostByModes>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_totalCostByModes,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchTotalCostByModes>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchMonthlyTotalCost = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'cost')
    try {
        const response = await fetch<
            IResponseData<IFetchMonthlyTotalCost[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_totalMonthlyCost,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchMonthlyTotalCost[]>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchPortCharges = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'cost')
    try {
        const response = await fetch<
            IResponseData<IFetchPortCharges>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_portCharges,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchPortCharges>
        err.isSuccess = false
        console.error(err)
        return err
    }
}

export const fetchTotalCostByPorts = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'cost')
    try {
        const response = await fetch<
            IResponseData<IFetchTotalCostByPorts[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_totalCostByPorts,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchTotalCostByPorts[]>
        err.isSuccess = false
        console.error(err)
        return err
    }
}
