import { IResponseData } from 'common/common.interface'
import {
    IRShipment,
    IRShipmentDetails,
    IRShipmentFilter,
} from './interface/shipment.interface'
import { fetch } from 'common/common.service'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'
import { Toast } from '@components/toast/toast.component'
import { initialResponseData } from 'common/common.static'

export const getShipmentsData = async (filter: IRShipmentFilter) => {
    try {
        const response = await fetch<
            IResponseData<IRShipment[]>,
            IRShipmentFilter
        >({
            endpoint: ifbEndpoints.shipment_getAll,
            params: filter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Shipments data',
                message: `Failed to fetch Shipments data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        if (error instanceof Error) {
            Toast({
                header: 'Shipments data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Shipments data',
                message: `Unknown error occurred while fetching Shipments data."`,
                type: 'error',
            })
        }
        const errResponse: IResponseData<IRShipment[]> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const getShipmentDetails = async (shipmentId: string) => {
    try {
        const response = await fetch<IResponseData<IRShipmentDetails>, string>({
            endpoint: `${ifbEndpoints.shipment_getAll}/${shipmentId}`,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Shipment Details',
                message: `Failed to fetch Shipment Details data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Shipments Details',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Shipments Details',
                message: `Unknown error occurred while fetching Shipments Details."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IRShipmentDetails>
        err.isSuccess = false
        return err
    }
}
