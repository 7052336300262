import Modal from '@components/modal/modal.component'
import { IUseCreateBooking } from '../create-booking.interface'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { ICreateBookingContainersValidation } from 'form-validation/create-booking.validation'
import { ICointainerTypeData } from 'repository/interface/loglinesMetaData.interface'

const FormContainerModal = ({
    CBService,
}: {
    CBService: IUseCreateBooking
}) => {
    const borderComponent = <div className="border-b"></div>
    const formTitle =
        CBService.containerParams === 'add' ? 'Add Container' : 'Edit Container'
    return (
        <Modal
            isModalOpen={CBService.formContainerModal.isModalOpen}
            className="!w-[25%] px-0 flex flex-col gap-3"
        >
            <>
                <div className="px-3 themes-text-gray-v9 text-size-M">
                    {formTitle}
                </div>
                {borderComponent}
                <div className="px-3 flex flex-col gap-3 mobile:h-[calc(100vh-22rem)] mobile:overflow-auto">
                    <FormikProvider
                        value={CBService.formikCreateContainerBookings}
                    >
                        <FormDropdown
                            isSearchable
                            isClearable
                            label={'CONTAINER TYPE'}
                            name={`containerType.code`}
                            placeholder={'Select here'}
                            options={CBService.dropdownData.containerModes}
                            defaultValue={CBService.dropdownData.containerModes}
                            parentDivClassName="!mb-0"
                            additionalOnClick={(data) => {
                                CBService.additionalClickSetDesc<ICreateBookingContainersValidation>(
                                    CBService.formikCreateContainerBookings,
                                    'containerType.description',
                                    data as IDropdownItem<any>,
                                )
                            }}
                            useUppercaseLabel
                        />
                        <FormInput
                            label={'Count'}
                            name={`containerCount`}
                            placeholder={'Enter here'}
                            parentDivClassName="!mb-0"
                            type={'number'}
                            useUppercaseLabel
                            useArrow={false}
                            maxLength={36}
                        />
                        {/* <FormInput
                            label={'Container Number'}
                            name={`containerNumber`}
                            placeholder={'Select here'}
                            parentDivClassName="!mb-0"
                            useUppercaseLabel
                        /> */}
                    </FormikProvider>
                </div>
                {borderComponent}
                <div className="flex  px-3 justify-between">
                    <Button
                        onClick={() => {
                            CBService.formContainerModal.closeModalHandling()
                        }}
                        label="CANCEL"
                        variant="brand-v2-inverse"
                    />
                    <Button
                        onClick={() => {
                            CBService.formikCreateContainerBookings.submitForm()
                        }}
                        label="PROCEED"
                        variant="brand-v2-inverse"
                    />
                </div>
            </>
        </Modal>
    )
}

export default FormContainerModal
