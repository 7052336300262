// interface for validation
export interface IFormikBookingFilter {
    consignor: string[]
    consignee: string[]
    destination: string[]
    origin: string[]
}

// form login initial data
export const bookingFilterInitialValue: IFormikBookingFilter = {
    consignor: [],
    consignee: [],
    destination: [],
    origin: [],
}
