import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

// interface for validation
export interface IForgotPasswordValidation {
    email: string
}

export const useForgotPasswordValidation = () => {
    const { t } = useTranslation()

    // form login initial data
    const forgotPasswordInitialValue: IForgotPasswordValidation = {
        email: '',
    }

    // form validation YUP for formik
    const forgotPasswordValidation: Yup.ObjectSchema<IForgotPasswordValidation> =
        Yup.object().shape({
            email: Yup.string()
                .email(t('message.email_invalid'))
                .required(
                    t('message.required', {
                        value: t('login.email_address'),
                    }),
                ),
        })

    return { forgotPasswordInitialValue, forgotPasswordValidation }
}
