var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from '@components/button/button.component';
import Overlay from '@components/overlay/overlay.component';
import useOverlay from '@components/overlay/overlay.service';
var RowOptionOverlay = function (_a) {
    var rowOptionComponent = _a.rowOptionComponent, data = _a.data;
    var overlayService = useOverlay();
    return (_jsx(_Fragment, { children: _jsxs("td", __assign({ className: "sticky z-[1] right-0 w-5 text-right" }, { children: [_jsx(Button, { onClick: function () {
                        overlayService === null || overlayService === void 0 ? void 0 : overlayService.openOverlayHandling();
                    }, icon: "ri-more-2-line", className: "border-none w-btnIconWidth themes-bg-white" }), _jsx(Overlay, __assign({ overlayService: overlayService, className: "!w-[10rem] shadow p-1 !absolute mr-[10px]" }, { children: _jsx("div", { children: rowOptionComponent(data, overlayService.closeOverlayHandling) }) }))] })) }));
};
export default RowOptionOverlay;
