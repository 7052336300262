import { IMeta, IResponseData } from 'common/common.interface'
import {
    IContainerMilestone,
    ITrackingResponse,
    ITrackingFilter,
    ITrackingStatusTotal,
} from 'repository/interface/tracking.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { formatDate } from 'common/common.service'
import { ITrackerItemV2 } from '@components/tracker-v2/tracker-v2.component'
import { ITrackingTable } from 'common/components/tracking-table-expandable-custom.component'

interface ITrackingsSlice {
    data: ITrackingTable[]
    meta: IMeta
    responseMessage: string
    filter: ITrackingFilter
    statusSelected: ITabItem
    statusTotal: ITrackingStatusTotal

    // segment details
    // detailsData: IShipmentDetails
}

export const initialState: ITrackingsSlice = {
    data: [],
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        total_Items: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    responseMessage: '',
    filter: {
        consolStatus: '',
        pageNumber: 1,
        pageSize: 50,
    },
    statusTotal: {
        all: 0,
        sea: 0,
        air: 0,
        roa: 0,
        others: 0,
    },
    statusSelected: {
        value: 'all',
        key: 'status',
        label: 'All Mode',
        totalData: 0,
    },
}

const trackingSlice = createSlice({
    name: 'trackingIFB',
    initialState,
    reducers: {
        setTrackingData(
            state,
            action: PayloadAction<IResponseData<ITrackingResponse[]>>,
        ) {
            const { data, isSuccess, message, meta, links, additionals } =
                action.payload

            const updatedStatusTotal: ITrackingStatusTotal = {
                all: 0,
                sea: 0,
                air: 0,
                roa: 0,
                others: 0,
            }

            // map count status data
            Object.keys(additionals).forEach((status) => {
                const statusName = status.toLowerCase()
                if (statusName in updatedStatusTotal) {
                    updatedStatusTotal[
                        statusName as keyof ITrackingStatusTotal
                    ] = additionals[status]
                }
            })

            const loadTrackingItems = (
                trackingItems: IContainerMilestone[],
            ) => {
                if (!trackingItems.length) return []
                const sortedMilestones = trackingItems.sort((a, b) => {
                    return (
                        new Date(b.actualDate).getTime() -
                        new Date(a.actualDate).getTime()
                    )
                })

                const updatedTrackingItems: ITrackerItemV2[] =
                    sortedMilestones?.map((item) => ({
                        statusDesc: item.description,
                        date: item.actualDate
                            ? formatDate(item.actualDate, 'DD MMM YYYY')
                            : '-',
                    }))
                return updatedTrackingItems
            }

            const updateTrackingTable: ITrackingTable[] = data?.map((item) => ({
                transportModeCode: item?.transportMode?.code,
                containerTypeCode: item?.containerType?.code,
                containerMode: {
                    code: item?.containerMode?.code,
                    desc: item?.containerMode?.description,
                },
                deliverymode: item.deliverymode,
                consolNumber: item.consolNumber,
                seal: item.seal,
                vesselName: item.vesselName,
                voyageFlightNo: item.voyageFlightNo,
                relatedShipmentNumber: item?.relatedShipmentNumber,
                trackerItems: loadTrackingItems(item.containerMilestone),
                containerNumber: item.containerNumber,
                portOfLoading: {
                    code: item.portOfDischarge.code,
                    desc: item.portOfDischarge.name,
                },
                portOfDischarge: {
                    code: item.portOfDischarge.code,
                    desc: item.portOfDischarge.name,
                },
                etd: item.etd,
                eta: item.eta,
            }))

            const d = {
                data: updateTrackingTable,
                isSuccess,
                message,
                meta,
                links,
                statusTotal: updatedStatusTotal,
            }

            return {
                ...state,
                ...d,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload

            return {
                ...state,
                statusSelected,
            }
        },
        setFilter(state, action: PayloadAction<ITrackingFilter>) {
            const filter = action.payload
            return {
                ...state,
                filter,
            }
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filter, pageNumber }
            return {
                ...state,
                filter,
            }
        },
    },
})

// these all the variables used for selector
export const trackingsDataSelector = (state: RootState) =>
    state.trackingIFB.data || {}
export const filterSelector = (state: RootState) =>
    state.trackingIFB.filter || {}
export const trackingsMetaSelector = (state: RootState) =>
    state.trackingIFB.meta || {}
export const trackingsFilterSelector = (state: RootState) =>
    state.trackingIFB.filter || {}
export const trackingsStatusTotalSelector = (state: RootState) =>
    state.trackingIFB.statusTotal || {}
export const tabStatusFilterSelector = (state: RootState) =>
    state.trackingIFB.statusSelected || {}

// all actions
export const { setTrackingData, setSelectedStatus, setPageNumber, setFilter } =
    trackingSlice.actions

// Reducer
export default trackingSlice.reducer
