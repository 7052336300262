import Button from '@components/button/button.component'
import Dropdown from '@components/dropdown/dropdown.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import Textarea from '@components/text-area/text-area.component'
import { useReceiptsDetails } from '../receipts-details.service'
import { useTranslation } from 'react-i18next'

const Message = () => {
    const { t } = useTranslation()
    const { fromPeople, updatePeople, removePerson } = useReceiptsDetails()
    return (
        <div className="grid grid-cols-4 gap-3 px-5 py-4">
            <div className="flex flex-col">
                <div className="flex flex-col gap-3 pt-2">
                    <Textarea
                        parentTextareaClassName="flex-grow flex flex-col"
                        value=""
                        onChange={() => {}}
                        label={t('additional.message').toUpperCase()}
                        placeholder={t('action.enter', {
                            value: t('additional.message'),
                        })}
                        className="w-full !flex-grow min-h-[150px]"
                        resize
                    />
                    <Button
                        onClick={() => {}}
                        label={t('action.send')}
                        className="w-full"
                        variant="brand-inverse"
                    />
                </div>
            </div>
            <div>
                <div className="flex flex-col gap-3 pt-2">
                    <Dropdown
                        options={[
                            { label: 'Jane Doe', value: 'Jane Doe' },
                            {
                                label: 'Alvianto Wiriyananda',
                                value: 'Alvianto Wiriyananda',
                            },
                            { label: 'Janice Molly', value: 'Janice Molly' },
                            { label: 'Vioni', value: 'Vioni' },
                        ]}
                        label={t('additional.add_follower')}
                        onClick={(selectedOption) => {
                            const _value = selectedOption as
                                | IDropdownItem
                                | undefined
                            if (_value) {
                                updatePeople(_value)
                            }
                        }}
                        placeholder={t('action.select', {
                            value: t('additional.add_follower'),
                        })}
                        isClearable={true}
                        isSearchable={true}
                    />
                </div>
                <div className="flex flex-col gap-2 pt-2">
                    {fromPeople.map((person) => (
                        <div
                            key={person.value}
                            className="flex justify-between items-center border rounded-md px-3"
                        >
                            <div className="text-[14px] themes-text-gray-v9 flex items-center">
                                <img
                                    alt="img-item"
                                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                    className="w-[20px] h-auto rounded-full mr-3"
                                />
                                {person.label}
                            </div>
                            <i
                                className="ri-delete-back-2-line  cursor-pointer themes-text-hover-red-v3"
                                onClick={() => removePerson(person)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Message
