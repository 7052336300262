import Button from '@components/button/button.component'
import Modal from '@components/modal/modal.component'
import { IUseCreateBooking } from '../create-booking.interface'

const ConfirmationCreateBookingModal = ({
    CBService,
}: {
    CBService: IUseCreateBooking
}) => {
    const borderComponent = <div className="border-b"></div>
    return (
        <Modal
            isModalOpen={CBService.confirmationModal.isModalOpen}
            className="!w-1/4  px-0"
        >
            <div className="flex flex-col gap-3">
                <div className="text-size-M font-bold px-3">
                    {CBService.confirmationModalProperty.title}
                </div>
                {borderComponent}
                <div className="px-3 flex flex-col gap-2 text-size-S">
                    <div>Are you sure to proceed with this action?</div>
                    <div className="font-bold">
                        {CBService.confirmationModalProperty.message}
                    </div>
                </div>
                {borderComponent}
                <div className="flex justify-between px-3">
                    <Button
                        onClick={() =>
                            CBService.confirmationModal.closeModalHandling()
                        }
                        label="cancel"
                        useUpperCase
                        variant="brand-v2-inverse"
                    />
                    <Button
                        onClick={() => {
                            CBService.confirmationModalProperty.btnProceed.onClick()
                            CBService.confirmationModal.closeModalHandling()
                        }}
                        label="proceed"
                        useUpperCase
                        variant={
                            CBService.confirmationModalProperty.btnProceed
                                .variant
                        }
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationCreateBookingModal
