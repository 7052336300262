import { IUseManageUserForm } from '../manage-user-form.interface'
import FormInput from '@components/form-input/form-input.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import LabelData from '@components/label-data/label-data.component'

const Details = ({
    useService,
    statusPage,
}: {
    useService: IUseManageUserForm
    statusPage: {
        isNew: boolean
        isProfilePage: boolean
    }
}) => {
    return (
        <div className="flex h-auto flex-col lg:h-full lg:flex-row">
            <div className="flex flex-col gap-3 p-3 w-[100%] lg:w-[25%] ">
                <div className="themes-text-gray-9 text-size-L">
                    Account Setup
                </div>

                <FormInput
                    useUppercaseLabel={true}
                    label={'EMAIL ADDRESS'}
                    placeholder={'Enter here'}
                    name={'email'}
                    required={true}
                    parentDivClassName="!mb-0"
                    disabled={statusPage.isProfilePage}
                />
                {statusPage.isNew && (
                    <>
                        <FormInput
                            useUppercaseLabel={true}
                            label={'password'}
                            placeholder={'Enter here'}
                            name={'password'}
                            type="password"
                            required
                            parentDivClassName="!mb-0"
                            client="IFB"
                        />
                        <FormInput
                            useUppercaseLabel={true}
                            label={'confirm password'}
                            placeholder={'Enter here'}
                            name={'confirmPassword'}
                            required
                            parentDivClassName="!mb-0"
                            type="password"
                            client="IFB"
                        />
                    </>
                )}
            </div>
            <div className="border-r flex flex-col gap-3 p-3 overflow-auto w-[100%] h-full lg:w-[25%]">
                <div className="themes-text-gray-9 text-size-L">
                    User Profile
                </div>
                <FormInput
                    useUppercaseLabel={true}
                    label={'FULL NAME'}
                    placeholder={'Enter here'}
                    name={'fullName'}
                    parentDivClassName="!mb-0"
                    required
                    disabled={statusPage.isProfilePage}
                />
                <FormInput
                    required
                    useUppercaseLabel={true}
                    label={'JOB TITLE'}
                    placeholder={'Enter here'}
                    name={'jobTitle'}
                    parentDivClassName="!mb-0"
                    disabled={statusPage.isProfilePage}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'PHONE NUMBER'}
                    placeholder={'Enter here'}
                    name={'phoneNumber'}
                    parentDivClassName="!mb-0"
                    type="number"
                    disabled={statusPage.isProfilePage}
                    useArrow={false}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'MOBILE NUMBER'}
                    placeholder={'Enter here'}
                    name={'mobilePhoneNumber'}
                    parentDivClassName="!mb-0"
                    type="number"
                    disabled={statusPage.isProfilePage}
                    useArrow={false}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'ADDRESS 1'}
                    placeholder={'Enter here'}
                    name={'addressDetails'}
                    parentDivClassName="!mb-0"
                    disabled={statusPage.isProfilePage}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'ADDRESS 2'}
                    placeholder={'Enter here'}
                    name={'address2'}
                    parentDivClassName="!mb-0"
                    disabled={statusPage.isProfilePage}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'CITY'}
                    placeholder={'Enter here'}
                    name={'city'}
                    parentDivClassName="!mb-0"
                    disabled={statusPage.isProfilePage}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'state'}
                    placeholder={'Enter here'}
                    name={'province'}
                    parentDivClassName="!mb-0"
                    disabled={statusPage.isProfilePage}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'POSTCODE'}
                    placeholder={'Enter here'}
                    name={'postCode'}
                    parentDivClassName="!mb-0"
                    type="number"
                    disabled={statusPage.isProfilePage}
                    useArrow={false}
                />
                <FormDropdown
                    isSearchable
                    useUppercaseLabel={true}
                    label={'COUNTRY'}
                    placeholder={'Select here'}
                    name={'country'}
                    parentDivClassName="!mb-0"
                    options={useService.countryList}
                    disabled={statusPage.isProfilePage}
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'DEFAULT CURRENCY'}
                    placeholder={'Select here'}
                    name={'defaultCurrency'}
                    parentDivClassName="!mb-0"
                    disabled
                />
                <FormInput
                    useUppercaseLabel={true}
                    label={'CURRENCY DECIMAL POINT'}
                    placeholder={'Enter here'}
                    name={'currencyDecimalPoint'}
                    parentDivClassName="!mb-0"
                    useDecimalFormat
                    disabled
                />
            </div>
            <div className="w-[100%] lg:w-[50%]">
                <div className="border-b p-3 themes-text-gray-9 text-size-L ">
                    Data Branch Access
                </div>
                <div className="flex flex-col gap-3 p-3">
                    {/* <FormDropdown
                        useUppercaseLabel={true}
                        label={'BRANCH'}
                        placeholder={'Select here'}
                        name={'defaultCurrency'}
                        parentDivClassName="!mb-0"
                        options={[]}
                        disabled={statusPage.isProfilePage}
                    /> */}
                    <LabelData
                        label="BRANCH 1"
                        data={useService.userDefault.data?.branch}
                        containerClass="px-2.5 py-2 themes-bg-brand-v1 rounded"
                        headerClass="themes-text-brand-v3 text-xs"
                        dataClass="text-sm"
                    />
                </div>
            </div>
        </div>
    )
}

export default Details
