import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { IMoreOption } from '@components/table-expandable-detail/table-expandable-detail.interface'
import { IDetailTracking } from './trackings.interface'

export const TrackingSummaryDetailHeader: ITableColumn<IDetailTracking>[] = [
    {
        label: 'Container Type',
        accessor: 'containerType',
    },
    {
        label: 'Container Mode',
        accessor: 'containerMode',
        customBuild: (data, rowData) => {
            return (
                <>
                    {rowData?.containerMode} - {rowData?.containerID}
                </>
            )
        },
    },
    {
        label: 'Delivery Mode',
        accessor: 'deliveryMode',
    },
    {
        label: 'Consol Number',
        accessor: 'consoleNo',
    },
    {
        label: 'Seal Number',
        accessor: 'sealNo',
    },
    {
        label: 'Vessel',
        labelTranslation: 'tracking.vessel',
        accessor: 'vessel',
    },
    {
        label: 'Voyage',
        accessor: 'voyage',
    },
    {
        label: 'Related Shipment',
        accessor: 'emptyReturnedOn',
    },
]

export const TabTrackings: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    {
        label: 'eDocs',
        labelTranslationCode: 'tab_details.edocs',
        key: 'status',
        value: 'eDocs',
        className: 'brand',
    },
    {
        label: 'Notes',
        labelTranslationCode: 'tab_details.notes',
        key: 'status',
        value: 'notes',
        className: 'brand',
    },
    {
        label: 'Milestones',
        labelTranslationCode: 'tab_details.milestones',
        key: 'status',
        value: 'milestones',
        className: 'brand',
    },
    {
        label: 'Events',
        labelTranslationCode: 'tab_details.events',
        key: 'status',
        value: 'events',
        className: 'brand',
    },
]

export const trackingMoreOptions: IMoreOption[] = [
    {
        label: 'View Trackings Details',
        value: 'details',
    },
    {
        label: 'eDocs',
        value: 'eDocs',
    },
    {
        label: 'Notes',
        value: 'notes',
    },
    {
        label: 'Delete',
        value: 'delete',
    },
    {
        label: 'Documents',
        value: 'documents',
    },
    {
        label: 'Workflow',
        value: 'workflow',
    },
]
