import Tab from '@components/tab/tab.component'

import Button from '@components/button/button.component'
import { useNavigate } from 'react-router-dom'
import { useProductsDetails } from './products-details.service'
import { TabProduct } from './products-details.static'

const ProductsDetails = () => {
    const navigate = useNavigate()

    const { tabFilter, setTabFilter, getContentProductsDetails } =
        useProductsDetails()

    return (
        <div className="content-full-height w-full rounded-lg themes-border-color bg-white  border-2 py-2 flex flex-col justify-between">
            <div className="flex flex-col">
                <Tab
                    containerClassName="px-3 overflow-auto border-b"
                    items={TabProduct ?? []}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                />
                {getContentProductsDetails(tabFilter)}
            </div>

            <div className="px-3 pt-2 border-t">
                <Button
                    onClick={() => {
                        navigate('/products')
                    }}
                    label="BACK"
                    className="w-[10rem] ml-0"
                    variant="brand-v2-inverse"
                />
            </div>
        </div>
    )
}

export default ProductsDetails
