import axios from 'axios'
import { removeSession, userDataSelector } from 'pages/login/login.slice'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { IBuildVersion, IConfig } from './config.interface'
import { useEffect, useRef, useState } from 'react'
import {
    currentThemeSelector,
    setClientVersion,
    setConfig,
    setTheme,
} from './config.slice'
import { getClient } from 'common/common.service'
import { resetStore, store } from 'store'
import { ThemeType } from 'common/common.interface'

export const useConfig = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const user = useSelector(userDataSelector)
    const currentTheme: ThemeType = useSelector(currentThemeSelector)
    const [sessionExpDate, setSessionExpDate] = useState<Date | null>(null)
    const [triggerEvent, setTriggerEvent] = useState<boolean>(false)
    const client: string = getClient()
    const clientException = ['ifb']

    // ignore session check in this route
    const [ignoredRoutes] = useState<string[]>(['/login', '/change-password'])

    // check session function
    const checkSession = (sess?: Date) => {
        if (clientException.includes(client)) return
        if (!sess) return

        const now = new Date()
        if (sess < now && client !== 'ifb') {
            removeSession()
            setSessionExpDate(null)
        }
    }

    const checkSessionRef = useRef(() => {
        checkSession()
    })

    // Update checkSessionRef.current whenever sessionExpDate changes
    useEffect(() => {
        const cSessionRef = checkSessionRef.current
        if (!sessionExpDate) {
            window.removeEventListener('mousemove', cSessionRef)
            return
        }
        checkSessionRef.current = () => {
            checkSession(sessionExpDate)
        }
        setTimeout(() => {
            setTriggerEvent(!triggerEvent)
        }, 400)
    }, [sessionExpDate])

    useEffect(() => {
        const checkSession = checkSessionRef.current
        window.addEventListener('mousemove', checkSession)
    }, [triggerEvent])

    // axios -------------------------------------------------------------------
    if (user) {
        axios.defaults.paramsSerializer = { indexes: null }
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
        axios.defaults.headers.common.Accept = 'application/json'
        axios.defaults.headers.common.Authorization = `Bearer ${user.token}`
        axios.defaults.timeout = 500000
    }

    // use Effect ---------------------------------------------------------------
    useEffect(() => {
        starting()
    }, [])

    useEffect(() => {
        if (user.token === '') starting()
    }, [user])

    useEffect(() => {
        const theme = currentTheme ?? 'default'
        const body = document.body
        body.removeAttribute('class')
        body.classList.add(theme)
    }, [currentTheme])

    useEffect(() => {
        // handling session
        if (
            !user?.token ||
            (!user?.token && !clientException.includes(client))
        ) {
            if (!ignoredRoutes.includes(location.pathname)) {
                navigate('/login', {
                    replace: true,
                })

                // reset store
                resetStore()
            }
        } else {
            if (clientException.includes(client)) return
            const token = user.token
            const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))

            //------------
            // if session problem fix, delete this
            // var currentTimestamp = Date.now()
            // var futureTimestamp = currentTimestamp + 20 * 1000
            // var futureDate = new Date(futureTimestamp)
            //------------

            // Replace timestamp_variable with your timestamp
            const timestamp: number = jwtPayload.exp
            // Convert timestamp to milliseconds
            var sessionDate = new Date(timestamp * 1000)
            // Get current time
            var now = new Date()
            // Compare timestamps
            if (sessionDate < now) {
                removeSession()
                setSessionExpDate(null)
            }

            // trigger event listener
            setSessionExpDate(sessionDate)
        }
    }, [user])

    // function ---------------------------------------------------------------
    const starting = async () => {
        try {
            // get from selector
            axios.get('/config.json').then((data) => {
                const conf = data.data
                const cf: IConfig = {
                    ...conf,
                    client: conf.client.toLowerCase(),
                }
                dispatch(setConfig(cf))

                // Set Theme Color used
                if (!currentTheme) {
                    // Default Settings
                    const client: string = (
                        data.data?.client ?? 'undefined-client'
                    ).toLowerCase()
                    let themeUsed: ThemeType
                    if (client === 'ifb') {
                        themeUsed = 'dark-sapphire-blue-themes'
                    } else {
                        themeUsed = 'bright-sky-blue-themes'
                    }
                    dispatch(setTheme(themeUsed))
                } else {
                    // temporary
                    if (currentTheme === 'ifb-themes') {
                        const themeUsed = 'dark-sapphire-blue-themes'
                        dispatch(setTheme(themeUsed))
                    } else if (currentTheme === 'loglines-themes') {
                        const themeUsed = 'bright-sky-blue-themes'
                        dispatch(setTheme(themeUsed))
                    } else {
                        const themeUsed = 'dark-sapphire-blue-themes'
                        dispatch(setTheme(themeUsed))
                    }
                }
            })

            // get build version
            axios.get<IBuildVersion>('/build-version.json').then((data) => {
                const buildVersion = data.data
                const clientVersion = buildVersion.clientVersion
                const lastBuild = buildVersion.lastBuild
                console.info(
                    'Loglines' +
                        '\nClient Version: ' +
                        clientVersion +
                        '\nLast Build: ' +
                        lastBuild,
                )
                const nbv: IBuildVersion = {
                    ...buildVersion,
                    client: buildVersion.client.toLowerCase(),
                }
                dispatch(setClientVersion(nbv))
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export function getConfig(): IConfig {
    const state = store.getState()
    return state.config.config
}
