/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { tabDetailHeaders } from './booking-details.static'
import { IBookingDetails } from './booking-details.interface'
import { useNavigate, useParams } from 'react-router-dom'
import { bookingDetails } from './booking-details.dummy'
import { getBookingsID } from 'repository/booking.repository'
import { useDispatch, useSelector } from 'react-redux'
import {
    bookingsDetailsSelector,
    setBookingsDataDetail,
} from '../bookings.slice'

const useBookingDetails = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    // states
    const [detailsData] = useState<IBookingDetails>(bookingDetails)
    const [tabFilter, setTabFilter] = useState<ITabItem>(tabDetailHeaders[0])
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    // selectors
    const bookingsDataDetail = useSelector(bookingsDetailsSelector)

    // functions
    // Get Data Function
    const loadData = async () => {
        try {
            if (id) {
                setLoading(true)
                const actionResultDetail = await getBookingsID(id)
                dispatch(setBookingsDataDetail(actionResultDetail))
                setLoading(false)
            }
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // useEffect
    useEffect(() => {
        loadData()
    }, [])

    return {
        loading,
        errorMessage,
        detailsData,
        tabFilter,
        bookingsDataDetail,
        id,
        navigate,
        setTabFilter,
    }
}

export default useBookingDetails
