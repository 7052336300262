var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from 'formik';
import DatePicker from '@components/date-picker/date-picker.component';
function FormDatePicker(_a) {
    var _b = _a.parentDivClassName, parentDivClassName = _b === void 0 ? '' : _b, _c = _a.showErrorMessage, showErrorMessage = _c === void 0 ? true : _c, props = __rest(_a, ["parentDivClassName", "showErrorMessage"]);
    //formik
    var _d = useField(props.name), field = _d[0], meta = _d[1], helpers = _d[2];
    var borderColorClass = meta.error && meta.touched ? 'themes-border-red-v3-important' : '';
    var isError = !(!meta.error || (meta.error && !meta.touched));
    var errorClassMessage = isError ? '' : 'hidden';
    return (_jsxs("div", __assign({ className: "w-full ".concat(parentDivClassName) }, { children: [_jsx(DatePicker, __assign({ selectedDate: field.value, selectedRange: field.value, setSelectedDate: function (value) {
                    if (!props.isRange) {
                        helpers.setValue(value);
                    }
                }, setSelectedRange: function (value) {
                    if (props.isRange) {
                        helpers.setValue(value);
                    }
                }, containerInputClassName: borderColorClass }, props)), _jsx("div", __assign({ className: "ml-small-x text-size-XS text-red-500 ".concat(!showErrorMessage ? 'hidden' : errorClassMessage) }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) }))] })));
}
export default FormDatePicker;
