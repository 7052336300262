import Tab from '@components/tab/tab.component'

import Button from '@components/button/button.component'
import { useNavigate } from 'react-router-dom'
import { useReceiptsDetails } from './receipts-details.service'
import { TabReceipts } from './receipts-details.static'
import { useTranslation } from 'react-i18next'

const ReceiptsDetails = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { tabFilter, setTabFilter, getContentReceiptsDetails } =
        useReceiptsDetails()

    return (
        <div className="content-full-height w-full rounded-lg themes-border-color bg-white  border-2 py-2 flex flex-col justify-between">
            <div className="flex flex-col">
                <Tab
                    containerClassName="px-3 overflow-auto border-b"
                    items={TabReceipts ?? []}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                />
                {getContentReceiptsDetails(tabFilter)}
            </div>

            <div className="px-2 pt-2 border-t">
                <Button
                    onClick={() => {
                        navigate('/Receipts')
                    }}
                    label={t('action.back')}
                    className="w-[10rem] ml-0"
                    variant="brand-v2-inverse"
                    useUpperCase
                />
            </div>
        </div>
    )
}

export default ReceiptsDetails
