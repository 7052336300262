import { IMeta } from 'common/common.interface'
import {
    IAverageMonthlyPerformance,
    ICOFilterByMetrics,
    IDataOverviewTable1,
    ISmallTableData,
    IFilterDropdownOptions,
    IFinanceProfitAndLossTableData,
    IHistoryData,
    IMarkShipmentCustomersAndSales,
    ITradeLanesData,
    IMediumTableData,
    IMediumTable,
    IFinanceData,
    ISmallTable,
    ICreditLimitTable,
} from './control-tower.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {
    IBarchartStacktedDataItem,
    ILineData,
} from '@components/rechart-component/rechart-interface'
import { faker } from '@faker-js/faker'
import {
    financeOpenInvoiceInitialValue,
    financeProfitAndLostTableInitialData,
    historySplitRecordChartDataIntialValue,
    historySplitRecordSummaryIntialvalue,
    mediumTableprogressMultipleBarKey,
    multipleBarsMediumTableDataIntialValue,
    regionPieChartData,
    tradeLanesCarrierInitialValue,
    tradeLanesRegionInitialValue,
} from './control-tower.static'
import { IPieChartData } from '@components/rechart-component/pie/doughnut-chart.component'
import {
    IRSmallTable,
    IRelatedKeyFiguresItemsChangeResponse,
    IRelatedKeyFiguresItemsResponse,
    IRelatedKeyFiguresResponse,
    IRTradeLanesRegion,
    IRTradeLanesCarrier,
    IRFinanceOpenInvoices,
} from 'repository/interface/control-tower.interface'
import { createMetaData, numberToCurrency } from 'common/common.service'
import { initialMeta } from 'common/common.static'
import { InfoCardGroupItems } from '@components/InfoCardGroup/Info-card-group.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IFilterDataCategoryCO } from 'form-validation/control-tower.validation'
import { IProgressMultipleBarsData } from '@components/progress-multiple-bars/progress-multiple-bars.interface'
import { formatDecimalNumber, numberWithCommas } from '@services/common.service'

export interface IControlTowerSlice {
    // common
    meta: IMeta
    historyData: IHistoryData
    filterDropdownOptions: IFilterDropdownOptions
    mediumTable: IMediumTable
    smallTable: ISmallTable

    // segment overview
    dataOverviewTable1: IDataOverviewTable1[]

    // segment trade lanes
    tradeLanesData: ITradeLanesData<{ formatNumberValue: string }>

    // segment finance
    financeData: IFinanceData
    financePieChart: IPieChartData[]
    averageMonthlyPerformance: IAverageMonthlyPerformance
}

export const initialState: IControlTowerSlice = {
    // segment common
    meta: initialMeta,
    historyData: {
        development: {
            chartData: {
                key: [
                    {
                        id: 'line1',
                        color: '#8D99A5',
                        label: 'This Period',
                    },
                    {
                        id: 'line2',
                        color: '#D9DDE1',
                        label: 'Last Period',
                    },
                ],
                line1: [],
                line2: [],
            },
            tickItems: [],
            infoCardItems: [
                {
                    value: '0',
                    label: 'Average Shipment',
                },
            ],
        },
        splitRecord: {
            chartData: {
                key: [
                    {
                        id: 'f1',
                        color: '#8D99A5',
                    },
                    {
                        id: 'f2',
                        color: '#D9DDE1',
                    },
                ],
                data: historySplitRecordChartDataIntialValue,
            },
            infoCardItems: historySplitRecordSummaryIntialvalue,
        },
    },
    filterDropdownOptions: {
        company: [{ label: 'All Company', value: 'all' }],
        transportMode: [{ label: 'All Modes', value: 'all' }],
        containerMode: [{ label: 'All Modes', value: 'all' }],
    },
    mediumTable: {
        table: {
            data: [],
            meta: initialMeta,
            filterPageNumber: 1,
        },
        infoCardItems: [],
        multipleBarItems: {
            data: multipleBarsMediumTableDataIntialValue,
            keyItems: mediumTableprogressMultipleBarKey,
        },
    },
    smallTable: {
        data: [],
        meta: initialMeta,
        filterPageNumber: 0,
    },

    // segment overview
    dataOverviewTable1: [],

    // segment trade lanes
    tradeLanesData: {
        region: tradeLanesRegionInitialValue,
        carrier: tradeLanesCarrierInitialValue,
    },

    // segment finance
    financeData: {
        openInvoices: financeOpenInvoiceInitialValue,
        table: {
            creditLimit: [],
            meta: initialMeta,
            filterPageNumber: 0,
            profitandLoss: financeProfitAndLostTableInitialData,
        },
        avgMonthlyPerfomance: {
            chartData: {
                key: [
                    {
                        id: 'line1',
                        color: '#5280C6',
                        label: 'Avg. GP',
                    },
                    {
                        id: 'line2',
                        color: '#C98167',
                        label: 'Avg. OPEX',
                    },
                    {
                        id: 'line3',
                        color: '#76C6CC',
                        label: 'Avg. NP',
                    },
                ],
                line1: [],
                line2: [],
                line3: [],
            },
            tickItems: [],
            infoCardItems: [
                {
                    value: 'AUD 0.00',
                    label: 'Overall Average Revenue',
                },
            ],
        },
    },

    financePieChart: [
        {
            name: 'Within payment term',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#C0C7CD',
            textColor: '#FFFFFF',
        },
        {
            name: '1 week overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#5B6C7C',
            textColor: '#FFFFFF',
        },
        {
            name: '2 weeks overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#FFE3A4',
            textColor: '#FFFFFF',
        },
        {
            name: '1 month overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#E3CD9A',
            textColor: '#FFFFFF',
        },
        {
            name: '3 months overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#F691A9',
            textColor: '#FFFFFF',
        },
        {
            name: '6 months overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#C9677F',
            textColor: '#FFFFFF',
        },
    ],
    averageMonthlyPerformance: {
        chartData: {
            key: [
                {
                    id: 'line1',
                    color: '#5280C6',
                    label: 'Avg. GP',
                },
                {
                    id: 'line2',
                    color: '#C98167',
                    label: 'Avg. OPEX',
                },
                {
                    id: 'line3',
                    color: '#76C6CC',
                    label: 'Avg. NP',
                },
            ],
            line1: [],
            line2: [],
            line3: [],
        },
        tickItems: [],
    },
}

const controlTowerSlice = createSlice({
    name: 'ControlTowerIFB',
    initialState,
    reducers: {
        // segment common
        setHistorySplitRecord(
            state,
            action: PayloadAction<{
                chartData: IBarchartStacktedDataItem[]
                infoCardGroupItems: InfoCardGroupItems[]
            }>,
        ) {
            const payloadData = action.payload

            state.historyData.splitRecord.chartData.data = payloadData.chartData
            state.historyData.splitRecord.infoCardItems =
                payloadData.infoCardGroupItems
        },
        setHistoryDevelopment(
            state,
            action: PayloadAction<{
                line1: ILineData[]
                line2: ILineData[]
                tickItems: string[]
                infoCardItems: InfoCardGroupItems[]
            }>,
        ) {
            const { line1, line2, tickItems, infoCardItems } = action.payload
            state.historyData.development.chartData.line1 = line1
            state.historyData.development.chartData.line2 = line2
            state.historyData.development.tickItems = tickItems
            state.historyData.development.infoCardItems = infoCardItems
        },
        setFilterDropdownOptions: (
            state,
            action: PayloadAction<IFilterDropdownOptions>,
        ) => {
            state.filterDropdownOptions = action.payload
        },
        setLoadOptionsFilterDropdown(
            state,
            action: PayloadAction<{
                options: IDropdownItem[]
                loadType: string
            }>,
        ) {
            const { options, loadType } = action.payload

            switch (loadType) {
                case 'company':
                    state.filterDropdownOptions.company = options
                    break
            }
        },
        setSmallTableData(
            state,
            action: PayloadAction<{
                data: ISmallTableData[]
                meta: IMeta
            }>,
        ) {
            const { data, meta } = action.payload

            state.smallTable.data = data
            state.smallTable.meta = meta
        },
        setPageNumberSmallTable2Slice(state, action: PayloadAction<number>) {
            state.smallTable.filterPageNumber = action.payload
        },
        setMediumTableData(
            state,
            action: PayloadAction<{
                data: IMediumTableData[]
                meta: IMeta
            }>,
        ) {
            const { data, meta } = action.payload

            state.mediumTable.table.data = data
            state.mediumTable.table.meta = meta
        },
        setPageNumberMediumTable2Slice(state, action: PayloadAction<number>) {
            state.mediumTable.table.filterPageNumber = action.payload
        },
        setMediumTableCardItems(
            state,
            action: PayloadAction<InfoCardGroupItems[]>,
        ) {
            state.mediumTable.infoCardItems = action.payload
        },
        setMediumTableMultipleBarsItemsData(
            state,
            action: PayloadAction<
                IProgressMultipleBarsData<IMarkShipmentCustomersAndSales>
            >,
        ) {
            state.mediumTable.multipleBarItems.data = action.payload
        },

        // segment overview
        setDataOverviewTable1(
            state,
            action: PayloadAction<IDataOverviewTable1[]>,
        ) {
            state.dataOverviewTable1 = action.payload
        },

        // segment trade lanes
        setTradeLanesCarrierData: (
            state,
            action: PayloadAction<{
                data: IRTradeLanesCarrier | null
                filter: IFilterDataCategoryCO
            }>,
        ) => {
            const { data, filter } = action.payload

            if (!data) {
                state.tradeLanesData.carrier = tradeLanesCarrierInitialValue
                return
            }

            const handleFormatValue = (value: number) => {
                return filter.Shipments
                    ? numberWithCommas(value.toString() || '0')
                    : formatDecimalNumber(Number(value.toFixed()) || 0)
            }

            const activeFilter = Object.entries(filter).find(
                ([key, value]) => value === true,
            )

            if (!activeFilter) {
                throw new Error('No active filter found')
            }

            const [key] = activeFilter

            const fieldName: Record<
                string,
                { chart: string; infoCard: string }
            > = {
                Shipments: {
                    chart: 'totalShipmentCurrentPeriod',
                    infoCard: 'overallShipment',
                },
                Revenue: {
                    chart: 'totalRevenueCurrentPeriod',
                    infoCard: 'overallRevenue',
                },
                Volume: {
                    chart: 'totalVolumeCurrentPeriod',
                    infoCard: 'overallVolume',
                },
                GrossProfit: {
                    chart: '',
                    infoCard: '',
                },
            }

            const getFieldName = fieldName[key]

            const mapPieChartData: IPieChartData<{
                formatNumberValue: string
            }>[] = []

            data.queryResults.forEach((item, idx) => {
                if (idx === 8) return
                const findDataByIdx = regionPieChartData[idx]
                const value = item[getFieldName.chart as keyof typeof item]

                mapPieChartData.push({
                    name: item.id,
                    value: value ? Number(value) : 0,
                    fill: findDataByIdx.fill,
                    textColor: findDataByIdx.textColor,
                    additionalValue: {
                        formatNumberValue: handleFormatValue(
                            value ? Number(value) : 0,
                        ),
                    },
                })
            })

            const overalValue = data[getFieldName.infoCard as keyof typeof data]

            state.tradeLanesData.carrier.pieChart = mapPieChartData
            state.tradeLanesData.carrier.infoCardItems[0].value =
                handleFormatValue(Number(overalValue))
        },
        setTradeLanesRegionData: (
            state,
            action: PayloadAction<{
                data: IRTradeLanesRegion | null
                filter: IFilterDataCategoryCO
            }>,
        ) => {
            const { data, filter } = action.payload

            if (!data) {
                state.tradeLanesData.region = tradeLanesRegionInitialValue
                return
            }

            const handleFormatValue = (value: number) => {
                return filter.Shipments
                    ? numberWithCommas(value.toString() || '0')
                    : formatDecimalNumber(Number(value.toFixed()) || 0)
            }

            const activeFilter = Object.entries(filter).find(
                ([key, value]) => value === true,
            )

            if (!activeFilter) {
                throw new Error('No active filter found')
            }

            const [key] = activeFilter

            const fieldName: Record<string, string> = {
                Shipments: 'totalShipmentCurrent',
                Revenue: 'totalRevenueCurrent',
                Volume: 'totalVolumeCurrent',
                GrossProfit: 'totalGPCurrent',
            }

            const getFieldName = fieldName[key]

            const mapPieChartData: IPieChartData<{
                formatNumberValue: string
            }>[] = []

            data?.region.forEach((item, idx) => {
                if (idx === 7) return
                const findDataByIdx = regionPieChartData[idx]
                const value = item[getFieldName as keyof typeof item]

                mapPieChartData.push({
                    name: item.regionName.toLocaleUpperCase(),
                    value: value ? Number(value) : 0,
                    fill: findDataByIdx.fill,
                    textColor: findDataByIdx.textColor,
                    additionalValue: {
                        formatNumberValue: handleFormatValue(
                            value ? Number(value) : 0,
                        ),
                    },
                })
            })

            const othersData = regionPieChartData[7]
            const othersValue =
                data.others[getFieldName as keyof typeof data.others]

            mapPieChartData.push({
                ...othersData,
                value: othersValue ? Number(othersValue) : 0,
                additionalValue: {
                    formatNumberValue: handleFormatValue(
                        othersValue ? Number(othersValue) : 0,
                    ),
                },
            })

            state.tradeLanesData.region.pieChart = mapPieChartData
            state.tradeLanesData.region.infoCardItems[0].value =
                numberWithCommas(data?.summary?.overallShipments.toString())
            state.tradeLanesData.region.infoCardItems[1].value =
                numberWithCommas(data?.summary?.overallRegions.toString())
        },

        // segment finance
        setAverageMonthlyPerformance(
            state,
            action: PayloadAction<{
                line1: ILineData[]
                line2: ILineData[]
                line3: ILineData[]
                tickItems: string[]
                summaryData: InfoCardGroupItems[]
            }>,
        ) {
            const { line1, line2, line3, tickItems, summaryData } =
                action.payload
            state.financeData.avgMonthlyPerfomance.chartData.line1 = line1
            state.financeData.avgMonthlyPerfomance.chartData.line2 = line2
            state.financeData.avgMonthlyPerfomance.chartData.line3 = line3
            state.financeData.avgMonthlyPerfomance.tickItems = tickItems
            state.financeData.avgMonthlyPerfomance.infoCardItems = summaryData
        },
        setOpenInvoceData(
            state,
            action: PayloadAction<IRFinanceOpenInvoices | null>,
        ) {
            const payloadData = action.payload

            if (!payloadData) {
                state.financeData.openInvoices = financeOpenInvoiceInitialValue
                return
            }

            // segment info card
            state.financeData.openInvoices.infoCardItems[0].value =
                payloadData?.shipmentOverall.toString() || '0'
            state.financeData.openInvoices.infoCardItems[1].value =
                payloadData?.amountOverall.toString() || '0'

            // segment pie chart
            state.financeData.openInvoices.pieChart[0].value =
                payloadData.items['Within Term'].amount
            state.financeData.openInvoices.pieChart[1].value =
                payloadData.items['1 week'].amount
            state.financeData.openInvoices.pieChart[2].value =
                payloadData.items['2 week'].amount
            state.financeData.openInvoices.pieChart[3].value =
                payloadData.items['1 month'].amount
            state.financeData.openInvoices.pieChart[4].value =
                payloadData.items['3 month'].amount
            state.financeData.openInvoices.pieChart[5].value =
                payloadData.items['6 month'].amount
        },
        setFinaceProfitAndLossTable(
            state,
            action: PayloadAction<{
                profitAndLoss: IFinanceProfitAndLossTableData[]
                meta: IMeta
            }>,
        ) {
            const { profitAndLoss, meta } = action.payload
            state.financeData.table.profitandLoss = profitAndLoss
            state.financeData.table.meta = meta
        },
        setFinaceCreditLimitTable(
            state,
            action: PayloadAction<{
                creditLimit: ICreditLimitTable[]
                meta: IMeta
            }>,
        ) {
            const { creditLimit, meta } = action.payload
            state.financeData.table.creditLimit = creditLimit
            state.financeData.table.meta = meta
        },
        setPageNumberFinanceTableSlice(state, action: PayloadAction<number>) {
            state.financeData.table.filterPageNumber = action.payload
        },
    },
})

// these all the variables used for selector\
// segment common
export const historyDataSelector = (state: RootState) =>
    state.controlTowerIFB.historyData || {}
export const filterDropdownOptionsSelector = (state: RootState) =>
    state.controlTowerIFB.filterDropdownOptions
export const mediumTableSelector = (state: RootState) =>
    state.controlTowerIFB.mediumTable
export const smallTableSelector = (state: RootState) =>
    state.controlTowerIFB.smallTable

// segment overview
export const dataOverviewTable1Selector = (state: RootState) =>
    state.controlTowerIFB.dataOverviewTable1 || {}

// segment trade lanes
export const TradeLanesDataSelector = (state: RootState) =>
    state.controlTowerIFB.tradeLanesData || {}

// segment finance
export const financePieChartSelector = (state: RootState) =>
    state.controlTowerIFB.financePieChart || {}
export const averageMonthlyPerformanceSelector = (state: RootState) =>
    state.controlTowerIFB.averageMonthlyPerformance || {}
export const financeDataSelector = (state: RootState) =>
    state.controlTowerIFB.financeData || {}

// all actions
export const {
    setAverageMonthlyPerformance,
    setDataOverviewTable1,
    setSmallTableData,
    setPageNumberSmallTable2Slice,
    setHistoryDevelopment,
    setFilterDropdownOptions,
    setTradeLanesCarrierData,
    setTradeLanesRegionData,
    setHistorySplitRecord,
    setMediumTableData,
    setPageNumberMediumTable2Slice,
    setMediumTableCardItems,
    setMediumTableMultipleBarsItemsData,
    setLoadOptionsFilterDropdown,
    setOpenInvoceData,
    setFinaceProfitAndLossTable,
    setFinaceCreditLimitTable,
    setPageNumberFinanceTableSlice,
} = controlTowerSlice.actions

// Reducer
export default controlTowerSlice.reducer
