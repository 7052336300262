import TabMaster from '@components/tab-master/tab-master.component'
import useTrackings from './trackings.service'
import GoogleMap from '@components/google-map/google-map.component'
import { useTranslation } from 'react-i18next'
import TrackingTableExpandableCustom from 'common/components/tracking-table-expandable-custom.component'

const Trackings = () => {
    const { t } = useTranslation()
    const {
        tabItems,
        tabFilter,
        trackingsData,
        loading,
        setTabFilter,
        setPageData,
        handleSearch,
        trackingsDataMeta,
        widthSize,
    } = useTrackings()

    return (
        <div className="content-full-height grid grid-cols-12 gap-4">
            <div
                className={`${widthSize > 1279 && widthSize < 1441 ? 'lg:col-span-7' : 'lg:col-span-6'} md:col-span-12 sm:col-span-12 flex flex-col container-global`}
                style={{ height: 'inherit' }}
            >
                <TabMaster
                    items={tabItems || []}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('tracking.container_number'),
                    })}
                    onSearchSubmit={handleSearch}
                />
                <TrackingTableExpandableCustom
                    data={trackingsData}
                    meta={trackingsDataMeta}
                    setPageData={setPageData}
                    loading={loading}
                    disableErrorIllustration
                />
            </div>
            <div
                className={`${widthSize > 1279 && widthSize < 1440 ? 'lg:col-span-5' : 'lg:col-span-6'}  md:col-span-12 sm:hidden themes-bg-white rounded-[10px] p-[5px] w-full sm:h-[500px] md:h-full shadow-md`}
            >
                <GoogleMap />
            </div>
        </div>
    )
}

export default Trackings
