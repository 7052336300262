import Button from '@components/button/button.component'
import Dropdown from '@components/dropdown/dropdown.component'
import Textarea from '@components/text-area/text-area.component'
import { useTranslation } from 'react-i18next'

const Overview = () => {
    const { t } = useTranslation()
    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] overflow-hidden p-4 gap-4">
                <div className="flex flex-col h-1/2 w-2/5 md:3/5 sm:w-4/5 p-4 border-2 rounded-[10px] gap-3 overflow-auto">
                    <div className="flex-col">
                        <div>
                            <span className="text-size-XL font-bold">
                                {t('overview.title1')}
                            </span>
                        </div>
                        <div>
                            <p className="text-size-M font-normal text-justify">
                                {t('overview.message1')}
                            </p>
                        </div>
                    </div>
                    <div className="flex-col">
                        <div>
                            <span className="text-size-XL font-bold">
                                {t('overview.title2')}
                            </span>
                        </div>
                        <div>
                            <p className="text-size-M font-normal text-justify">
                                {t('overview.message2')}
                            </p>
                        </div>
                    </div>
                    <div className="flex-col">
                        <div>
                            <span className="text-size-XL font-bold">
                                {t('overview.title3')}
                            </span>
                        </div>
                        <div>
                            <p className="text-size-M font-normal text-justify">
                                {t('overview.message3')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col h-1/2 w-2/5 md:3/5 sm:w-4/5 p-4 border-2 rounded-[10px] gap-2 overflow-auto">
                    <div className="flex w-full">
                        <span className="text-size-XL font-bold">
                            {t('overview.title4')}
                        </span>
                    </div>
                    <div className="flex w-full">
                        <Textarea
                            label={t('overview.requirements')}
                            placeholder={t('action.enter', {
                                value: t('overview.requirements'),
                            })}
                            className="!h-[200px] resize-none"
                        />
                    </div>
                    <div className="flex w-full">
                        <Dropdown
                            label={t('overview.country')}
                            placeholder={t('action.select', {
                                value: t('overview.country'),
                            })}
                            options={[
                                {
                                    label: 'United States',
                                    value: 'US',
                                },
                                {
                                    label: 'Singapore',
                                    value: 'SG',
                                },
                                {
                                    label: 'Indonesia',
                                    value: 'ID',
                                },
                            ]}
                        />
                    </div>
                    <div>
                        <Button
                            label={t('action.submit').toUpperCase()}
                            variant="brand-v2-inverse"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview
