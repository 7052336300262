var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SpinnerTable from '@components/spinner-table/spinner-table.component';
import useSplitTable from './split-table.service';
import EmptyResult from '@components/empty-result/empty-result.component';
import { Fragment } from 'react/jsx-runtime';
var SplitTable = function (_a) {
    var 
    // property css
    _b = _a.tdClass, 
    // property css
    tdClass = _b === void 0 ? '' : _b, _c = _a.thClass, thClass = _c === void 0 ? '' : _c, _d = _a.containerTableClass, containerTableClass = _d === void 0 ? '' : _d, _e = _a.loading, loading = _e === void 0 ? false : _e, props = __rest(_a, ["tdClass", "thClass", "containerTableClass", "loading"]);
    var _f = useSplitTable({
        data: props.data,
        loading: loading,
        columns: props.columns,
        useSubData: props.useSubData,
    }), tableRefs = _f.tableRefs, hoveredRowIndex = _f.hoveredRowIndex, setHoveredRowIndex = _f.setHoveredRowIndex, clickRowIndex = _f.clickRowIndex, setClickRowIndex = _f.setClickRowIndex, syncScroll = _f.syncScroll, dataState = _f.dataState, hoveredSubRowIndex = _f.hoveredSubRowIndex, setHoveredSubRowIndex = _f.setHoveredSubRowIndex, toggleSubData = _f.toggleSubData, clickSubRowIndex = _f.clickSubRowIndex, setClickSubRowIndex = _f.setClickSubRowIndex;
    var cursorClass = props.onRowClick ? 'cursor-pointer' : '';
    var loadingMessage = "Loading Data . . .";
    var notFoundMessage = "No data found";
    return (_jsx("div", __assign({ className: "".concat(containerTableClass, " w-full flex flex-grow h-full") }, { children: loading === true ? (_jsx(SpinnerTable, { className: "w-full", message: loadingMessage })) : props.data.length <= 0 ? (_jsx(EmptyResult, { className: "w-full", message: notFoundMessage })) : (props.columns.map(function (table, tableIdx) {
            var overflowClass = tableIdx + 1 !== props.columns.length
                ? 'overflow-y-hidden'
                : 'overflow-y-auto';
            var borderClass = tableIdx === 0 ? 'rounded' : 'border-l';
            return (_jsx("div", __assign({ ref: function (el) {
                    if (el) {
                        tableRefs.current[tableIdx] = el;
                    }
                }, className: "overflow-x-scroll flex h-full ".concat(borderClass, " ").concat(overflowClass), style: {
                    width: table.width,
                }, onScroll: function (e) { return syncScroll(e, tableIdx); } }, { children: _jsxs("table", __assign({ className: "w-full table-auto h-fit" }, { children: [_jsx("thead", { children: _jsx("tr", __assign({ className: "bg-white border-b" }, { children: table.header.map(function (col, colIdx) { return (_jsx("th", __assign({ className: "".concat(thClass, " text-left px-2 h-10 themes-bg-gray-v1 themes-text-gray-v5 sticky whitespace-nowrap top-[-1px]") }, { children: col.label }), colIdx)); }) })) }), _jsx("tbody", { children: dataState === null || dataState === void 0 ? void 0 : dataState.map(function (row, rowIdx) {
                                var hasSubData = 'subData' in row &&
                                    'showSubData' in row;
                                var hoverClass = hoveredRowIndex === rowIdx
                                    ? 'themes-bg-gray-v3'
                                    : '';
                                var onClickClass = props.onRowClick &&
                                    clickRowIndex === rowIdx
                                    ? 'themes-bg-brand-v2'
                                    : '';
                                return (_jsxs(Fragment, { children: [_jsx("tr", __assign({ className: "".concat(hoverClass, " ").concat(onClickClass, " ").concat(cursorClass, " border-b themes-text-gray-v9"), onMouseEnter: function () {
                                                return setHoveredRowIndex(rowIdx);
                                            }, onMouseLeave: function () {
                                                return setHoveredRowIndex(null);
                                            }, onClick: function () {
                                                if (props.onRowClick) {
                                                    setClickRowIndex(rowIdx);
                                                    setClickSubRowIndex(null);
                                                    props.onRowClick(row);
                                                    if (props.useSubData) {
                                                        toggleSubData(rowIdx);
                                                    }
                                                }
                                                // if ()
                                            } }, { children: table.header.map(function (column, idx3) {
                                                return (_jsx("td", __assign({ className: "".concat(tdClass, " text-left h-10 px-2 ") }, { children: _jsx("div", __assign({ className: "flex items-center w-max" }, { children: column.customBuild
                                                            ? column.customBuild(row)
                                                            : row[column
                                                                .accessor] ||
                                                                '-' })) }), "".concat(idx3, "-").concat(column.accessor)));
                                            }) })), hasSubData &&
                                            row.showSubData &&
                                            row.subData.map(function (subRow, subRowIdx) {
                                                var subRowHoverClass = hoveredSubRowIndex ===
                                                    subRowIdx
                                                    ? 'themes-bg-gray-v3'
                                                    : '';
                                                var onClickClass = props.onRowClick &&
                                                    clickSubRowIndex ===
                                                        subRowIdx
                                                    ? 'themes-bg-brand-v2'
                                                    : '';
                                                return (_jsx("tr", __assign({ className: "".concat(subRowHoverClass, " ").concat(onClickClass, " ").concat(cursorClass, " border-b themes-bg-gray-v1 themes-text-gray-v5"), onMouseEnter: function () {
                                                        return setHoveredSubRowIndex(subRowIdx);
                                                    }, onMouseLeave: function () {
                                                        return setHoveredSubRowIndex(null);
                                                    }, onClick: function () {
                                                        if (props.onRowClick) {
                                                            // click active
                                                            setClickSubRowIndex(subRowIdx);
                                                            setClickRowIndex(null);
                                                            props.onRowClick(subRow);
                                                        }
                                                    } }, { children: table.header.map(function (column, idx4) {
                                                        return (_jsx("td", __assign({ className: "".concat(tdClass, " text-left h-10 px-2 ") }, { children: _jsx("div", __assign({ className: "flex items-center w-max" }, { children: column.customBuild
                                                                    ? column.customBuild(subRow)
                                                                    : subRow[column
                                                                        .accessor] ||
                                                                        '-' })) }), "".concat(idx4, "-").concat(column.accessor)));
                                                    }) }), subRowIdx));
                                            })] }, rowIdx));
                            }) })] })) }), tableIdx));
        })) })));
};
export default SplitTable;
