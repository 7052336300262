import Tab from '@components/tab/tab.component'
import useBookingDetails from './booking-details.service'
import { tabDetailHeaders } from './booking-details.static'
import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'
import Spinner from '@components/spinner/spinner.component'
import Details from './contents/details.component'
import PackLines from './contents/pack-lines.component'
import EDocs from './contents/e-docs.component'
import Messages from './contents/messages.component'
import Milestones from './contents/milestones.components'
import Events from './contents/events.components'
import Container from './contents/container.component'
import RelatedParties from './contents/related-parties.component'

const BookingDetails = () => {
    const BDService = useBookingDetails() // booking details service
    const { t } = useTranslation()
    const spinnerShow = BDService.loading ? '' : 'hidden'
    const contentShow = BDService.loading ? 'hidden' : ''
    const tabItems = tabDetailHeaders.filter(
        (item) =>
            BDService.bookingsDataDetail.shipmentDetails[0].containerMode ===
                'Full Container Load' || item.value !== 'container',
    )

    const loadContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: (
                <Details
                    data={BDService.bookingsDataDetail}
                    bookingNo={BDService.id}
                />
            ),
            packLines: <PackLines data={BDService.bookingsDataDetail} />,
            // eDocs: <EDocs />,
            // events: <Events />,
            messages: <Messages />,
            milestones: <Milestones />,
            container: (
                <Container
                    data={BDService.bookingsDataDetail.detailsContainers}
                />
            ),
            relatedParties: (
                <RelatedParties
                    data={BDService.bookingsDataDetail?.relatedParties}
                />
            ),
        }
        return contents[BDService.tabFilter.value]
    }

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <div className="flex justify-between items-center px-3">
                    <Tab
                        items={tabItems}
                        tabFilter={BDService.tabFilter}
                        onChange={(item) => {
                            BDService.setTabFilter(item)
                        }}
                    />
                    {/* hide this feature */}
                    {/* <div className="flex gap-3">
                        <Button
                            onClick={() => {
                                BDService.navigate('/edit-booking')
                            }}
                            label={t('bookings.edit_booking')}
                            variant="brand-inverse"
                            className="w-btnRegularWidth"
                        />
                        <Button
                            onClick={() => BDService.navigate('/book-again')}
                            label={t('bookings.book_again')}
                            variant="brand-inverse"
                            className="w-btnRegularWidth"
                        />
                    </div> */}
                </div>
                <div className="border-b"></div>
                <div className="h-[calc(100vh-13rem)] overflow-auto">
                    <div
                        className={`${spinnerShow} flex items-center justify-center h-full`}
                    >
                        <Spinner label="Please Wait..." />
                    </div>
                    <div className={`${contentShow} w-full h-full`}>
                        {loadContent()}
                    </div>
                </div>
                <div className="border-b"></div>
                <div className="p-3">
                    <Button
                        onClick={() => BDService.navigate('/bookings')}
                        label={t('action.back')}
                        variant="brand-v2-inverse"
                        className="w-btnRegularWidth"
                        useUpperCase={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default BookingDetails
