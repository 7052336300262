import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { QuotationDataDummy } from './quotations.dummy'
import { IQuotations } from './quotations.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const QuotationStatusHeader: ITabItem[] = [
    {
        label: 'All Quotation',
        labelTranslationCode: 'status.all_quotation',
        totalData: QuotationDataDummy.length,
        key: 'status',
        value: 'allQuotations',
        className: 'brand-v2',
    },
    {
        label: 'Approved',
        labelTranslationCode: 'status.approved',
        totalData: QuotationDataDummy.filter(
            (x: any) => x.status === 'approved',
        ).length,
        key: 'status',
        value: 'approved',
        className: 'brand-v2',
    },
    {
        label: 'In Review',
        labelTranslationCode: 'status.in_review',
        totalData: QuotationDataDummy.filter((x: any) => x.status === 'waiting')
            .length,
        key: 'status',
        value: 'waiting',
        className: 'brand-v2',
    },
    {
        label: 'Declined',
        labelTranslationCode: 'status.declined',
        totalData: QuotationDataDummy.filter(
            (x: any) => x.status === 'declined',
        ).length,
        key: 'status',
        value: 'declined',
        className: 'brand-v2',
    },
]

export const QuotationSummaryHeader: ITableColumn[] = [
    {
        label: 'Quotation No.',
        accessor: 'quotationNo',
    },
    {
        label: 'Company',
        accessor: 'companyName',
    },
    {
        label: 'Start Date',
        accessor: 'startDate',
    },
    {
        label: 'End Date',
        accessor: 'endDate',
    },
    {
        label: 'One Off Shipment Origin',
        accessor: 'oneOffShipmentOrigin',
    },
    {
        label: 'One Off Shipment Destination',
        accessor: 'oneOffShipmentDestination',
    },
    {
        label: 'Total Local Self Amount',
        accessor: 'totalLocalSelfAmount',
    },
]

export const QuotationsConfigureColumns: IConfigureColumns<IQuotations>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Quotation No.',
                accessor: 'quotationNo',
                labelTranslation: 'quotes.quotation_no',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Company',
                accessor: 'companyName',
                labelTranslation: 'quotes.company',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Start Date',
                accessor: 'startDate',
                labelTranslation: 'quotes.start_date',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'End Date',
                accessor: 'endDate',
                labelTranslation: 'quotes.end_date',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'One Off Shipment Origin',
                accessor: 'oneOffShipmentOrigin',
                labelTranslation: 'quotes.one_off_shipment_origin',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'One Off Shipment Destination',
                accessor: 'oneOffShipmentDestination',
                labelTranslation: 'quotes.one_off_shipment_destination',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Local Self Amount',
                accessor: 'totalLocalSelfAmount',
                labelTranslation: 'quotes.total_local_self_amount',
            },
            {
                statusColumn: 'availableColumns',
                label: 'Updated By',
                accessor: 'updatedBy',
                labelTranslation: 'additional.updated_by',
            },
        ],
    },
]
