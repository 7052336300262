var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import './button.style.css';
var Button = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'button' : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, onClick = _a.onClick, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, useUpperCase = _a.useUpperCase, _e = _a.label, label = _e === void 0 ? '' : _e, _f = _a.variant, variant = _f === void 0 ? 'default' : _f, _g = _a.iconClassName, iconClassName = _g === void 0 ? '' : _g, _h = _a.iconSuffix, iconSuffix = _h === void 0 ? '' : _h, _j = _a.labelClass, labelClass = _j === void 0 ? '' : _j, _k = _a.dataTestId, dataTestId = _k === void 0 ? '' : _k, props = __rest(_a, ["type", "isDisabled", "onClick", "isLoading", "useUpperCase", "label", "variant", "iconClassName", "iconSuffix", "labelClass", "dataTestId"]);
    var disableButton = isLoading ? true : isDisabled;
    var newLabel = useUpperCase ? label === null || label === void 0 ? void 0 : label.toUpperCase() : label;
    var buttonVariant = 'button-' + variant;
    var hasIcon = props.icon || iconSuffix;
    var buttonIconClass = hasIcon && !label ? 'button-icon' : '';
    var dataTestIdComponent = dataTestId ? "".concat(dataTestId, "_BUTTON") : '';
    var customLabel = isLoading ? ('PLEASE WAIT') : (_jsxs(_Fragment, { children: [!props.icon ? undefined : (_jsx("i", { className: "".concat(props.icon, " ").concat(iconClassName, " ").concat(newLabel ? "pr-2 sm:pr-0 ".concat(props.isMobileIcon && 'md:hidden') : '') })), props.icon || iconSuffix !== '' ? (_jsx("span", __assign({ className: "".concat(labelClass, " sm:hidden") }, { children: newLabel }))) : (_jsx("span", __assign({ className: "".concat(labelClass) }, { children: newLabel }))), iconSuffix === '' ? undefined : (_jsx(_Fragment, { children: _jsx("i", { className: "".concat(iconSuffix, " ").concat(iconClassName, "  ").concat(newLabel ? "pl-2 sm:pl-0 ".concat(props.isMobileIcon && 'md:hidden') : '') }) }))] }));
    return (_jsx("button", __assign({}, props, { "data-testid": dataTestIdComponent, type: type, disabled: disableButton, onClick: isDisabled
            ? undefined
            : function () {
                onClick();
            }, className: "loglines-button ".concat(buttonVariant, " ").concat(props.className, " ").concat(buttonIconClass) }, { children: customLabel })));
};
export default Button;
