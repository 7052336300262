import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ITableColumn } from '@components/table/table.interface'
import { ShipmentConfigureColumns } from './shipments.static'
import { getShipmentsData } from 'repository/shipment.repository'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import {
    // filterSelector,
    setFilter,
    setPageNumber,
    setSelectedStatus,
    setShipmentData,
    // shipmentMetaSelector,
    // shipmentsDataSelector,
    // shipmentsStatusTotalSelector,
    shipmentsSummaryDataSelector,
    // tabStatusFilterSelector,
} from './shipments.slice'
import {
    IRShipment,
    IRShipmentFilter,
    IRShipmentStatusTotal,
} from 'repository/interface/shipment.interface'
import { Toast } from '@components/toast/toast.component'
import { isEmptyArray, useFormik } from 'formik'
import { initialFilter } from 'common/common.static'
import {
    IShipmentFilter,
    shipmentFilterInitialValue,
} from 'form-validation/shipment.validation'
import { formatDate } from '@services/common.service'
import { IFilterGenerateReport } from './shipments.interface'
import moment from 'moment'
import { getEnv } from 'common/common.service'

const useShipments = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // selector
    const shipmentsData = useSelector(shipmentsSummaryDataSelector)

    // state
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingGenerateReport, setLoadingGenerateReport] =
        useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [headers, setHeaders] = useState<ITableColumn<IRShipment>[]>([])
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [filterGenerateReport, setFilterGenerateReport] =
        useState<IFilterGenerateReport>({
            filter: {},
            isGenerateReport: false,
        })

    // variable
    const env = getEnv()

    // overlay
    const filterOverlayService = useOverlay()
    const newBookingOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    // variable
    const { filter, meta, data, statusTotal, statusSelected } = shipmentsData

    // formiks
    const formikFilter = useFormik<IShipmentFilter>({
        initialValues: shipmentFilterInitialValue,
        onSubmit: (values) => {
            if (filterGenerateReport.isGenerateReport) {
                setFilterGenerateReport({
                    isGenerateReport: false,
                    filter: flattenedFilterData(values),
                })
                updateFormFilter()
            } else {
                dispatch(setFilter(flattenedFilterData(values)))
            }
        },
    })

    const flattenedFilterData = (values: IShipmentFilter) => {
        const tmpFilter: IRShipmentFilter = {
            ...filter,
            ETAStart: formatDate(values?.eta?.to?.toISOString(), 'YYYY-MM-DD'),
            ETAEnd: formatDate(values?.eta?.to?.toISOString(), 'YYYY-MM-DD'),
            ETDStart: formatDate(values.etd.from?.toISOString(), 'YYYY-MM-DD'),
            ETDEnd: formatDate(values.etd.to?.toISOString(), 'YYYY-MM-DD'),
            TransportModeCode: values.transportMode,
            Consignee: values.consignee,
            Consignor: values.consignor,
            Origin: values.origin,
            Destination: values.destination,
        }
        return tmpFilter
    }

    useEffect(() => {
        dispatch(setFilter(initialFilter))
        setIsInitialized(true)
    }, [])

    useEffect(() => {
        generateTabItems(statusTotal)
    }, [statusTotal])

    // get data
    useEffect(() => {
        if (isInitialized) loadData()
    }, [filter, statusSelected, isInitialized])

    // Get Data Function
    const loadData = async () => {
        const status = statusSelected.value

        let tmpFilter: IRShipmentFilter = filter
        if (statusSelected?.childStatus) {
            const inProgressStat = statusSelected?.childStatus[0].value
            tmpFilter = { ...filter, status, inProgressStatus: inProgressStat }
        } else {
            tmpFilter = { ...filter, status }
        }
        try {
            setLoading(true)
            const actionResult = await getShipmentsData(tmpFilter)
            dispatch(setShipmentData(actionResult))
            setLoading(false)
            if (isEmptyArray(actionResult.data)) {
                Toast({
                    header: 'Search Not Found!',
                    message:
                        "The keyword you've entered does not display any result.",
                    type: 'error',
                })
            }
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const loadGenerateReportData = async (): Promise<IRShipment[]> => {
        let generateData: IRShipment[] = []
        setLoadingGenerateReport(true)
        try {
            const actionResult = await getShipmentsData({
                ...filterGenerateReport,
                status: 'all',
                search: '',
                pageNumber: 1,
                pageSize: shipmentsData?.meta?.total_Items || 50,
            })
            generateData = actionResult.data
        } catch (e) {
            console.error(e)
        }
        setLoadingGenerateReport(false)
        return generateData
    }

    // generate tab Items
    const generateTabItems = (status: IRShipmentStatusTotal) => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Shipments',
                totalData: status.all,
                key: 'status',
                value: 'all',
                className: 'brand-v2',
            },
            {
                label: 'Departed',
                totalData: status.departed,
                key: 'status',
                value: 'departed',
                className: 'brand-v2',
            },
            {
                label: 'Boarded',
                totalData: status.boarded,
                key: 'status',
                value: 'boarded',
                className: 'brand-v2',
            },
            {
                label: 'Cleared',
                totalData: status.cleared,
                key: 'status',
                value: 'cleared',
                className: 'brand-v2',
            },
            {
                label: 'Arrived',
                totalData: status.arrived,
                key: 'status',
                value: 'arrived',
                className: 'brand-v2',
            },
            {
                label: 'Others',
                totalData: status.others,
                key: 'status',
                value: 'others',
                className: 'brand-v2',
            },
        ]

        setTabItems(tabItems)
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(setFilter({ ...filter, search: values, pageNumber: 1 }))
        } else if (values.length <= 0)
            dispatch(setFilter({ ...filter, search: '', pageNumber: 1 }))
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const updateFormFilter = () => {
        const tmpFilter: IShipmentFilter = {
            eta: {
                from: filter.ETAStart
                    ? moment(filter.ETAStart, 'YYYY-MM-DD').toDate()
                    : undefined,
                to: filter.ETAEnd
                    ? moment(filter.ETAEnd, 'YYYY-MM-DD').toDate()
                    : undefined,
            },
            etd: {
                from: filter.ETDStart
                    ? moment(filter.ETDStart, 'YYYY-MM-DD').toDate()
                    : undefined,
                to: filter.ETDEnd
                    ? moment(filter.ETDEnd, 'YYYY-MM-DD').toDate()
                    : undefined,
            },
            transportMode: filter.TransportModeCode || [],
            consignor: filter.Consignor || [],
            consignee: filter.Consignee || [],
            destination: filter.Destination || [],
            origin: filter.origin || [],
        }
        formikFilter.setValues(tmpFilter)
    }

    return {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabItems,
        statusSelected,
        loading,
        errorMessage,
        meta,
        data,
        setPageData,
        setLoading,
        setErrorMessage,
        navigate,
        handleSearch,
        setTabItems,
        setTabFilter,
        shipmentsData,
        ConfigureColumnsModalService,
        headers,
        ShipmentConfigureColumns,
        setHeaders,
        formikFilter,
        loadGenerateReportData,
        loadingGenerateReport,
        setFilterGenerateReport,
        updateFormFilter,
        env,
    }
}

export default useShipments
