import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'
import { IUseCreateBooking } from '../create-booking.interface'

const BookBy = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const { t } = useTranslation()
    return (
        <div>
            <div className="p-3 h-[calc(100vh-10.7rem)] overflow-auto flex flex-col gap-3 justify-center">
                <div className="font-bold text-size-L mt-80 sm:mt-24 md:mt-0">
                    Book by Container Mode
                </div>
                <span className="-mt-5">
                    Select one of the container mode to get started
                </span>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                    <div
                        className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                        onClick={() => {
                            CBService.goToFormCreate('fcl')
                            // initial value packs because the field is not mandatory in fcl mode
                            CBService.formikCreateBooking.setFieldValue(
                                'loglinesBookingCreateBookingDetails.packs',
                                'null',
                            )
                        }}
                    >
                        <div className="text-size-L font-bold">
                            {t('bookings.full_container_load')}
                            <p className="text-size-S font-normal">
                                SEA / ROAD / RAIL
                            </p>
                        </div>
                        <i className="ri-archive-2-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                    </div>
                    <div
                        className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                        onClick={() => CBService.goToFormCreate('lcl')}
                    >
                        <div className="text-size-L font-bold">
                            Less Container Load
                            <p className="text-size-S font-normal">
                                SEA / ROAD / RAIL
                            </p>
                        </div>
                        <i className="ri-archive-drawer-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                    </div>
                    <div
                        className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                        onClick={() => CBService.goToFormCreate('lse')}
                    >
                        <div className="text-size-L font-bold">
                            {t('bookings.loose')}
                            <p className="text-size-S font-normal">AIR Only</p>
                        </div>
                        <i className="ri-stack-overflow-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                    </div>
                    <div
                        className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                        onClick={() => CBService.goToFormCreate('ftl')}
                    >
                        <div className="text-size-L font-bold">
                            {t('bookings.full_truck_load')}
                            <p className="text-size-S font-normal">Road Only</p>
                        </div>
                        <i className="ri-truck-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                    </div>
                    <div
                        className="relative cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col justify-between items-start p-4 themes-text-brand-v4 themes-bg-brand-v1 min-h-[100px] md:min-h-[200px]"
                        onClick={() => CBService.goToFormCreate('cou')}
                    >
                        <div className="text-size-L font-bold">
                            No Container
                            <p className="text-size-S font-normal">
                                Courier Only
                            </p>
                        </div>
                        <i className="ri-e-bike-2-line text-[51px] themes-text-brand-v3 absolute bottom-4 right-4"></i>
                    </div>
                </div>
                {/* <div>{t('bookings.book_by_mode')}</div>
                <div className="grid grid-cols-5 gap-3">
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={() => onClick('air')}
                    >
                        <i className="ri-flight-takeoff-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.air')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={() => onClick('sea')}
                    >
                        <i className="ri-ship-2-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.sea')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={() => onClick('road')}
                    >
                        <i className="ri-truck-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.road')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={() => onClick('rail')}
                    >
                        <i className="ri-caravan-fill text-[51px]"></i>
                        <div className="text-size-L">{t('bookings.rail')}</div>
                    </div>
                    <div
                        className="cursor-pointer border rounded leading-tight themes-border-brand-v4 flex flex-col items-center py-16 themes-text-brand-v4"
                        onClick={() => onClick('courier')}
                    >
                        <i className="ri-e-bike-2-fill text-[51px]"></i>
                        <div className="text-size-L">
                            {t('bookings.courier')}
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="border-b"></div>
            <div className="p-3">
                <Button
                    onClick={() => {
                        CBService.navigate(-1)
                    }}
                    label={t('action.back')}
                    useUpperCase
                    className="w-btnRegularWidth"
                    variant="brand-v2-inverse"
                />
            </div>
        </div>
    )
}

export default BookBy
