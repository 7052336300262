/* eslint-disable no-unused-vars */
import { ITabItem } from '@components/tab/tab.interface'
import { useState } from 'react'
import { formBookingStatusHeader } from '../create-booking/create-booking.static'
import PackLines from '../create-booking/contents/pack-lines.component'
import Details from './contents/details.component'
import { useNavigate } from 'react-router-dom'
import {
    IBookingDetails,
    IBookingPackLines,
} from '../booking-details/booking-details.interface'
import {
    bookingDetails,
    bookingPackLinesDummy,
} from '../booking-details/booking-details.dummy'
import {
    ICreateBookingPackLinesValidation,
    useBookingsCreateValidation,
} from 'form-validation/booking-details.validation'
import { useFormik } from 'formik'
import {
    IBookAgainValidation,
    bookAgainInitialValue,
    bookAgainValidation,
} from 'form-validation/book-again.validation'
import BookingInformation from '../components/booking-information.component'
import PreviewDetails from '../edit-booking/contents/details.component'
import { editBookingValidation } from 'form-validation/edit-booking.validation'
import { Toast } from '@components/toast/toast.component'
import { tabConsigneeHeaders } from '../bookings.static'

const useBookAgain = () => {
    const navigate = useNavigate()

    const {
        createBookingPackLinesInitialValue,
        createBookingPackLinesValidation,
    } = useBookingsCreateValidation()
    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>(
        formBookingStatusHeader[0],
    )
    const [packLinesData, setPackLinesData] = useState<IBookingPackLines[]>(
        bookingPackLinesDummy,
    )
    const [consignorTabFilter, setConsignorTabFilter] = useState<ITabItem>(
        tabConsigneeHeaders[0],
    )
    const [consigneeTabFilter, setConsigneeTabFilter] = useState<ITabItem>(
        tabConsigneeHeaders[0],
    )
    const [isPreview, setIsPreview] = useState<boolean>(false)
    const [packLineData, setPackLineData] = useState<IBookingPackLines>(
        createBookingPackLinesInitialValue,
    )
    const [packLineParams, setPackLineParams] = useState<
        'update' | 'add' | 'duplicate' | 'delete'
    >('update')

    // fromik
    const formikBookAgain = useFormik<IBookAgainValidation>({
        validationSchema: bookAgainValidation,
        initialValues: bookAgainInitialValue,
        onSubmit: () => {
            setIsPreview(true)
        },
    })
    const formikPlaceBooking = useFormik<IBookingDetails>({
        validationSchema: editBookingValidation,
        initialValues: bookingDetails,
        onSubmit: () => {
            Toast({
                header: 'Succes',
                message: 'Booking successfully created again',
                type: 'success',
            })
            navigate('/booking-details')
        },
    })
    const formikBookingPackLines = useFormik<ICreateBookingPackLinesValidation>(
        {
            validationSchema: createBookingPackLinesValidation,
            initialValues: packLineData,
            enableReinitialize: true,
            onSubmit: (values, { resetForm }) => {
                let updateData = []
                let resetValues = null

                switch (packLineParams) {
                    case 'add':
                        updateData = [
                            ...packLinesData,
                            { ...values, id: packLinesData.length + 1 },
                        ]
                        resetValues = createBookingPackLinesInitialValue
                        break
                    case 'update':
                        updateData = packLinesData.map((item) =>
                            item.id === values.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        break
                    case 'duplicate':
                        updateData = [
                            ...packLinesData,
                            { ...values, id: packLinesData.length + 1 },
                        ]
                        break
                    case 'delete':
                        updateData = packLinesData.filter(
                            (item) => item.id !== values.id,
                        )
                        resetValues = createBookingPackLinesInitialValue
                        break
                    default:
                        updateData = packLinesData
                        break
                }

                setPackLinesData(updateData)
                if (resetValues !== null) {
                    resetForm({ values: resetValues })
                }
            },
        },
    )

    // functions
    const getContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: (
                <Details
                    formik={formikBookAgain}
                    getContentDetails={getContentDetails}
                />
            ),
            // packLines: (
            //     <PackLines
            //         packLinesData={packLinesData}
            //         formik={formikBookingPackLines}
            //         rowOnClick={(data) => setPackLineData(data)}
            //         setPackLineParams={(data) => setPackLineParams(data)}
            //     />
            // ),
        }
        return contents[tabFilter.value]
    }

    const getContentDetails = !isPreview ? (
        <div className="w-1/2">
            <BookingInformation idForm={''} />
        </div>
    ) : (
        <PreviewDetails
            consignorTabFilter={consignorTabFilter}
            setConsignorTabFilter={(data) => setConsignorTabFilter(data)}
            consigneeTabFilter={consigneeTabFilter}
            setConsigneeTabFilter={(data) => setConsigneeTabFilter(data)}
            formik={formikPlaceBooking}
        />
    )

    return {
        getContent,
        setTabFilter,
        setIsPreview,
        navigate,
        formikPlaceBooking,
        isPreview,
        formikBookAgain,
        tabFilter,
    }
}

export default useBookAgain
