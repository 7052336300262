import axios, { AxiosResponse } from 'axios'
import {
    IBlob,
    IFetch,
    IFetchOptions,
    IMeta,
    IPost,
    IRoute,
} from './common.interface'
import moment from 'moment'
import { useEffect } from 'react'
import { removeSession } from 'pages/login/login.slice'
import { getConfig } from 'config/config.service'
import { IConfig } from 'config/config.interface'
import { ifbRoutes } from './routes/ifb.routes'
import i18n from 'config/config-i18n.service'
import { TranslationCode } from '@type/translation-code'

const getBaseUrl = () => {
    const config = getConfig()
    return config.apiUrl
}
export const getClient = () => {
    const config: IConfig = getConfig()
    return config.client
}

export const getEnv = () => {
    const config: IConfig = getConfig()
    return config.env
}

export const fetch = async <D, T>(props: IFetch<T>) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
            {
                params: props.params,
            },
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else if (axios.isAxiosError(error) && error?.message) {
            throw new Error(error?.message)
        } else {
            throw new Error('Something error')
        }
    }
}

export const fetchOptions = async <D>(props: IFetchOptions) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else if (axios.isAxiosError(error) && error?.message) {
            throw new Error(error?.message)
        } else {
            throw new Error('Something error')
        }
    }
}

export const post = async <D, T>(props: IPost<T>) => {
    const response: AxiosResponse<D> = await axios.post(
        getBaseUrl() + props.endpoint,
        props?.payload,
        {
            headers: {
                'Content-Type': props?.isFormData
                    ? 'multipart/form-data'
                    : 'application/json',
            },
            params: props?.params,
        },
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const put = async <D, T>(props: IPost<T>) => {
    const response: AxiosResponse<D> = await axios.put(
        getBaseUrl() + props.endpoint,
        props?.payload,
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const deleteRequest = async <D, T>(props: IFetch<T>) => {
    console.log('props', props)

    const response: AxiosResponse<D> = await axios.delete(
        getBaseUrl() + props.endpoint,
        { params: props.params },
    )

    if (response.status === 401) {
        removeSession()
    }
    return response.data
}

export const fecthBlob = async <D, T>(props: IBlob<T>) => {
    try {
        const response: AxiosResponse<D> = await axios.get(
            getBaseUrl() + props.endpoint,
            {
                params: props?.params,
                responseType: 'blob',
            },
        )
        return response
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            removeSession()
            throw new Error('Unauthorized Access: Please log in')
        } else {
            throw new Error('No Data Available')
        }
    }
}

export const formatNumber = (num: string | number) => {
    return typeof num === 'number'
        ? num.toLocaleString()
        : parseInt(num).toLocaleString()
}

export const formatDateTime = (dateString: string) => {
    const formattedDate = moment(dateString).format('DD/MM/YYYY, HH:mm')
    return formattedDate
}

export const formatDateString = (dateString: string) => {
    const formattedDate = moment(dateString).format('DD MMM YYYY')
    return formattedDate
}

export const formatDate = (
    dateString: string,
    format: string = 'DD/MM/YYYY',
) => {
    const date = moment(dateString)
    if (!date.isValid()) {
        return '' // Atau Anda bisa mengembalikan string kosong atau pesan lainnya
    }
    return date.format(format)
}

export const formatDateDash = (dateString: string) => {
    const formattedDate = moment(dateString).format('DD-MM-YYYY')
    return formattedDate
}

export const getModuleParentId = () => {
    let parentIds: string[] = []

    getRoutesByClient().forEach((dt) => {
        if (!dt.parentId) {
            return
        }

        parentIds.push(dt.parentId)

        let sub: IRoute[] | undefined = dt.sub
        if (sub) {
            sub?.forEach((dtSub) => {
                if (!dtSub.parentId) {
                    return
                }
                parentIds.push(dtSub.parentId)
            })
        }
    })
    return parentIds
}

export const toCamelCase = (str: string) => {
    // Split kata-kata berdasarkan spasi atau underscore
    const words = str.split(/\s|_/)

    // Ubah setiap kata menjadi camelCase
    const camelCaseWords = words.map((word, index) => {
        // Pada kata pertama, gunakan huruf kecil
        if (index === 0) {
            return word.toLowerCase()
        }

        // Pada kata lainnya, gunakan huruf besar di awal
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })

    // Gabungkan kata-kata menjadi satu string
    return camelCaseWords.join('')
}

export const revertToTitleCase = (str: string) => {
    // Breaks a string into an array of words
    let words = str.match(/[A-Za-z][a-z]*/g) || []

    // Combine words with spaces and change the first letter of each word to uppercase
    return words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export const numberWithCommas = (x: string) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const capitalizeFirstLetter = (str: string) => {
    const words = str.split(/\s|_/)

    const CapitalizedFirstLetter = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })

    return CapitalizedFirstLetter.join(' ')
}

export const removeUnderscores = (text: string) => text.replace(/_/g, ' ')

/**
 * Hook that alerts clicks outside of the passed refCombine words with spaces and change the first letter of each word to uppercase
 */
export function useOutsideClickHandling(ref: any, func: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                func()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}

// copy text to clipboard
export const copyTextToClipboard = (
    id: string,
    iconClassNames: string = '',
) => {
    // Mengambil elemen berdasarkan ID
    const getContainer = document.getElementById(id)

    // Mengambil nilai teks dari elemen div
    const inputElement: any = getContainer
        ? getContainer.querySelector('input')
        : null

    const textValue = inputElement
        ? (inputElement as HTMLInputElement).value
        : (getContainer?.textContent as string)

    // Menyalin teks ke clipboard
    navigator.clipboard
        .writeText(textValue)
        .then(() => {
            // Mengubah ikon menjadi tanda centang setelah berhasil disalin ke clipboard
            const iconElement =
                getContainer?.querySelector('.ri-file-copy-line')
            console.log(iconElement)

            if (iconElement) {
                iconElement.className = `${iconClassNames} ri-check-line`

                // Setelah 1.5 detik, kembalikan ikon ke ikon semula
                setTimeout(() => {
                    iconElement.className = `${iconClassNames} ri-file-copy-line cursor-pointer`
                }, 1500)
            }
        })
        .catch((err) => {
            console.error('Failed to copy text: ', err)
        })
}
export function changeInputValueToZero(id: string, value: string): void {
    // Mendapatkan referensi ke elemen input berdasarkan ID
    const myInput: HTMLInputElement | null = document.getElementById(
        id,
    ) as HTMLInputElement

    // Memastikan elemen ditemukan sebelum mengubah nilai
    if (myInput && value === '') {
        // Mengubah nilai elemen input
        myInput.value = '0'
    }
}

export const filterContentByEnv = (devOnly: boolean | undefined) => {
    const env = getEnv()

    if (!devOnly) return true
    if (env !== 'production') return true
    return false
}

export const getRoutesByClient = () => {
    const client = getClient()
    let routes: IRoute[] = []

    switch (client) {
        case 'ifb':
            routes = ifbRoutes
            return routes
        default:
            return routes
    }
}

export const mapPath = (path: string) => {
    if (typeof path !== 'string') return []
    const pathArray = path.split('/')?.filter((data) => data)
    return pathArray
}

export const openEmailClient = (emailAddress: string) => {
    var subject = 'Subject'
    var body = '--- Body ---'

    var mailtoUrl =
        'mailto:' +
        encodeURIComponent(emailAddress) +
        '?subject=' +
        encodeURIComponent(subject) +
        '&body=' +
        encodeURIComponent(body)

    window.open(mailtoUrl)
}

export const translate = (value: TranslationCode, options?: any) => {
    return i18n.t(value, options)
}

export const debounce = <T extends (...args: any[]) => Promise<any>>(
    func: T,
    delay: number,
) => {
    let timer: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>): Promise<ReturnType<T>> => {
        return new Promise<ReturnType<T>>((resolve, reject) => {
            if (timer) clearTimeout(timer)
            timer = setTimeout(async () => {
                try {
                    const result = await func(...args)
                    resolve(result)
                } catch (error) {
                    reject(error)
                }
            }, delay)
        })
    }
}

let debounceTimer: ReturnType<typeof setTimeout>
export function debounceVoid(
    callback: (...args: any[]) => void,
    delay: number,
) {
    return function (...args: any[]) {
        // Reset timer jika klik terjadi lagi sebelum delay waktu yang ditentukan
        if (debounceTimer) clearTimeout(debounceTimer)

        // Set timer untuk menjalankan fungsi setelah delay
        debounceTimer = setTimeout(() => {
            callback(...args) // Panggil fungsi yang diberikan setelah delay
        }, delay)
    }
}

export const numberToFixedInt = (value?: number) =>
    value ? Number(value.toFixed(2)) : 0

export const numberToCurrency = (value?: number) =>
    value ? numberWithCommas(value.toFixed(2)) : '0.00'

// handle meta data
export const createMetaData = ({
    meta,
    lengthData,
    itemPerPage,
    pageNumber,
}: {
    meta: IMeta
    lengthData: number
    itemPerPage: number
    pageNumber: number
}): {
    total_Items: number
    current_page: number
    index_start: number
    index_end: number
    last_page: number
} => {
    const total_Items = lengthData
    const current_page = total_Items ? (pageNumber ? pageNumber : 1) : 0
    const index_start = (current_page - 1) * itemPerPage + 1
    const index_end = Math.min(index_start + itemPerPage - 1, total_Items)
    const last_page = Math.ceil(total_Items / itemPerPage)

    return {
        total_Items,
        current_page,
        index_start,
        index_end,
        last_page,
    }
}

export const rangeDate = (
    startMonth: string,
    endMonth: string,
): { formattedStartMonth: string; formattedEndMonth: string } => {
    const [_startMonth, _startYear] = startMonth.split('/')

    const getLastDateOfMonth = (monthYear: string): string => {
        const endMonth = monthYear
        const lastDate = moment(endMonth, 'MM/YYYY').endOf('month')

        if (!lastDate.isValid()) {
            return 'Invalid Date'
        }

        return lastDate.format('YYYY-MM-DD')
    }

    const formattedStartMonth = `${_startYear}-${_startMonth}-01`
    const formattedEndMonth = getLastDateOfMonth(endMonth)

    return {
        formattedStartMonth,
        formattedEndMonth,
    }
}

export const customRounding = ({
    value,
    useCheckDecimal,
    useDecimalPoint,
    decimalPlaces = 2,
}: {
    value: number | string
    useCheckDecimal?: boolean
    useDecimalPoint?: boolean
    decimalPlaces?: number
}) => {
    // Menghapus tanda koma jika input adalah string
    const numericValue =
        typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value

    let hasDecimals: boolean = false // default
    switch (true) {
        case !!isNaN(numericValue): // Jika nilai tidak valid (NaN), kembalikan string asli
            hasDecimals = true
            return value.toString()
        case !!useDecimalPoint: // value will use decimal point
            hasDecimals = true
            break
        case !!useCheckDecimal:
            hasDecimals = numericValue % 1 !== 0 // format value follows the value sent
            break
    }

    return numericValue.toLocaleString('en-US', {
        minimumFractionDigits: hasDecimals ? decimalPlaces : 0,
        maximumFractionDigits: hasDecimals ? decimalPlaces : 0,
    })
}

export function convertToNumber(value?: string) {
    if (!value) {
        return 0
    }

    // Remove commas and convert to number
    const cleanedValue = value?.replace(/,/g, '')
    return parseFloat(cleanedValue)
}
