import { IUseShipmentDetails } from '../shipment-details.interface'

const Details = ({ useService }: { useService: IUseShipmentDetails }) => {
    const loadDataComponent = ({
        label,
        value,
    }: {
        label: string
        value?: string | number
    }) => (
        <div className="leading-none">
            <div className="themes-text-gray-v5">{label}</div>
            <div className="themes-text-gray-v9">{value || '-'}</div>
        </div>
    )

    return (
        <div className="p-3 h-full">
            <div className="grid grid-cols-4 sm:grid-cols-2 mobile:!grid-cols-1 gap-3 h-full">
                {/* shipments details */}
                <div className="flex flex-wrap flex-col gap-2">
                    <div className="text-size-L themes-text-gray-v9 mb-1">
                        Shipment Details
                    </div>
                    {loadDataComponent({
                        label: 'Shipment No.',
                        value: useService?.id,
                    })}
                    {loadDataComponent({
                        label: 'House Bill',
                        value: useService?.shipmentDetails?.details?.houseBill,
                    })}
                    {loadDataComponent({
                        label: 'Container Mode',
                        value: useService?.shipmentDetails.details
                            ?.containerMode,
                    })}
                    {loadDataComponent({
                        label: 'Transport Mode',
                        value: useService?.shipmentDetails.details
                            ?.transportMode,
                    })}
                    {loadDataComponent({
                        label: 'Service Level',
                        value: useService.shipmentDetails?.details
                            ?.serviceLevelDescription,
                    })}
                    {loadDataComponent({
                        label: 'Outer Packs',
                        value: `${useService?.shipmentDetails?.details?.packs ?? '0'} ${useService?.shipmentDetails?.details?.outerPacksUnitCode ?? ''}`,
                    })}
                    {loadDataComponent({
                        label: 'Weight Measure',
                        value: `${useService?.shipmentDetails?.details?.totalWeight ?? ''} ${useService?.shipmentDetails?.details?.totalWeightUnit ?? ''}`,
                    })}
                    {loadDataComponent({
                        label: 'Volume Measure',
                        value: `${useService?.shipmentDetails?.details?.totalVolume ?? ''} ${useService?.shipmentDetails?.details?.totalVolumeUnit ?? ''}`,
                    })}
                </div>

                {/* Origin & destination */}
                <div className="flex flex-col gap-3 mobile:border-none mobile:pl-0 border-l pl-3">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Origin
                        </div>
                        {loadDataComponent({
                            label: 'Origin',
                            value: useService?.shipmentDetails?.details?.origin
                                .portName,
                        })}
                        {loadDataComponent({
                            label: 'ETD',
                            value: useService?.shipmentDetails?.details?.origin
                                .etdShipment,
                        })}
                        {loadDataComponent({
                            label: 'Estimated Pickup',
                            value: useService?.shipmentDetails?.details?.origin
                                .estimatedPickup,
                        })}
                        {loadDataComponent({
                            label: 'Pickup Required By',
                            value: useService?.shipmentDetails?.details?.origin
                                .picRequiredBy,
                        })}
                        {loadDataComponent({
                            label: 'Pickup Cartage Advised',
                            value: useService?.shipmentDetails?.details?.origin
                                .pickupTrnAdvised,
                        })}
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 my-1">
                            Destination
                        </div>
                        {loadDataComponent({
                            label: 'Destination',
                            value: useService?.shipmentDetails?.details
                                ?.destination.portName,
                        })}
                        {loadDataComponent({
                            label: 'ETA',
                            value: useService?.shipmentDetails?.details
                                ?.destination.etaShipment,
                        })}
                        {loadDataComponent({
                            label: 'Estimated Delivery',
                            value: useService?.shipmentDetails?.details
                                ?.destination.estimatedDelivery,
                        })}
                        {loadDataComponent({
                            label: 'Delivery Required By',
                            value: useService?.shipmentDetails?.details
                                ?.destination.dlvRequiredBy,
                        })}
                        {loadDataComponent({
                            label: 'Delivery Cartage Advised',
                            value: useService?.shipmentDetails?.details
                                ?.destination.deliveryPortTransportAdvised,
                        })}
                    </div>
                </div>

                {/* charges */}
                <div className="flex flex-col gap-3 border-l pl-3 sm:border-none sm:pl-0">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Charges
                        </div>
                        {loadDataComponent({
                            label: 'INCO Term',
                            value: useService?.shipmentDetails?.details?.charges
                                .incoTerm,
                        })}
                        {loadDataComponent({
                            label: 'Goods Value',
                            value: `${useService?.shipmentDetails?.details?.charges.goodsValue ?? ''} ${useService?.shipmentDetails?.details?.charges.goodsValueCurrencyCode ?? ''}`,
                        })}
                        {loadDataComponent({
                            label: 'Additional Terms',
                            value: useService?.shipmentDetails?.details?.charges
                                .additionalTerms,
                        })}
                        {loadDataComponent({
                            label: 'Insurance Value',
                            value: `${useService?.shipmentDetails?.details?.charges.insuranceValue ?? ''} ${useService?.shipmentDetails?.details?.charges.insuranceValueCurrencyCode ?? ''}`,
                        })}
                        {loadDataComponent({
                            label: 'Charges Apply',
                            value: useService?.shipmentDetails?.details?.charges
                                .chargesApply,
                        })}
                    </div>
                </div>

                {/* notes */}
                <div className="flex flex-col gap-3 border-l pl-3 mobile:pl-0 mobile:border-none mobile:pb-4">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Notes
                        </div>
                        {loadDataComponent({
                            label: 'Special Instructions',
                            value: useService?.shipmentDetails?.details?.notes
                                .specialInstructions,
                        })}
                        {loadDataComponent({
                            label: 'Marks and Numbers',
                            value: useService?.shipmentDetails?.details?.notes
                                .marksAndNumbers,
                        })}
                        {loadDataComponent({
                            label: 'Goods Description',
                            value: useService?.shipmentDetails?.details?.notes
                                .detailedGoodsDesc,
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details
