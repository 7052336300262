import { IUseShipmentDetails } from '../shipment-details.interface'

const RelatedParties = ({
    useService,
}: {
    useService: IUseShipmentDetails
}) => {
    const loadDataComponent = ({
        label,
        value,
    }: {
        label?: string | null
        value?: string | number
    }) => (
        <div className="leading-none">
            <div className="themes-text-gray-v5">{label ?? ''}</div>
            <div className="themes-text-gray-v9">{value || '-'}</div>
        </div>
    )

    return (
        <div className="p-3 h-full">
            <div className="grid grid-cols-4 sm:grid-cols-2 mobile:!grid-cols-1 gap-3 h-full">
                {/* Consignor Pickup Address */}
                <div className="flex flex-col gap-1">
                    <div className="text-size-L themes-text-gray-v9 mb-1">
                        Consignor Pickup Address
                    </div>
                    {loadDataComponent({
                        label: 'Company Name',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.company,
                    })}
                    {loadDataComponent({
                        label: 'Address 1',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.address1,
                    })}
                    {loadDataComponent({
                        label: 'Address 2',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.address2,
                    })}
                    {loadDataComponent({
                        label: 'City',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.city,
                    })}
                    {loadDataComponent({
                        label: 'State',
                        value:
                            useService.shipmentDetails.relatedParties
                                ?.consignorPickupAddress?.state?.description ??
                            '',
                    })}
                    {loadDataComponent({
                        label: 'Postcode',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.postCode,
                    })}
                    {loadDataComponent({
                        label: 'Country',
                        value: `${useService.shipmentDetails.relatedParties?.consignorPickupAddress?.countryCode}, ${useService.shipmentDetails.relatedParties?.consignorPickupAddress?.countryName}`,
                    })}
                    {loadDataComponent({
                        label: 'Contact Name',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.contactName,
                    })}
                    {loadDataComponent({
                        label: 'Phone Number',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.contactPhoneNumber,
                    })}
                    {loadDataComponent({
                        label: 'Mobile Number',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.contactMobileNumber,
                    })}
                    {loadDataComponent({
                        label: 'Email Address',
                        value: useService.shipmentDetails.relatedParties
                            ?.consignorPickupAddress?.contactEmailAddress,
                    })}
                </div>

                {/* Consignor Documentary Address */}
                <div className="flex flex-col gap-3 border-l pl-3 sm:!pl-0  sm:!border-none">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Consignor Documentary Address
                        </div>
                        {loadDataComponent({
                            label: 'Company Name',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress.company,
                        })}
                        {loadDataComponent({
                            label: 'Address 1',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress.address1,
                        })}
                        {loadDataComponent({
                            label: 'Address 2',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress.address2,
                        })}
                        {loadDataComponent({
                            label: 'City',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress.city,
                        })}
                        {loadDataComponent({
                            label: 'State',
                            value:
                                useService.shipmentDetails.relatedParties
                                    .consignorDocumentaryAddress?.state
                                    ?.description ?? '',
                        })}
                        {loadDataComponent({
                            label: 'Postcode',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress.postCode,
                        })}
                        {loadDataComponent({
                            label: 'Country',
                            value: `${useService.shipmentDetails.relatedParties.consignorDocumentaryAddress.countryCode}, ${useService.shipmentDetails.relatedParties.consignorDocumentaryAddress.countryName}`,
                        })}
                        {loadDataComponent({
                            label: 'Contact Name',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress.contactName,
                        })}
                        {loadDataComponent({
                            label: 'Phone Number',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress.contactPhoneNumber,
                        })}
                        {loadDataComponent({
                            label: 'Mobile Number',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress
                                .contactMobileNumber,
                        })}
                        {loadDataComponent({
                            label: 'Email Address',
                            value: useService.shipmentDetails.relatedParties
                                .consignorDocumentaryAddress
                                .contactEmailAddress,
                        })}
                    </div>
                </div>

                {/* Consignee Delivery Address */}
                <div className="flex flex-col gap-3 border-l sm:!border-none pl-3 sm:!pl-0">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Consignee Delivery Address
                        </div>
                        {loadDataComponent({
                            label: 'Company Name',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.company,
                        })}
                        {loadDataComponent({
                            label: 'Address 1',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.address1,
                        })}
                        {loadDataComponent({
                            label: 'Address 2',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.address2,
                        })}
                        {loadDataComponent({
                            label: 'City',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.city,
                        })}
                        {loadDataComponent({
                            label: 'State',
                            value:
                                useService.shipmentDetails.relatedParties
                                    .consigneePickupAddress.state
                                    ?.description ?? '',
                        })}
                        {loadDataComponent({
                            label: 'Postcode',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.postCode,
                        })}
                        {loadDataComponent({
                            label: 'Country',
                            value: `${useService.shipmentDetails.relatedParties.consigneePickupAddress.countryCode}, ${useService.shipmentDetails.relatedParties.consigneePickupAddress.countryName}`,
                        })}
                        {loadDataComponent({
                            label: 'Contact Name',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.contactName,
                        })}
                        {loadDataComponent({
                            label: 'Phone Number',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.contactPhoneNumber,
                        })}
                        {loadDataComponent({
                            label: 'Mobile Number',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.contactMobileNumber,
                        })}
                        {loadDataComponent({
                            label: 'Email Address',
                            value: useService.shipmentDetails.relatedParties
                                .consigneePickupAddress.contactEmailAddress,
                        })}
                    </div>
                </div>

                {/* Consignee Documentary Address */}
                <div className="flex flex-col gap-3 border-l sm:!border-none pl-3 sm:!pl-0">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Consignee Documentary Address
                        </div>
                        {loadDataComponent({
                            label: 'Company Name',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress.company,
                        })}
                        {loadDataComponent({
                            label: 'Address 1',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress.address1,
                        })}
                        {loadDataComponent({
                            label: 'Address 2',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress.address2,
                        })}
                        {loadDataComponent({
                            label: 'City',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress.city,
                        })}
                        {loadDataComponent({
                            label: 'State',
                            value:
                                useService.shipmentDetails.relatedParties
                                    .consigneeDocumentaryAddress.state
                                    ?.description ?? '',
                        })}
                        {loadDataComponent({
                            label: 'Postcode',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress.postCode,
                        })}
                        {loadDataComponent({
                            label: 'Country',
                            value: `${useService.shipmentDetails.relatedParties.consigneeDocumentaryAddress.countryCode}, ${useService.shipmentDetails.relatedParties.consigneeDocumentaryAddress.countryName}`,
                        })}
                        {loadDataComponent({
                            label: 'Contact Name',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress.contactName,
                        })}
                        {loadDataComponent({
                            label: 'Phone Number',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress.contactPhoneNumber,
                        })}
                        {loadDataComponent({
                            label: 'Mobile Number',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress
                                .contactMobileNumber,
                        })}
                        {loadDataComponent({
                            label: 'Email Address',
                            value: useService.shipmentDetails.relatedParties
                                .consigneeDocumentaryAddress
                                .contactEmailAddress,
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RelatedParties
