import { ISTColumn } from '@components/simple-table/simple-table.interface'
import {
    IBookingStatusRespon,
    IPointMap,
    IShipmentStatusRespon2,
} from './dashboard.interface'
import { formatDate } from 'common/common.service'
import { IRShipment } from 'repository/interface/shipment.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { faker } from '@faker-js/faker'

export const shipmentTrackingHeader: ISTColumn<IRShipment>[] = [
    {
        accessor: 'transportModeCode',
        label: 'Mode',
        customHeader: () => <div className="text-size-XS">Mode</div>,
        customBuild: (data) => {
            let iconTransportMode: string
            switch (data) {
                case 'AIR':
                    iconTransportMode = 'ri-plane-line'
                    break
                case 'SEA':
                    iconTransportMode = 'ri-ship-line'
                    break
                case 'ROA':
                    iconTransportMode = 'ri-truck-line'
                    break
                case 'RAI':
                    iconTransportMode = 'ri-train-line'
                    break
                case 'COU':
                    iconTransportMode = 'ri-e-bike-2-line'
                    break
                default:
                    iconTransportMode = 'ri-plane-line'
            }

            return <i className={`${iconTransportMode} text-size-XS`}></i>
        },
    },
    {
        accessor: 'shipmentID',
        label: 'Shipment No.',
        customBuild: (data, x, y, z, rowData) => (
            <div className="text-size-XS">{rowData?.shipmentID}</div>
        ),
        customHeader: () => <div className="text-size-XS">Shipment No.</div>,
    },
    {
        accessor: 'originPortCode',
        label: 'Origin',
        customBuild: (data, x, y, z, rowData) => {
            return (
                <div className="themes-bg-brand-v1 themes-text-brand-v5 w-[120px] flex justify-center rounded-md py-1 text-[12px]">
                    {rowData?.datesETDShipment && rowData?.originPortCode
                        ? `${formatDate(rowData?.datesETDShipment || '', 'DD MMM YY')} - ${rowData?.originPortCode}`
                        : rowData?.originPortCode
                          ? `${rowData?.originPortCode}`
                          : '-'}
                </div>
            )
        },
        customHeader: () => <div className="text-size-XS">Origin</div>,
    },
    {
        accessor: 'destinationPortCode',
        label: 'Destination',
        customBuild: (data, x, y, z, rowData) => (
            <div className="themes-bg-brand-v3 !text-white flex justify-center rounded-md p-1  text-[12px]">
                {rowData?.datesETAShipment && rowData?.destinationPortCode
                    ? `${formatDate(rowData?.datesETAShipment || '', 'DD MMM YY')} - ${rowData?.destinationPortCode}`
                    : rowData?.destinationPortCode
                      ? `${rowData?.destinationPortCode}`
                      : '-'}
            </div>
        ),
        customHeader: () => <div className="text-size-XS">Destination</div>,
    },
    {
        accessor: 'exception',
        label: 'Exception',
        customBuild: (data, x, y, z, rowData) => (
            <div
                className={`${rowData?.exception === 'EXC' ? 'themes-bg-red-v1 themes-text-red-v4 themes-border-red-v4' : 'themes-bg-gray-v1 themes-text-brand-v9 themes-border-brand-v9'}  border  w-[40px] flex justify-center rounded-md py-1  text-[12px]`}
            >
                {rowData?.exception ? rowData?.exception : 'NON'}
            </div>
        ),
        customHeader: () => <div className="text-size-XS">Exception</div>,
    },
]

export const shipmentTableTab: ITabItem[] = [
    {
        label: 'All Shipment',
        key: 'status',
        value: 'allInvoices',
        className: 'brand',
    },
    {
        label: 'Origin Only',
        key: 'status',
        value: 'origin',
        className: 'brand',
    },
    {
        label: 'Destination Only',
        key: 'status',
        value: 'destination',
        className: 'brand',
    },
]

export const PointMap = (): IPointMap[] => {
    const data = []

    // Thailand
    for (let i = 0; i < 20; i++) {
        let name = `S0000${faker.number.int({ min: 2000, max: 5000 })}`
        let lat = faker.location.latitude({
            max: 15.870032,
            min: 12.670032,
        })
        let lng = faker.location.longitude({
            max: 100.992541,
            min: 88.192541,
        })
        let key = `THAI-${i}`
        let status = true
        data.push({
            name: name,
            lat: lat,
            lng: lng,
            key: key,
            status: status,
        })
    }

    // INDONESIA
    for (let i = 0; i < 20; i++) {
        let name = `S0000${faker.number.int({ min: 2000, max: 5000 })}`
        let lat = faker.location.latitude({ max: 6.789275, min: -0.789275 })
        let lng = faker.location.longitude({
            max: 113.921327,
            min: 100.921327,
        })
        let key = `INDO-${i}`
        let status = true
        data.push({
            name: name,
            lat: lat,
            lng: lng,
            key: key,
            status: status,
        })
    }

    // Australia
    for (let i = 0; i < 20; i++) {
        let name = `S0000${faker.number.int({ min: 2000, max: 5000 })}`
        let lat = faker.location.latitude({
            max: -25.274398,
            min: -29.274398,
        })
        let lng = faker.location.longitude({
            max: 133.775136,
            min: 132.775136,
        })
        let key = `AUS-${i}`
        let status = true
        data.push({
            name: name,
            lat: lat,
            lng: lng,
            key: key,
            status: status,
        })
    }

    return data
}

export const bookingMilestones: IBookingStatusRespon = {
    Draft: 0,
    'Document Sent': 0,
    Booked: 0,
    'Booking Confirmed': 0,
    'Booking Canceled': 0,
    'Booking Rejected': 0,
}

export const shipmentMilestones: IShipmentStatusRespon2 = {
    'Delivery Cartage Advised': 0,
    'Pickup Cartage Complete': 0,
    'Origin Receival from Wharf': 0,
    'All Export Documents Received': 0,
    'Received from Shipment': 0,
    'Shipped On Board': 0,
    'Export Customs Commenced': 0,
    'Export Customs Cleared': 0,
    'Moved from Origin to First Load Port': 0,
    'All Import Documents Received': 0,
    'Custom Commenced': 0,
    'Customs Cleared': 0,
    'Destination Arrival': 0,
    'Pickup Cartage Advised': 0,
    'Delivery Cartage Complete/Finalised': 0,
}
