import { IMenu, IRoute } from 'common/common.interface'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

export default function Submenu({
    action,
    path,
    showName,
    text,
    expandable,
    textTranslationCode,
    openSideBar,
}: IMenu & IRoute) {
    const { t } = useTranslation()
    // const [isHover, setIsHover] = useState(false)
    const [expand, setExpand] = useState(false)
    const location = useLocation()
    const currentURL = location.pathname
    const menuLabel = textTranslationCode ? t(textTranslationCode) : text

    // is module active?
    const active = currentURL?.includes(path)
    // is sub module active
    // const isSubActive = !sub?.map
    //     ? false
    //     : sub.find((x) => currentURL?.includes(x.path))
    //         ? true
    //         : false
    // const expandableBorder = expandable && expand ? ' border-[#dee1ed]' : ''
    // const subMenuSpacingClass = isSubMenu ? 'mb-[2px]' : 'mb-2'
    // const cIcon = isSubMenu ? `ri-arrow-right-s-line` : icon
    // const sideMenuHeight = isSubMenu
    //     ? 'min-h-[30px] text-[10px]'
    //     : 'min-h-[40px] text-[18px]'
    // const subMenuIconClass = isSubMenu ? '!text-[16px]' : ''

    // active class or hover --------------------------------------------------------
    const menuClass = active ? 'themes-submenu-active' : 'themes-submenu'
    // const divClass = isHover || active ? 'bg-logistical-blue' : ''
    // const divClassRelatedWithSub =
    //     isSubActive && !expand ? '!bg-logistical-blue !text-white' : ''
    // const textClassRelatedWithSub = isSubActive && !expand ? 'text-white' : ''
    // smooth animation sidebar ------------------------------------------------------

    const [showMenuNameClass, setShowMenuClass] = useState(
        showName ? '' : 'hide',
    )

    const [iconDisplay, setIconDisplay] = useState('')

    const formatLabel = text?.toUpperCase().replace(/\s+/g, '_')
    const dataTestId = `${formatLabel}_MENU`

    useEffect(() => {
        if (showName) {
            setShowMenuClass('')
            setIconDisplay(`flex justify-start pr-3 pl-3  `)
            return
        }
        setShowMenuClass('hide')

        const cls =
            'flex w-full transform transition-all  translate-x-[25%]  duration-[2s]'
        setIconDisplay(cls)
        setTimeout(() => {
            setIconDisplay(cls + `   `)
        }, 500)
    }, [showName])

    // end smooth animation side bar  ------------------------------------------------------

    const onClickHandler = () => {
        if (expandable) {
            if (!expand) {
                setExpand((c) => !c)
            }
            openSideBar && !showName && openSideBar()
        } else {
            action(path)
        }
    }

    useEffect(() => {
        if (!showName) {
            setExpand(false)
        }
    }, [showName])

    // useEffect(() => {
    //     if (!active && !isSubActive && sub) {
    //         setExpand(false)
    //     }
    // }, [active, isSubActive])

    return (
        <div
            onClick={onClickHandler}
            // onMouseEnter={() => setIsHover(true)}
            // onMouseLeave={() => setIsHover(false)}
            className={`cursor-pointer w-full flex select-none flex-col justify-center items-center last:mb-0`}
            data-testid={dataTestId}
        >
            <div
                className={`${menuClass} w-full transition-all duration-200 flex items-center rounded-[0.5rem]`}
            >
                <div
                    className={`pl-[30px] items-center min-h-[30px] ${iconDisplay}`}
                >
                    {/* <i
                        className={`cursor-pointer ${subMenuIconClass} ${icon}`}
                    /> */}
                </div>

                <div
                    className={`${showMenuNameClass}  transition-all duration-200 text-[15px] font-[400] tracking-tight text-animation whitespace-nowrap`}
                >
                    {menuLabel}
                </div>
            </div>
        </div>
    )
}
