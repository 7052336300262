import { formatNumber } from 'common/common.service'
import { IInfoCardProps } from '../dashboard.interface'

const InfoCard: React.FC<IInfoCardProps> = ({ icon, total, title, items }) => {
    return (
        <div className="flex flex-col gap-1 md:border-l-0 sm:border-l-0 lg:border-l pl-2 h-full">
            <div className="flex gap-2 items-center">
                <div className="themes-bg-gray-v1 themes-text-gray-v9 rounded-md flex justify-center items-center">
                    <i className={icon} />
                </div>
                <div className="leading-4 font-bold">
                    <div className="info-card-count">{formatNumber(total)}</div>
                    <div className="info-card-title">{title}</div>
                </div>
            </div>
            <div className="themes-text-gray-v5 info-card-item leading-4 ">
                {items
                    .sort((a, b) => {
                        const aValue =
                            typeof a.value === 'string'
                                ? parseFloat(a.value)
                                : a.value
                        const bValue =
                            typeof b.value === 'string'
                                ? parseFloat(b.value)
                                : b.value
                        return bValue - aValue
                    })
                    .map((item, index) => (
                        <div key={index} className="flex justify-between">
                            <div
                                title={item.label}
                                className="w-full text-ellipsis whitespace-nowrap overflow-hidden"
                            >
                                {item.label}
                            </div>
                            <div className="font-bold text-end">
                                {formatNumber(item.value)}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default InfoCard
