import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import useBookings from './bookings.service'
import { IBookings } from './bookings.interface'
import { useTranslation } from 'react-i18next'
import GenerateReportModal from 'common/components/generate-report-modal.component'
import BookingFilter from './components/booking-filter.component'
import { IFormikBookingFilter } from 'form-validation/booking.validation'
import { generateReportHeaders } from './bookings.static'
import Spinner from '@components/spinner/spinner.component'

const Bookings = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        generateReportModalService,
        tabFilter,
        ConfigureColumnsModalService,
        allMNodal,
        loading,
        headers,
        navigate,
        tabItems,
        setTabFilter,
        handleSearch,
        bookingsData,
        bookingsDataMeta,
        setPageData,
        formikFilter,
        updateFormFilter,
        loadGenerateReportData,
        loadingGenerateReport,
        env,
    } = useBookings()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                {loadingGenerateReport ? (
                    <div
                        className={`flex items-center justify-center h-full text-size-XS-testing`}
                    >
                        <Spinner label="Please Wait..." />
                    </div>
                ) : (
                    <>
                        <TabMaster
                            items={tabItems || []}
                            tabFilter={tabFilter}
                            onChange={(item) => {
                                setTabFilter(item)
                            }}
                            //  search ----------------------------------------------------------------
                            useSearch={true}
                            placeHolderSearch={'Search Booking Number'}
                            onSearchSubmit={handleSearch}
                            containerSearchClassName={'flex-1'}
                            //  button for overlay or something ----------------------------------------
                            // hide filter for new feature
                            // iconButton1={{
                            //     onClick: () =>
                            //         filterOverlayService.toggleOverlayHandling(),
                            //     icon: 'ri-filter-2-line',
                            //     filterOverlayComponent: (
                            //         <BookingFilterOverlay
                            //             filterOverlayService={
                            //                 filterOverlayService
                            //             }
                            //             formikFilter={formikFilter}
                            //             updateFormFilter={updateFormFilter}
                            //         />
                            //     ),
                            // }}
                            // iconButton2={{
                            //     onClick: () =>
                            //         ConfigureColumnsModalService.openModalHandling(),
                            //     icon: 'ri-settings-2-line',
                            // }}
                            actionButton1={{
                                onClick: () => {
                                    navigate('/create-booking')
                                },
                                label: t('menu.bookings_new').toUpperCase(),
                                className: '!text-size-S',
                                variant: 'brand-v2-inverse',
                            }}
                            dataTestId="BOOKINGS_TABMASTER"
                        />
                        <Table<IBookings>
                            headers={headers}
                            data={bookingsData}
                            loading={loading}
                            disableErrorIllustration
                            nextHandling={(page) => {
                                setPageData(page)
                            }}
                            previousHandling={(page) => {
                                setPageData(page)
                            }}
                            meta={bookingsDataMeta}
                            moduleTitle={'Booking'}
                            onRowClick={(data) => {
                                const id = data.bookingNo
                                navigate('/booking-details/' + id)
                            }}
                            enableExport={env !== 'production' ? true : false}
                            exportModalService={generateReportModalService}
                            variantButtonExport="brand-v2-inverse"
                            dataTestId="BOOKINGS_TABLE"
                        />
                    </>
                )}
            </div>
            <>
                {allMNodal}
                <GenerateReportModal<IBookings, IFormikBookingFilter>
                    modalService={generateReportModalService}
                    formik={formikFilter}
                    filterFormComponent={<BookingFilter />}
                    module={'Booking'}
                    loadGenerateReportData={loadGenerateReportData}
                    headers={generateReportHeaders}
                    updateFormFilter={updateFormFilter}
                />
            </>
        </div>
    )
}

export default Bookings
