import Tab from '@components/tab/tab.component'
import { tabDetailHeaders } from './shipment-details.static'
import Button from '@components/button/button.component'
import useShipmentDetails from './shipment-details.service'
import { useTranslation } from 'react-i18next'
import Details from './contents/details'
import PackLines from './contents/packLines'
import Milestones from './contents/milestones'
import Tracking from './contents/tracking'
import RelatedParties from './contents/related-parties'
// import Orders from './contents/orders'
// import RelatedInvoices from './contents/related-invoices'
// import Milestones from 'pages/bookings/booking-details/contents/milestones.components'
// import Events from 'pages/bookings/booking-details/contents/events.components'
// import Notes from 'pages/bookings/booking-details/contents/notes.component'
// import Messages from 'pages/bookings/booking-details/contents/messages.component'
// import EDocs from 'pages/bookings/booking-details/contents/e-docs.component'
// import Documents from 'pages/bookings/booking-details/contents/documents.component'

const ShipmentDetails = () => {
    const useService = useShipmentDetails()
    const { t } = useTranslation()

    const getContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: <Details useService={useService} />,
            packLines: <PackLines useService={useService} />,
            // orders: <Orders />,
            // relatedInvoices: <RelatedInvoices />,
            // documents: <Documents />,
            // eDocs: <EDocs />,
            // messages: <Messages />,
            // notes: <Notes />,
            // events: <Events />,
            relatedParties: <RelatedParties useService={useService} />,
            ...(useService.env !== 'production' && {
                milestones: <Milestones useService={useService} />,
                tracking: <Tracking useService={useService} />,
            }),
        }
        return contents[useService.tabFilter.value]
    }

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow  flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <Tab
                    containerClassName="px-3 !overflow-hidden"
                    items={tabDetailHeaders.filter((item) =>
                        useService.env !== 'production'
                            ? item
                            : item.value !== 'milestones' &&
                              item.value !== 'tracking',
                    )}
                    tabFilter={useService.tabFilter}
                    onChange={(item) => {
                        useService.setTabFilter(item)
                    }}
                />
                <div className="border-b"></div>
                <div className="h-[calc(100vh-13rem)] overflow-auto">
                    {getContent()}
                </div>
                <div className="border-b"></div>
                <div className="p-3">
                    <Button
                        onClick={() => useService.navigate(-1)}
                        label={t('action.back')}
                        variant="brand-v2-inverse"
                        className="w-btnRegularWidth"
                        useUpperCase={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default ShipmentDetails
