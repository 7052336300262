import { IResponseData } from 'common/common.interface'
import { post } from 'common/common.service'
import { ILoginValidation } from 'form-validation/login.validation'
import { IRAuthData } from './interface/auth.interface'
import { ifbEndpoints } from 'common/endpoints/ifb.endpoints'

export const auth = async (payload: ILoginValidation) => {
    try {
        const response = await post<
            IResponseData<IRAuthData>,
            ILoginValidation
        >({
            endpoint: ifbEndpoints.auth,
            payload,
        })
        return response
    } catch (error: any) {
        if (error?.response && error?.response?.status === 400) {
            const response: IResponseData<any> = error?.response?.data
            return response
        } else {
            const err = error as IResponseData<IRAuthData>
            err.isSuccess = false
            return err
        }
    }
}
