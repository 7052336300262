import React from 'react'
import { IStatusLabel } from './status-label.interface'
import { statusLabelColorClasses } from './status-label.static'

const StatusLabel = ({
  color = "default",
  children,
  className,
  ...defaultPropsDivElement
}: IStatusLabel) => {
  return (
    <div
      {...defaultPropsDivElement}
      className={`${statusLabelColorClasses[color]} text-[0.82rem] px-4 pt-[0.8rem] pb-3 rounded-sm w-full items-center justify-center uppercase ${className}`}
    >
      {children}
    </div>
  )
}

export default StatusLabel