import * as Yup from 'yup'

// interface for validation
export interface ICreateUserModuleValidation {
    moduleTemplateId: string
    access: {
        canAccessModule: boolean
        canViewDetails: boolean
        canCreate: boolean
        canUpdate: boolean
        canDelete: boolean
    }
}

export interface ICreateUserValidation {
    // account setup
    email: string
    password: string
    confirmPassword: string

    // User Profile
    fullName: string
    jobTitle: string
    phoneNumber: string
    mobilePhoneNumber: string
    homePhoneNumber: string
    city: string
    country: string
    // missing payload
    address1: string
    address2: string
    state: string
    postCode: string
    defaultCurrency: string
    currencyDecimalPoint: string

    // data branch access
    // missing payload
    branch: string

    // field noused
    jobCategory: string
    userName: string
    province: string
    addressDetails: string
    contactName: string
    moduleList: ICreateUserModuleValidation[]
}

// form validation YUP for formik
export const createUserValidation = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    jobTitle: Yup.string().required('Job title is required'),
    // jobCategory: Yup.string().required('Job category is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    // userName: Yup.string().required('User name is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
        .nullable()
        .oneOf([Yup.ref('password')], 'Confirm password must match password')
        .required('Confirm password is required'),
})

// form login initial data
export const createUserInitialValue: ICreateUserValidation = {
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    jobTitle: '',
    phoneNumber: '',
    mobilePhoneNumber: '',
    homePhoneNumber: '',
    city: '',
    country: '',
    address1: '',
    address2: '',
    state: '',
    postCode: '',
    defaultCurrency: '',
    currencyDecimalPoint: '0.00',
    branch: '',
    jobCategory: '',
    userName: '',
    province: '',
    addressDetails: '',
    contactName: '',
    moduleList: [],
}
