import Dropdown from '@components/dropdown/dropdown.component'
import Input from '@components/input/input.component'
import SelectedRateCard from '../components/selected-rate-card.component'
import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { FormikProvider } from 'formik'
import useCreateQuotation from './create-quotation.service'

const CreateQuotations = () => {
    const { navigate, selectRateOnclick, rateData, formik } =
        useCreateQuotation()

    return (
        <div className="container-global content-full-height overflow-hidden grid grid-cols-4 gap-3 ">
            <FormikProvider value={formik}>
                <div>
                    <div className="flex flex-col gap-3 p-3 overflow-auto h-[calc(100vh-10.8rem)]">
                        <div className="flex flex-col gap-3">
                            <div className="flex">
                                <span className="text-size-M font-bold">
                                    Origin{' '}
                                    <i className="ri-arrow-right-line text-size-M font-bold" />{' '}
                                    Destination
                                </span>
                            </div>
                            <FormDropdown
                                name={'originPort'}
                                label="ORIGIN PORT"
                                placeholder="Select Origin Port"
                                options={[
                                    {
                                        label: 'AUMEL - Melbourne',
                                        value: 'AUMEL',
                                    },
                                    {
                                        label: 'ADESC - Escaldes',
                                        value: 'ADESC',
                                    },
                                ]}
                                parentDivClassName="!mb-0"
                            />
                            <FormDropdown
                                name="destinationPort"
                                label="DESTINATION PORT"
                                placeholder="Select Destination Port"
                                options={[
                                    {
                                        label: 'AUMEL - Melbourne',
                                        value: 'AUMEL',
                                    },
                                    {
                                        label: 'ADESC - Escaldes',
                                        value: 'ADESC',
                                    },
                                ]}
                                parentDivClassName="!mb-0"
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            <div>Quote Details</div>
                            <div className="grid grid-cols-2 gap-3">
                                <Dropdown
                                    label="Transport Mode"
                                    placeholder="Select Transport Mode"
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label="Container Mode"
                                    placeholder="Select Container Mode"
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label="Service Level"
                                    placeholder="Select Service Level"
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    label="Inco Term"
                                    placeholder="Select Inco Term"
                                    options={[
                                        {
                                            label: 'AUMEL - Melbourne, VIC, Australia',
                                            value: 'AUMEL - Melbourne, VIC, Australia',
                                        },
                                    ]}
                                />
                            </div>
                            <Input label="Weight" value="0.000" disabled />
                            <Input label="Volume" value="0.000" disabled />
                            <Input
                                label="Value of Goods"
                                value="0.000"
                                disabled
                            />
                            <Input
                                label="Value of Goods Currency"
                                value="0.000"
                                disabled
                            />
                            <Dropdown
                                label="Insurance Value"
                                placeholder="Select Insurance Value"
                                options={[
                                    {
                                        label: 'AUMEL - Melbourne, VIC, Australia',
                                        value: 'AUMEL - Melbourne, VIC, Australia',
                                    },
                                ]}
                            />
                            <Dropdown
                                label="Insurance Value Currency"
                                placeholder="Select Insurance Value Currency"
                                options={[
                                    {
                                        label: 'AUMEL - Melbourne, VIC, Australia',
                                        value: 'AUMEL - Melbourne, VIC, Australia',
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <div className="border-b"></div>
                    <div className="flex justify-between p-3">
                        <Button
                            label="BACK"
                            onClick={() => {
                                navigate(-1)
                            }}
                            className="w-btnSmallWidth"
                            variant="brand-v2-inverse"
                        />
                        <Button
                            label="REFRESH RATES"
                            onClick={() => formik.submitForm()}
                            // className=""
                            variant="brand-v2-inverse"
                        />
                    </div>
                </div>
            </FormikProvider>
            <div className="col-span-3 p-3 my-3 mr-3 themes-bg-gray-v1 flex flex-col overflow-auto gap-3 rounded border">
                {!rateData.length ? (
                    <div className="flex justify-center items-center h-full">
                        No rates found.
                    </div>
                ) : (
                    rateData.map((item, index) => (
                        <SelectedRateCard
                            key={index}
                            isCreate={true}
                            data={item}
                            selectRateOnclick={selectRateOnclick}
                        />
                    ))
                )}
            </div>
        </div>
    )
}

export default CreateQuotations
