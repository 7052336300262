var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useRef, useState } from 'react';
var useSplitTable = function (_a) {
    var data = _a.data, loading = _a.loading, columns = _a.columns, useSubData = _a.useSubData;
    var tableRefs = useRef([]);
    var _b = useState(null), hoveredRowIndex = _b[0], setHoveredRowIndex = _b[1];
    var _c = useState(null), hoveredSubRowIndex = _c[0], setHoveredSubRowIndex = _c[1];
    var _d = useState(0), clickRowIndex = _d[0], setClickRowIndex = _d[1];
    var _e = useState(null), clickSubRowIndex = _e[0], setClickSubRowIndex = _e[1];
    var _f = useState(), dataState = _f[0], setDataState = _f[1];
    var syncScroll = function (e, index) {
        var currentScrollTop = e.currentTarget.scrollTop;
        tableRefs.current.forEach(function (table, idx) {
            if (table && idx !== index) {
                table.scrollTop = currentScrollTop;
            }
        });
    };
    function initialDataState(dt) {
        if (!Array.isArray(dt))
            return;
        if (useSubData) {
            var foundSubData_1 = false;
            var newData = dt.map(function (item) {
                var hasSubData = 'subData' in item &&
                    Array.isArray(item.subData) &&
                    item.subData.length > 0;
                if (hasSubData && !foundSubData_1) {
                    // Set showSubData true hanya untuk data pertama yang memiliki subData
                    foundSubData_1 = true;
                    return __assign(__assign({}, item), { showSubData: true });
                }
                else {
                    // Untuk data lainnya, set showSubData false
                    return __assign(__assign({}, item), { showSubData: false });
                }
            });
            setDataState(newData);
        }
        else {
            setDataState(dt);
        }
    }
    var toggleSubData = function (index) {
        setDataState(function (prevData) {
            if (!prevData)
                return prevData;
            return prevData.map(function (item, i) {
                if (i === index &&
                    'subData' in item &&
                    Array.isArray(item.subData)) {
                    // Toggle showSubData for the row that has subData
                    return __assign(__assign({}, item), { showSubData: !item.showSubData });
                }
                // If not, keep showSubData false
                return __assign(__assign({}, item), { showSubData: false });
            }); // Otherwise just `T[]`
        });
    };
    useEffect(function () {
        var handleWheelScroll = function (index) { return function (e) {
            var scrollTop = e.deltaY;
            tableRefs.current.forEach(function (table, idx) {
                if (table && idx !== index) {
                    table.scrollTop += scrollTop;
                }
            });
        }; };
        tableRefs.current.forEach(function (table, index) {
            if (table) {
                table.addEventListener('wheel', handleWheelScroll(index));
            }
        });
        return function () {
            tableRefs.current.forEach(function (table, index) {
                if (table) {
                    table.removeEventListener('wheel', handleWheelScroll(index));
                }
            });
        };
    }, [loading]);
    useEffect(function () {
        setClickRowIndex(0);
        initialDataState(data);
    }, [data]);
    return {
        tableRefs: tableRefs,
        hoveredRowIndex: hoveredRowIndex,
        setHoveredRowIndex: setHoveredRowIndex,
        clickRowIndex: clickRowIndex,
        setClickRowIndex: setClickRowIndex,
        syncScroll: syncScroll,
        dataState: dataState,
        hoveredSubRowIndex: hoveredSubRowIndex,
        setHoveredSubRowIndex: setHoveredSubRowIndex,
        toggleSubData: toggleSubData,
        clickSubRowIndex: clickSubRowIndex,
        setClickSubRowIndex: setClickSubRowIndex,
    };
};
export default useSplitTable;
