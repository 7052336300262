export const ifbEndpoints = {
    // auth
    auth: 'authentication',

    // loglines meta data
    loglines_meta_data_ContainerMode: 'LoglinesMetadata/ContainerMode',
    loglines_meta_data_Branch: 'LoglinesMetadata/Branch',

    // dashboard
    news_scrapper: 'Scrapper/ifb',
    scfi_index: 'Scrapper/sse',
    shipmentStatus: 'UniversalShipment/GetShipmentStatus',
    shipmentMilestoneStatus: 'UniversalShipment/GetShipmentMilestones',
    bookingStatus: 'BookingShipment/GetBookingMilestones',
    containerModeStatus: 'UniversalShipment/GetContainerTransportStatus',
    dashboard_TotalShipmentsByInvoicePaid:
        'UniversalShipment/GetInvoiceCountPerMonth',
    dashboard_OpenInvoices: 'UniversalShipment/GetInvoicePaid',

    //bookings
    bookings: 'BookingShipment',
    bookingsGetMetadata: 'BookingShipment/GetMetadata',
    bookingsGetContactMetadata: 'BookingShipment/GetContactMetadata',
    GetOriginDestination: 'BookingShipment/GetOriginDestination',
    bookingsCreate: 'BookingShipment/create',

    //volume
    barChartVolume: 'Analytics/Volume/Transport',
    donatChartVolume: 'Analytics/Volume',

    // performance
    performance_getStatus: 'Analytics/Performance/ShipmentStatus',
    performance_getByPorts: 'Analytics/Performance/PerformanceByPorts',
    performance_averageTransitTime: 'Analytics/Performance/ShipmentTransitTime',
    performance_shipmentsTransitTimeByPorts:
        'Analytics/Performance/ShipmentTransitTimeByPort',
    performance_averageTransitTimeByMode:
        'Analytics/Performance/ShipmentTransitRecordedByModes',
    performance_totalCostByModes: 'Analytics/Performance/TotalCostByModes',
    performance_totalMonthlyCost: 'Analytics/Performance/TotalMonthlyCost',
    performance_portCharges: 'Analytics/Performance/PortCharges',
    performance_totalCostByPorts: 'Analytics/Performance/TotalCostByPorts',
    performance_milestoneGroupDayRange:
        'Analytics/Performance/MilestoneGroupDayRange',
    performance_averageDaysDelayed: 'Analytics/Performance/MonthlyDelayed',
    performance_transitTimeRouteGroup:
        'Analytics/Performance/TransitTimeRouteGroup',

    // control tower
    control_tower_RelatedKeyFigure: 'ControlTower/Overview/RelatedKeyFigure',
    control_tower_Overview: 'ControlTower/Overview/',
    control_tower_CustomerHistoryDevelopmnent:
        'ControlTower/Overview/Customer-History-Development',
    control_tower_BranchHistoryDevelopmnent:
        'ControlTower/Overview/Branch-History-Development',
    control_tower_AgentHistoryDevelopmnent:
        'ControlTower/Overview/Agent-History-Development',
    control_tower_CustomerHistorySplitRecord:
        'ControlTower/Overview/Customer-History-Split',
    control_tower_AgentHistorySplitRecord:
        'ControlTower/Overview/Agent-History-Split',
    control_tower_BranchHistorySplitRecord:
        'ControlTower/Overview/Branch-History-Split',
    control_tower_TrandeLanesCountry: 'ControlTower/TradeLanes/Country',
    control_tower_TrandeLanesPort: 'ControlTower/TradeLanes/Port',
    control_tower_TrandeLanesLane: 'ControlTower/TradeLanes/Lane',
    control_tower_TrandeLanesCountriesDevelopment:
        'ControlTower/TradeLanes/Countries-History-Development',
    control_tower_TrandeLanesPortDevelopment:
        'ControlTower/TradeLanes/Port-History-Development',
    control_tower_TrandeLanesCarrier: 'ControlTower/TradeLanes/Carrier',
    control_tower_TrandeLanesRegion: 'ControlTower/TradeLanes/Region',
    control_tower_TradeLanesCountrySplitRecord:
        'ControlTower/TradeLanes/Country-History-Split',
    control_tower_TradeLanesPortSplitRecord:
        'ControlTower/TradeLanes/Port-History-Split',
    control_tower_TradeLaneLanesSplitRecord:
        'ControlTower/TradeLanes/Lanes-History-Split',
    control_tower_TradeLaneLanesDevelopment:
        'ControlTower/TradeLanes/Lane-History-Development',

    // control tower customers
    control_tower_CustomerOrganisation: 'ControlTower/Customer/Organization',
    control_tower_CustomerOrganisationHistoryDevelopment:
        'ControlTower/Customer/Org-History-Development',
    control_tower_CustomerOrganisationHistorySplitRecord:
        'ControlTower/Customer/Org-History-Split',

    // control tower sales
    control_tower_SalesContact: 'ControlTower/Sales/Sales',
    control_tower_OverallSalesContact: 'ControlTower/Sales/Overall-Sales',
    control_tower_SalesContactHistoryDevelopment:
        'ControlTower/Sales/Sales-History-Development',
    control_tower_SalesContactHistorySplitRecord:
        'ControlTower/Sales/Sales-Staff-History-Split',
    control_tower_SalesOrganisation: 'ControlTower/Sales/Sales-Org',
    control_tower_SalesOverallOrganisation:
        'ControlTower/Sales/Overall-Sales-Org',
    control_tower_SalesOrganisationDevelopment:
        'ControlTower/Sales/Sales-Org-History-Development',
    control_tower_SalesOrganisationSplit:
        'ControlTower/Sales/Sales-Org-History-Split',

    // control tower finance
    control_tower_FinanceOpenInvoice:
        'ControlTower/Finance/Outstanding-Invoices',
    control_tower_FinanceProfitAndLoss: 'ControlTower/Finance/Profit-And-Loss',
    control_tower_FinanceCreditLimit: 'ControlTower/Finance/Credit-Limit',
    control_tower_AvgMonthlyPerformance: 'ControlTower/Finance/Average-Monthly',
    control_tower_FinanceProfitAndLossHistoryDevelopment:
        'ControlTower/Finance/History-Development',
    control_tower_FinanceProfitAndLossHistorySplitRecord:
        'ControlTower/Finance/History-Split',

    //shipments
    shipment_getAll: 'UniversalShipment',

    // trackings
    tracking_getAll: 'Container/Get',

    //manage user
    manage_user_getAll: 'Staff',
    manage_user_getCurrentUser: 'Staff/current-staff',
    manage_user_getModuleTemplates: 'Staff/module-templates',
    manage_user_getDefaultData: 'Staff/DefaultAdminData',
    manage_user_forgotPassword: 'Staff/forgot-password',
    manage_user_resetPassword: 'Staff/reset-password',
    manage_user_staffUpdateStatus: 'Staff/update-status',
}
