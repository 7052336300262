import IFBLogo from '@assets/img/logos/IFBLogoWhite.png'
import FormInput from '@components/form-input/form-input.component'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import useTracking from './login-service'
import GoogleMap from '@components/google-map/google-map.component'
import { faker } from '@faker-js/faker'
import { IPoint, ITracking, Ishipment } from './login.interface'
import EstimationInfo from '@components/estimation-info/estimation-info'

const shipmentDummy: Ishipment[] = [
    {
        label: 'House Bill',
        value: 'S00001001',
    },
    {
        label: 'Total Packages',
        value: '3',
    },
    {
        label: 'Weight',
        value: '10 KG',
    },
    {
        label: 'Service Level',
        value: 'Standard',
    },
    {
        label: 'Origin Port',
        value: 'IDTNJ',
    },
    {
        label: 'Destination Port',
        value: 'USOAK',
    },
    {
        label: 'Container Mode',
        value: 'LSE',
    },
    {
        label: 'Voyage/ Flight #/ Truck Ref',
        value: 'GTO20',
    },
]

const trackDummy: ITracking[] = [
    {
        label: 'Arrival',
        desc: 'SEA Arrival Terminal, GE, IT, Changed To: 01-Aug-23',
        time: 'Aug 1, 2023 00:00',
    },
    {
        label: 'Departure',
        desc: 'SEA Departure Terminal Hamburg, HH, DE Changed To: 28-Jul-2',
        time: 'Jul 28, 2023 00:00',
    },
    {
        label: 'All Export Documents Received',
        time: 'Mar 2, 2023 19:40',
    },
    {
        label: 'All Import Documents Received',
        time: 'Mar 2, 2023 19:40',
    },
]

const PointMap = (): IPoint[] => {
    const data = []

    // Thailand
    for (let i = 0; i < 1; i++) {
        let name = `S0000${faker.number.int({ min: 2000, max: 5000 })}`
        let lat = faker.location.latitude({ max: 15.870032, min: 12.670032 })
        let lng = faker.location.longitude({ max: 100.992541, min: 88.192541 })
        let key = `THAI-${i}`
        let status = false
        data.push({ name: name, lat: lat, lng: lng, key: key, status: status })
    }

    // USA
    for (let i = 0; i < 1; i++) {
        let name = `S0000${faker.number.int({ min: 2000, max: 5000 })}`
        let lat = faker.location.latitude({ max: 49.384358, min: 24.396308 })
        let lng = faker.location.longitude({ max: -66.93457, min: -124.731422 })
        let key = `USA-${i}`
        let status = true
        data.push({ name: name, lat: lat, lng: lng, key: key, status: status })
    }

    return data
}

const IFBTrackShipment = ({
    setIsLoginForm,
}: {
    setIsLoginForm: () => void
}) => {
    const { formik } = useTracking({
        handleSearch: (value) => console.log(value),
    })
    return (
        <div className="themes-bg-gray-v2 h-screen sm:h-full md:h-full lg:h-screen flex flex-col w-100">
            <div className="w-100 themes-bg-brand-v5 p-[20px] flex">
                <img className="h-[23px] w-[59px]" src={IFBLogo} alt="Logo" />
                <span className="ml-[20px] text-[20px] font-rormal text-white">
                    Track Shipments
                </span>
            </div>
            <div className="flex  sm:flex-col md:flex-row flex-grow gap-2 p-[20px]">
                <div className="w-full flex">
                    <div className="flex flex-col gap-2 w-full ">
                        <FormikProvider value={formik}>
                            <div className="bg-white shadow-md rounded-[10px] p-[20px] w-[100%]">
                                <div className="grid grid-cols-12 gap-2">
                                    <div className="col-span-10">
                                        <FormInput
                                            autoComplete="auto-off"
                                            hideError={true}
                                            placeholder={'Search Your Tracking'}
                                            parentDivClassName="w-full min-w-[250px] !mb-0"
                                            icon="ri-search-line"
                                            name="searchTerm"
                                            onKeyDown={() => {}}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <Button
                                            type="submit"
                                            label="Track"
                                            className="w-[100%]"
                                            variant="brand"
                                            onClick={() => {}}
                                        />
                                    </div>
                                </div>
                                <div className="themes-text-gray-v7 text-[14px] mt-2">
                                    Tracking # / Container / House Bill / Direct
                                    Master
                                </div>
                            </div>
                        </FormikProvider>
                        <div className="flex-grow flex sm:flex-col md:flex-col lg:flex-row gap-2">
                            <div className="bg-white shadow-md rounded-[10px] p-[20px] w-full flex flex-col justify-between">
                                <div>
                                    <div
                                        className="flex flex-col"
                                        style={{
                                            lineHeight: '14px',
                                            flex: '1',
                                        }}
                                    >
                                        <div className="text-[18px] themes-text-brand-v5 font-bold">
                                            S00001001
                                        </div>
                                        <div className="text-[14px] themes-text-gray-v6 font-normal">
                                            10 KG
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-2 flex-grow">
                                        {shipmentDummy.map((data, idx) => {
                                            return (
                                                <div
                                                    key={`item-${idx}`}
                                                    className="grid grid-cols-12 gap-1 text-[14px]"
                                                >
                                                    <div className="col-span-8 themes-text-gray-v6 font-bold">
                                                        {data.label}
                                                    </div>
                                                    <div className="col-span-4 text-left font-normal">
                                                        {data.value}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <Button
                                    type="submit"
                                    label="Back to Login"
                                    className="w-max"
                                    variant="brand-inverse"
                                    onClick={() => setIsLoginForm()}
                                />
                            </div>
                            <div className="bg-white shadow-md rounded-[10px] p-[20px] w-full">
                                <div className="text-[16px] themes-text-gray-v6 font-bold">
                                    Tracking History
                                </div>
                                <EstimationInfo
                                    initialCountryName={'Bankok'}
                                    initialCountryCode={'THBKK'}
                                    destinationCountryName={'USA'}
                                    destinationCountryCode={'USNYC'}
                                    ETD={'07 Mar 24'}
                                    ETA={'16 Apr 24'}
                                    iconMap
                                    status={{
                                        color: 'green',
                                        text: 'IN TRANSIT',
                                    }}
                                />
                                <hr className="mt-2" />
                                <div className="mt-[20px]">
                                    {trackDummy.map((data, idx) => {
                                        return (
                                            <div
                                                key={`data-track-${idx}`}
                                                className="flex items-start relative"
                                            >
                                                <div className="mr-2 flex flex-col items-center">
                                                    <div className="rounded-full w-2 h-2 bg-blue-500 line-through z-10" />
                                                    {idx !==
                                                        trackDummy.length -
                                                            1 && (
                                                        <div className="absolute top-0 bottom-0 w-[2px] themes-bg-gray-v2" />
                                                    )}
                                                </div>
                                                <div className="text-[14px] mt-[-4px] pb-5">
                                                    <div className="font-bold">
                                                        {data.label}
                                                    </div>
                                                    <div>{data.desc}</div>
                                                    <div>{data.time}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-[10px] p-[5px] w-full sm:h-[500px] md:h-full  shadow-md ">
                    <GoogleMap points={PointMap()} />
                </div>
            </div>
        </div>
    )
}
export default IFBTrackShipment
