var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useField } from 'formik';
import MeasurementInput from '@components/measurement-input/measurement-input.component';
import { useEffect, useState } from 'react';
function FormMeasurementInput(_a) {
    var _b, _c, _d, _e, _f, _g;
    var _h = _a.options, options = _h === void 0 ? [] : _h, _j = _a.parentDivClassName, parentDivClassName = _j === void 0 ? '' : _j, _k = _a.showErrorMessage, showErrorMessage = _k === void 0 ? true : _k, props = __rest(_a, ["options", "parentDivClassName", "showErrorMessage"]);
    //formik
    // [0] => field
    // [1] => meta
    // [2] => helpers
    var primaryFormik = useField(props.name);
    var qtyFormik = useField("".concat(props.name, ".qty"));
    var unitFormik = useField("".concat(props.name, ".unit"));
    var _l = useState(), errorMessage = _l[0], setErrorMessage = _l[1];
    var measurement = {
        qty: (_c = (_b = primaryFormik[0]) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.qty,
        unit: (_e = (_d = primaryFormik[0]) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.unit,
        unitLabel: (_g = (_f = primaryFormik[0]) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.unitLabel,
    };
    var borderColorClass = errorMessage
        ? 'themes-border-red-v3-important'
        : '';
    var errorClassMessage = errorMessage ? '' : 'hidden';
    useEffect(function () {
        if (!unitFormik[1].touched && !qtyFormik[1].touched)
            return;
        var unitError = unitFormik[1].error;
        var qtyError = qtyFormik[1].error;
        if (props.name === 'weight') {
            console.log(unitError, errorMessage);
        }
        if (!unitError && !qtyError && !errorMessage)
            return;
        if (unitError) {
            setErrorMessage(unitError);
            return;
        }
        if (qtyError) {
            setErrorMessage(qtyError);
            return;
        }
        setErrorMessage(undefined);
        console.log(errorMessage);
    }, [
        unitFormik[1].error,
        qtyFormik[1].error,
        unitFormik[1].touched,
        qtyFormik[1].touched,
    ]);
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName) }, { children: [_jsx(MeasurementInput, __assign({ options: options, measurement: measurement, 
                // value={measurement.qty}
                setMeasurement: function (change) {
                    primaryFormik[2].setValue(change);
                    props.additionalOnClick && props.additionalOnClick(change);
                }, borderError: borderColorClass, type: props.useDecimalFormat ? 'text' : 'number' }, props)), showErrorMessage && (_jsx("div", __assign({ className: "ml-small-x text-size-XS text-red-500 ".concat(errorClassMessage, " pt-[1px]") }, { children: errorMessage })))] })));
}
export default FormMeasurementInput;
