import { IMediumTableData, IUseControlTower } from '../control-tower.interface'
import SplitTable from '@components/split-table/split-table.component'
import { mediumTableHeaders, tabItemFilterSales } from '../control-tower.static'
import Tab from '@components/tab/tab.component'
import Pagination from '@components/pagination/pagination.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import ProgressMultipleBars from '@components/progress-multiple-bars/progress-multiple-bars.component'

const Sales = ({ service }: { service: IUseControlTower }) => {
    return (
        <>
            <div className="col-span-8 border-r" style={{ height: 'inherit' }}>
                <div className="flex justify-between items-center px-2 h-[40px]">
                    <Tab
                        items={tabItemFilterSales}
                        tabFilter={service.tabFilterSales}
                        onChange={(item) => {
                            service.setTabFilterSales(item)
                        }}
                        containerClassName="!overflow-y-hidden !text-size-XS !p-0 !border-none"
                        containerItemClass="!min-h-[40px] h-[40px]"
                    />
                    <Pagination
                        containerClassName="!p-0 flex items-center h-full"
                        className="!h-[27px]"
                        btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                        meta={service.mediumTable.table.meta}
                        previousHandling={(page) => {
                            service.handlingPageNumberMediumTable(page)
                        }}
                        nextHandling={(page) => {
                            service.handlingPageNumberMediumTable(page)
                        }}
                    />
                </div>
                <InfoCardGroup
                    items={service.mediumTable.infoCardItems}
                    titleClass="!text-size-M"
                    descClass="!text-size-XS"
                />
                <div className="flex items-center pl-2">
                    <div className="themes-text-gray-v4 text-size-XS font-bold leading-none w-[70px]">
                        <div>%</div>
                        {service.getActiveFieldFilterDataCategory().label}
                    </div>
                    <ProgressMultipleBars
                        containerClassNames={`!w-full px-2 text-size-S`}
                        data={service.mediumTable.multipleBarItems.data}
                        useTooltip={false}
                        keyItems={service.mediumTable.multipleBarItems.keyItems}
                    />
                </div>
                <div className="h-[calc(100vh-21.5rem)] border-t w-full">
                    <SplitTable<IMediumTableData>
                        columns={mediumTableHeaders}
                        data={service?.mediumTable?.table.data || []}
                        thClass="text-size-XS"
                        tdClass="text-size-XS"
                        loading={service.loadingMediumTable}
                        onRowClick={(data) => {
                            service.handleLoadHistoryData(data.name)
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default Sales
