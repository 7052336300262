import * as Yup from 'yup'
import moment from 'moment'

// interface for validation
export interface IPerformanceFilterValidation {
    shipmentDateBy: string
    startMonth: string
    endMonth: string
    currency?: string
}

export const usePerformanceFilterValidation = () => {
    const peformanceFilterValidation = Yup.object().shape({
        startMonth: Yup.string()
            .required('Start Month is required')
            .matches(
                /^(0[1-9]|1[0-2])\/\d{4}$/,
                'Invalid Start Month format (MM/YYYY)',
            )
            .test(
                'is-greater',
                'End Month cannot be earlier then the start month',
                function (value) {
                    const { endMonth } = this.parent
                    if (!value || !endMonth) return false

                    const start = moment(endMonth, 'MM/YYYY')
                    const end = moment(value, 'MM/YYYY')

                    return start.isSameOrAfter(end)
                },
            )
            .test(
                'max-range',
                'Date filter range cannot be more than 12 months',
                function (value) {
                    const { endMonth } = this.parent
                    if (!value || !endMonth) return false

                    const end = moment(endMonth, 'MM/YYYY')
                    const start = moment(value, 'MM/YYYY')

                    return end.diff(start, 'months') < 12
                },
            ),

        endMonth: Yup.string()
            .required('End Month is required')
            .matches(
                /^(0[1-9]|1[0-2])\/\d{4}$/,
                'Invalid End Month format (MM/YYYY)',
            )
            .test(
                'is-greater',
                'End Month cannot be earlier then the start month',
                function (value) {
                    const { startMonth } = this.parent
                    if (!value || !startMonth) return false

                    const start = moment(startMonth, 'MM/YYYY')
                    const end = moment(value, 'MM/YYYY')

                    return end.isSameOrAfter(start)
                },
            )
            .test(
                'max-range',
                'Date filter range cannot be more than 12 months',
                function (value) {
                    const { startMonth } = this.parent
                    if (!value || !startMonth) return false

                    const start = moment(startMonth, 'MM/YYYY')
                    const end = moment(value, 'MM/YYYY')

                    return end.diff(start, 'months') < 12
                },
            ),
    })

    const monthNow = moment().subtract(0, 'months').format('MM/YYYY')
    const sixMonthsAgo = moment().subtract(5, 'months').format('MM/YYYY')
    const peformanceFilterInitialValue: IPerformanceFilterValidation = {
        shipmentDateBy: 'eta',
        startMonth: `${sixMonthsAgo}`,
        endMonth: `${monthNow}`,
        currency: 'PLN',
    }

    return {
        peformanceFilterValidation,
        peformanceFilterInitialValue,
    }
}
