var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, } from 'recharts';
import BarLoading from '../loading/bar-loading.component';
import EmptyResult from '@components/empty-result/empty-result.component';
var BarChartStacked = function (_a) {
    var data = _a.data, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, renderTooltipValue = _a.renderTooltipValue, emptyResultCompprops = _a.emptyResultCompprops, disableErrorIllustration = _a.disableErrorIllustration;
    var _c = useState(window.innerWidth), widthSize = _c[0], setWidthSize = _c[1];
    var ref = useRef(null);
    var YAxisCssProperties = useState({
        fontSize: 14, // default px
    })[0];
    var updateDimensions = function () {
        setWidthSize(window.innerWidth);
    };
    useEffect(function () {
        window.addEventListener('resize', updateDimensions);
        return function () {
            window.removeEventListener('resize', updateDimensions);
        };
    });
    var CustomTooltip = function (_a) {
        var active = _a.active, payload = _a.payload, label = _a.label;
        if (active && payload && payload.length) {
            var filterPayload = payload.filter(function (item) { return item.value !== 0; });
            if (!filterPayload.length)
                return null;
            return (_jsxs("div", __assign({ className: "custom-tooltip p-2 rounded shadow-md themes-bg-gray-v9 opacity-80 themes-text-white" }, { children: [_jsx("p", __assign({ className: "label text-sm font-semibold" }, { children: "".concat(label) })), filterPayload.map(function (entry, index) {
                        var contentItemvalue = renderTooltipValue
                            ? renderTooltipValue(entry)
                            : "item ".concat(index + 1, " : ").concat(entry.value.toLocaleString());
                        return (_jsxs("p", __assign({ className: "intro text-xs flex mb-1 items-centes" }, { children: [_jsx("div", { style: { backgroundColor: entry.fill }, className: "w-[20px] h-[20px] rounded-sm mr-3" }), contentItemvalue] }), "item-".concat(index)));
                    })] })));
        }
        return null;
    };
    var CustomTick = function (_a) {
        var x = _a.x, y = _a.y, payload = _a.payload;
        var matchingData = data.data.find(function (item) { return item.name === payload.value; });
        var fontSize = widthSize > 1279 && widthSize < 1440 ? '10px' : '12px';
        // Function untuk memotong teks dan menambahkan ellipsis
        var getEllipsisText = function (text, maxLength) {
            var _a;
            // Step 1: Check if text exceeds maxLength
            if (text.length > maxLength) {
                // Step 2: Check if text contains '('
                var indexOfParenthesis = text === null || text === void 0 ? void 0 : text.indexOf('(');
                if (indexOfParenthesis !== -1) {
                    // Step 3: Remove the text inside parentheses including the parentheses
                    text = (_a = text.substring(0, indexOfParenthesis)) === null || _a === void 0 ? void 0 : _a.trim();
                }
                // Step 4: Check again if text exceeds maxLength after removing parentheses
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                }
            }
            // Return the original or trimmed text
            return text;
        };
        var calculateWidthContent = function () {
            var _a;
            var width = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.offsetWidth;
            if (!width)
                return 0;
            var lengthBar = data.data.length;
            var calculate = (width / lengthBar) * 0.3; // 0.3 from barCategoryGap in BarChart
            return calculate;
        };
        var maxLength = calculateWidthContent() < 20 ? 4 : 20;
        var displayText = getEllipsisText(payload === null || payload === void 0 ? void 0 : payload.value, maxLength);
        var secondaryText = getEllipsisText((matchingData === null || matchingData === void 0 ? void 0 : matchingData.secName) || '', maxLength);
        return (_jsxs("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: [_jsx("text", __assign({ x: 0, y: 0, dy: 16, textAnchor: "middle", fill: "#20384E", fontSize: fontSize }, { children: displayText })), _jsx("text", __assign({ x: 0, y: 0, dy: 30, textAnchor: "middle", fill: "#C0C7CD", fontSize: fontSize }, { children: secondaryText }))] })));
    };
    var tickFormatter = function (value, index) {
        var limit = 1; // put your maximum character
        if (value.length < limit)
            return value;
        return "".concat(value.substring(0, limit), "...");
    };
    var calculateLeftMargin = function () {
        var _a;
        // using formula linear line
        // formula intial margin yx = 0.7x−44
        // formula intial interval yx =  0.7x−1
        // formula marginLeft yx = (0.7x-51) + (((0.7x−1) * maxlength) - (0.7x-1))
        // x = fontSize
        var maxValue = Math.max.apply(Math, data.data.map(function (item) {
            var _a, _b, _c, _d, _e, _f;
            return Math.max((_a = item === null || item === void 0 ? void 0 : item.f1) !== null && _a !== void 0 ? _a : 0, (_b = item.f2) !== null && _b !== void 0 ? _b : 0, (_c = item.f3) !== null && _c !== void 0 ? _c : 0, (_d = item.f3) !== null && _d !== void 0 ? _d : 0, (_e = item.f4) !== null && _e !== void 0 ? _e : 0, (_f = item.f5) !== null && _f !== void 0 ? _f : 0);
        }));
        var roundedToNextThousand = Math.round(maxValue) * 10;
        var maxLength = roundedToNextThousand.toString().length;
        var x = (_a = YAxisCssProperties.fontSize) !== null && _a !== void 0 ? _a : 0;
        var initialMargin = 0.7 * x - 35;
        var initialInterval = 0.7 * x - 1;
        var marginLeft = initialMargin + (initialInterval * maxLength - initialInterval);
        return marginLeft;
    };
    var isEmpityData = !data.data.length ||
        data.data.every(function (item) {
            var _a, _b, _c, _d, _e;
            return ((_a = item.f1) !== null && _a !== void 0 ? _a : 0) === 0 &&
                ((_b = item.f2) !== null && _b !== void 0 ? _b : 0) === 0 &&
                ((_c = item.f3) !== null && _c !== void 0 ? _c : 0) === 0 &&
                ((_d = item.f4) !== null && _d !== void 0 ? _d : 0) === 0 &&
                ((_e = item.f5) !== null && _e !== void 0 ? _e : 0) === 0;
        });
    return (_jsx("div", __assign({ className: "w-full h-full", ref: ref }, { children: isLoading ? (_jsx(BarLoading, {})) : isEmpityData ? (_jsx(EmptyResult, { disableIllustration: disableErrorIllustration, className: "w-full ".concat(emptyResultCompprops === null || emptyResultCompprops === void 0 ? void 0 : emptyResultCompprops.className), message: 'Data Not Found', imgClassName: emptyResultCompprops === null || emptyResultCompprops === void 0 ? void 0 : emptyResultCompprops.imgClassName })) : (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(BarChart, __assign({ width: 500, height: 500, data: data.data, margin: {
                    top: 0,
                    right: 0,
                    left: calculateLeftMargin(),
                    bottom: -20,
                }, barCategoryGap: "30%" }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name", height: 60, style: { fontSize: '6px' }, tick: _jsx(CustomTick, {}), interval: 0, tickFormatter: tickFormatter }), _jsx(YAxis, { tick: { fill: '#666' }, style: YAxisCssProperties }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), data.key.map(function (item, idx) {
                        return (_jsx(Bar, __assign({ dataKey: item.id, stackId: "a", fill: item.color, radius: [3, 3, 0, 0] }, { children: data.data.map(function (entry, index) {
                                var keys = Object.keys(entry);
                                var values = Object.values(entry);
                                var channelNameIndex = keys.findIndex(function (key) { return key === item.id; });
                                var lengthValue = (values === null || values === void 0 ? void 0 : values.length) || 0;
                                if (channelNameIndex ===
                                    lengthValue - 2) {
                                    return (
                                    // normal chart
                                    _jsx(Cell, {}, "cell-".concat(index)));
                                }
                                return (
                                // rounded chart
                                _jsx(Cell, { radius: 0 }, "cell-".concat(index)));
                            }) }), "item-bar-".concat(idx)));
                    })] })) }))) })));
};
export default BarChartStacked;
