import Button from '@components/button/button.component'
import { IButton } from '@components/button/button.interface'
import { useState } from 'react'

const ListButton = ({
    containerClassNames = '',
    listButton,
}: {
    containerClassNames?: string
    listButton: IButton[]
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    return (
        <div className={`${containerClassNames}`}>
            {listButton.map(({ onClick, className, ...item }, idx) => {
                const isActive = activeIndex === idx
                const classActive = isActive
                    ? 'themes-bg-brand-v4 themes-text-white'
                    : 'themes-bg-brand-v2 themes-text-brand-v4'
                return (
                    <Button
                        onClick={() => {
                            setActiveIndex(idx)
                            onClick()
                        }}
                        className={`flex items-center justify-center flex-wrap ${className} ${classActive}`}
                        labelClass={`flex-grow max-w-fit xl:text-[10px] 2xl:text-size-XS`}
                        iconClassName={`flex-grow max-w-[30px] min-w-[30px] !pr-0`}
                        {...item}
                    />
                )
            })}
        </div>
    )
}

export default ListButton
